import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
  getErrorMessage,
  validateBIC,
  validateEMail,
  validateIBAN,
  validatePhone
} from './Utils';

export default class PublisherApplication extends Component {

  constructor(props) {

    super(props);

    this.catchError = this.catchError.bind(this);
    this.endPDFConnection = this.endPDFConnection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setShowMessage = this.setShowMessage.bind(this);
    this.toggleBIC = this.toggleBIC.bind(this);

    this.APIURL = require('./../config/config.js').APIURL;
    this.message = [];
    this.state = {
      BIC: false,
      isLoading: false,
      showMessage: false
    };

    var today = new Date();

    this.date =
      String(today.getDate()).padStart(2, '0') + '.'
      + String(today.getMonth() + 1).padStart(2, '0') + '.'
      + today.getFullYear();

  }

  render() {

    document.body.style.backgroundColor = '#E7D074';

    return(

      <div className='mediumBoxWrapper'>
        <LoadingOverlay isLoading={this.state.isLoading}/>
        <ShowMessage
          showMessage={this.state.showMessage}
          message={this.message}
          setShowMessage={this.setShowMessage}
        />
        <div className='mediumBox OuterBox'>
          <table className='MaxWidth'>
            <tbody>
              <tr>
                <td className='Logo'>
                  <img src='/img/logo.gif' alt=''></img>
                </td>
                <td className='Center'>
                  <h2>Avaldus Autorihüvitusfondile</h2>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='InnerBox'>
            <form onSubmit={this.handleSubmit}>
              <table className='MaxWidth'>
                <tbody>
                  <tr>
                    <td className='Subtitle' colSpan='2'>
                      Avalduse esitaja andmed
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Nimi:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='Name'
                        maxLength='100'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Ärinimi:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='BusinessName'
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Registrikood:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='12'
                        name='LegalCode'
                        maxLength='11'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Konktaktisik:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='ContactPerson'
                        maxLength='100'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Allkirjaõiguslik isik:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='AuthorizedPerson'
                        maxLength='100'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='Subtitle' colSpan='2'>
                      Aadress
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Tänav, maja, korter:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='Address'
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Linn:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='City'
                        maxLength='30'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Postiindeks:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='12'
                        name='ZIPCode'
                        maxLength='10'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      E-posti aadress:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='EMail'
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Telefon:
                    </td>
                    <td>
                      <input 
                        type='text'
                        size='25'
                        name='Phone'
                        maxLength='20'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='Subtitle' colSpan='2'>
                      Panga andmed
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      IBAN:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='IBAN'
                        maxLength='50'
                        onChange={this.toggleBIC}>
                      </input>
                    </td>
                  </tr>
                  {this.state.BIC ?
                    <tr>
                      <td className='RequiredField'>
                        BIC:
                      </td>
                      <td>
                        <input
                          type='text'
                          size='12'
                          name='BIC'
                          maxLength='8'>
                        </input>
                      </td>
                    </tr>
                    : null}
                  <tr>
                    <td colSpan='2'>
                      <ul>
                        <li>Taotlen paljundushüvitist teoste reprograafilise reprodutseerimise eest vastavalt lisale.</li>
                        <br></br>
                        <li>Olen teadlik hüvitise taotlemise korrast ja kinnitan minu poolt esitatud andmete õigsust.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className='center'>
                      <b>Kuupäev:</b> {this.date}
                    </td>
                    <td className='center'>
                      <b>Allkiri:</b>
                    </td>
                  </tr>
                  <tr>
                    <td className='Center' colSpan='2'>
                      <button className='StandardButton' type='submit'>Loo PDF</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>

    );

  }

  catchError(error) {

    this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

    this.setState({
      isLoading: false,
      showMessage: true
    });

  }

  endPDFConnection(response) {

    this.setState({ isLoading: false });

    window.location = response.data.data.pdflink;

  }

  handleSubmit(event) {

    event.preventDefault();

    var BIC = '';
    var message = '';

    if(event.target.BIC != null) {

      BIC = event.target.BIC.value;

    }

    var errorMessage = [];

    if(event.target.Name.value === '') {

      errorMessage.push({value: 'Nimi peab olema täidetud!', align: 'center'});

    }

    if(event.target.BusinessName.value === '') {

      errorMessage.push({value: 'Ärinimi peab olema täidetud!', align: 'center'});

    }

    if(event.target.LegalCode.value === '') {

      errorMessage.push({value: 'Registrikood peab olema täidetud!', align: 'center'});

    }

    if(event.target.ContactPerson.value === '') {

      errorMessage.push({value: 'Kontaktisik peab olema täidetud!', align: 'center'});

    }

    if(event.target.AuthorizedPerson.value === '') {

      errorMessage.push({value: 'Allkirjaõiguslik isik peab olema täidetud!', align: 'center'});

    }

    if(event.target.Address.value === '') {

      errorMessage.push({value: 'Tänav, maja, korter peab olema täidetud!', align: 'center'});

    }

    if(event.target.City.value === '') {

      errorMessage.push({value: 'Linn peab olema täidetud!', align: 'center'});

    }

    if(event.target.ZIPCode.value === '') {

      errorMessage.push({value: 'Postiindeks peab olema täidetud!', align: 'center'});

    }

    message = validateEMail(event.target.EMail.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = validatePhone(event.target.Phone.value);

    if(event.target.Phone.value === '') {

      errorMessage.push({value: 'Telefon peab olema täidetud!', align: 'center'});

    }

    else if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = validateIBAN(event.target.IBAN.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = validateBIC(this.state.BIC, BIC);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    if(errorMessage.length === 0) {

      this.setState({ isLoading: true });

      var URL = this.APIURL + 'pdf';

      var data =
        '{"pagedata": "'
        + '<!DOCTYPE html><html><head><meta charset=\\"utf-8\\"><title>AHF</title><style type=\\"text/css\\">'
        + '#ApplicationRow {white-space: nowrap;}'
        + 'td, ul, td {font-family: Verdana, Helvetica, sans-serif;font-size: 16px;}'
        + 'h2 {font-family: Verdana, Helvetica, sans-serif;font-size: 20px;}'
        + '#ApplicationsBox {width: 964px;}'
        + '#LongWidth {max-width: 150px;overflow: hidden;}'
        + '#Title {text-align: center;margin: auto;font-weight: bold;padding: 10px;font-family: Verdana, Helvetica, sans-serif;font-size: 20px;}'
        + '.ApplicationTable {border-collapse: collapse;}'
        + '.ApplicationTable td {vertical-align: top;padding-top: 5px;padding-bottom: 5px;}'
        + '.Center {width: 100%;text-align: center;white-space: nowrap;}'
        + '.Column {width: 45%;display: inline-block;text-align: center;font-family: Verdana, Helvetica, sans-serif;font-size: 16px;padding-bottom: 15px;}'
        + '.Content {text-align: left;width: 50%;}'
        + '.Header {border-top: 1px solid black;border-bottom: 1px solid black;}'
        + '.InnerBox {padding: 10px;border: 1px solid black;}'
        + '.Label {text-align: right;font-weight: bold;width: 50%;}'
        + '.Logo {width: 155px;height: 80px;text-align: center;}'
        + '.MaxWidth {width: 100%;word-break: break-all;word-break: break-word;text-align: center;}'
        + '.Width120 {min-width: 135px;max-width: 135px;word-break: break-all;word-break: break-word;}'
        + '.Width250 {min-width: 250px;max-width: 250px;word-break: break-all;word-break: break-word;}'
        + '.Width60 {min-width: 40px;max-width: 40px;word-break: break-all;word-break: break-word;text-align: center;}'
        +  'tr.Space > td {padding-bottom: 1em;}'
        +  'tr {page-break-inside: avoid;}'
        + '.OuterBox {margin: auto;border-radius: 20px;padding: 20px;}'
        + '.TopButtons {min-width: 155px;max-width: 155px;height: 80px;text-align: center;}'
        + '</style></head>'
        + '<body>'
        + '<div>'
        + '<p></p>'
        + '<div id=\\"ApplicationsBox\\" class=\\"OuterBox\\">'
        + '<table class=\\"MaxWidth\\">'
        + '<tbody>'
        + '<tr>'
        + '<td class=\\"Logo\\">'
        + '<img src=\\"img/logo.gif\\"></img>'
        + '</td>'
        + '<td class=\\"Center\\" colSpan=\\"2\\">'
        + '<h2>Avaldus Autorihüvitusfondile</h2>'
        + '</td>'
        + '<td class=\\"TopButtons\\">'
        + '</td>'
        + '</tr>'
        + '</tbody>'
        + '</table>'
        + '<div class=\\"Header\\">'
        + '<table class=\\"MaxWidth\\">'
        + '<tbody>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Nimi:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.Name.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Ärinimi:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.BusinessName.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Registrikood:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.LegalCode.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Kontaktisik:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.ContactPerson.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Allkirjaõiguslik isik:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.AuthorizedPerson.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Tänav, maja, korter:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.Address.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Linn:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.City.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Postiindeks:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.ZIPCode.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'E-posti aadress:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.EMail.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'Telefon:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.Phone.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + '<tr>'
        + '<td class=\\"Label\\">'
        + 'IBAN:'
        + '</td>'
        + '<td class=\\"Content\\">'
        + event.target.IBAN.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
        + '</td>'
        + '</tr>'
        + (event.target.BIC == null ? '' :
          '<tr>'
          + '<td class=\\"Label\\">'
          + 'BIC:'
          + '</td>'
          + '<td class=\\"Content\\">'
          + event.target.BIC.value
          + '</td>'
          + '</tr>')
        + '<tr>'
        + '</tbody>'
        + '</table>'
        + '<ul>'
        + '<li><b>Taotlen paljundushüvitist teoste reprograafilise reprodutseerimise eest vastavalt lisale.</b></li>'
        + '<br></br>'
        + '<li><b>Olen teadlik hüvitise taotlemise korrast ja kinnitan minu poolt esitatud andmete õigsust.</b></li>'
        + '</ul>'
        + '<div class=\\"Column\\">'
        + '<b>Kuupäev:</b> ' + this.date
        + '</div>'
        + '<div class=\\"Column\\">'
        + '<b>Allkiri:</b>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</body>'
        + '</html>"}';

      var config = {

        headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
        timeout: 30000,

      };

      axios.post(URL, data, config).then(this.endPDFConnection).catch(this.catchError);

    }

    else {

      this.message.push(errorMessage);

      this.setState({ showMessage: true });

    }

  }

  setShowMessage(showMessage) {

    this.setState({showMessage: showMessage});

  }

  toggleBIC(event) {

    if(event.target.value.length >= 10 && event.target.value.substring(0, 2).toLowerCase() !== 'ee') {

      this.setState({BIC: true});

    }

    else {

      this.setState({BIC: false});

    }

  }

}