import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	catchError,
	getCookie
} from './Utils';

export default class ApplicantView extends Component {

	constructor(props) {

		super(props);

		this.back = this.back.bind(this);
		this.catchError = catchError.bind(this);
		this.endApplicationsConnection = this.endApplicationsConnection.bind(this);
		this.endPartyConnection = this.endPartyConnection.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			applications: [],
			hasMultipleAuthors: false,
			isLoading: true,
			party: {},
			showMessages: false
		};

	}

	componentDidMount() {

		var URL = this.APIURL + 'parties/' + this.props.response.params.partyID;

		axios.get(URL, this.config).then(this.endPartyConnection).catch(this.catchError);

	}

	render() {

		document.body.style.backgroundColor = '#BA491C';

		return(

			<div id='applicationsBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='ApplicationsBox' className='OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center' colSpan='2'>
									<h2>Kasutaja: {this.state.party.legal_cd == null ||this.state.party.legal_cd.length === 0 ? this.state.party.personal_cd : this.state.party.legal_cd}</h2>
								</td>
								<td className='TopButtons'>
									<button type='button' className='StandardButton' onClick={this.back}>Tagasi</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='Header'>
						<table className='MaxWidth'>
							<tbody>
								<tr>
									<td className='Label'>
										Avalduse esitaja:
									</td>
									<td className='Content'>
										{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0
											? <div>{this.state.party.applicant_first_name} {this.state.party.applicant_family_name}</div>
											: this.state.party.legal_name}
									</td>
								</tr>
								{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0 ? null :
									<tr>
										<td className='Label'>
											Esindaja:
										</td>
										<td className='Content'>
											{this.state.party.applicant_first_name} {this.state.party.applicant_family_name}
										</td>
									</tr>}
								{this.state.hasMultipleAuthors ? null :
									<tr>
										<td className='Label'>
											Autor:
										</td>
										<td className='Content'>
											{this.state.party.author_first_name} {this.state.party.author_family_name}
										</td>
									</tr>}
								{this.state.hasMultipleAuthors ? null :
									<tr>
										<td className='Label'>
											Pseudonüüm(id):
										</td>
										<td className='Content'>
											{this.state.party.pseudonym}
										</td>
									</tr>}
								<tr>
									<td className='Label'>
										Aadress, postiindeks:
									</td>
									<td className='Content'>
										{this.state.party.address_line1}
										{this.state.party.address_line1 != null && (this.state.party.address_line2 != null || this.state.party.zip_cd != null) ? ', ' : ''}
										{this.state.party.address_line2}
										{this.state.party.address_line2 != null && this.state.party.zip_cd != null ? ', ' : ''}
										{this.state.party.zip_cd}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										Telefon:
									</td>
									<td className='Content'>
										{this.state.party.phone1}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										E-post:
									</td>
									<td className='Content'>
										{this.state.party.email}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0 ? 'Isikukood:' : 'Registreerimisnumber:'}
									</td>
									<td className='Content'>
										{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0 ? this.state.party.personal_cd : this.state.party.legal_cd}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										IBAN:
									</td>
									<td className='Content'>
										{this.state.party.iban}
									</td>
								</tr>
								{this.state.party.bic == null || this.state.party.bic.length === 0 ? null :
									<tr>
										<td className='Label'>
											BIC:
										</td>
										<td className='Content'>
											{this.state.party.iban}
										</td>
									</tr>}
								<tr>
									<td>
										<p></p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div>
						<p></p>
						<div className='InnerBox'>
							<table id='ApplicationsTable'>
								<thead>
									<tr>
										<th className='Width60'>Taotlus</th>
										<th className='WidthRemaining'>Autor</th>
										<th className='Width80'>Taotlus alustatud</th>
										<th className='Width80'>Taotlus lõpetatud</th>
										<th className='Width80'>Olek</th>
										<th className='Width80'>Roll</th>
									</tr>
								</thead>
								<tbody>
									{

										this.state.applications.map(function(application, i) {

											var Status;

											var Options = {};

											if(application.status_cd === 'E') {

												Status = 'Valmis'

												Options['id'] = 'Applied';

											}

											else if(application.status_cd === 'L' || application.status_cd === 'K') {

												Status = 'Pooleli';

												Options['id'] = 'Unfinished';

											}

											else {

												Status = 'Kinnitatud';

												Options['id'] = 'Accepted';

											}

											var applicant_role = '';

											if(application.applicant_role_cd === 'I') {

												applicant_role = 'Isiklikult';

											}

											else if(application.applicant_role_cd === 'E') {

												applicant_role = 'Esindaja';

											}

											else if(application.applicant_role_cd === 'P') {

												applicant_role = 'Pärija';

											}

											return(

												<tr key={i}>
													<td {...Options} className='Width60'>{application.appl_id}</td>
													<td {...Options} className='WidthRemaining'>{application.author_first_name} {application.author_family_name}</td>
													<td {...Options} className='Width80'>{application.create_dtime}</td>
													<td {...Options} className='Width80'>{Status === 'Valmis' || Status === 'Kinnitatud' ? application.change_dtime : ''}</td>
													<td {...Options} className='Width80'>{Status}</td>
													<td {...Options} className='Width80'>{applicant_role}</td>
												</tr>

											);

										}, this)

									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

		);

	}

	back() {

    let url = this.props.router.url({ name: 'Applications' });

    this.props.router.navigate({ url });

	}

	endApplicationsConnection(response) {

		var application = response.data.data;

		var author = (this.state.party.author_first_name == null ? '' : this.state.party.author_first_name)
			+ (this.state.party.author_family_name == null ? '' : this.state.party.author_family_name);

		var hasMultipleAuthors = false;

		for(let i = 0; i < application.length; i ++) {

			if(author === '') {

				author = application[i].author_first_name + application[i].author_family_name;

			}

			else if(application[i].author_first_name + application[i].author_family_name !== author) {

				hasMultipleAuthors = true;

				break;

			}

		}

		this.setState({
			applications: application,
			hasMultipleAuthors: hasMultipleAuthors,
			isLoading: false
		})

	}

	endPartyConnection(response) {

		this.setState({ party: response.data.data });

		var URL = this.APIURL + 'applications?filtered=false&party_id=' + this.props.response.params.partyID;

		axios.get(URL, this.config).then(this.endApplicationsConnection).catch(this.catchError);

	}

	setShowMessage(showMessage) {

		if(!showMessage) {

			if(this.navigateBackAfterMessage) {

				this.back();

			}

			else {

				this.setState({showMessage: false});

			}

		}

		else {

			this.setState({showMessage: true});

		}

	}

}