import React, { Component } from 'react';

export default class ShowMessage extends Component {

  constructor(props) {

    super(props);

    this.answer = this.answer.bind(this);
    this.closeMessage = this.closeMessage.bind(this);

  }

  render() {

    if(this.props.showMessage) {

      return (

        <div>
          <div className="messageCover">
            <div className="messageScreen">
              <div className="inlineBlock">
                <div>
                {
                  this.props.message[0].map (
                    function(message, index) {
                      return (
                        <div key={index} className={'message commonText ' + message.align}>
                          {message.value}
                        </div>
                      );
                    }
                  )
                }
                </div>
              </div>
              <div className='center'>
                {this.props.message[0][0].choices
                  ?
                    this.props.message[0][0].choices.map (
                      function(choice, index) {
                        return (
                          <button
                            key={index}
                            className='StandardButton'
                            onClick={this.answer.bind(this, index)}>
                            {choice}
                          </button>
                        );
                      }, this
                    )
                  :
                    <button
                      autoFocus
                      className='StandardButton'
                      onClick={this.closeMessage}>
                      Ok
                    </button>
                }
              </div>
            </div>
          </div>
        </div>

      );

    }

    else {

      return (

        <div/>

      );

    }

  }

  answer(index) {

    this.props.answer(index);

    this.closeMessage();

  }

  closeMessage() {

    this.props.message.shift();

    var showMessage = false;

    if(this.props.message.length > 0) {

      showMessage = true;

    }

    this.props.setShowMessage(showMessage);

  }

}