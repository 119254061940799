import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
  catchError,
  getCookie
} from './Utils';

export default class NewWork extends Component {

  constructor(props) {

    super(props);

    this.AddAuthorship = this.AddAuthorship.bind(this);
    this.back = this.back.bind(this);
    this.catchError = catchError.bind(this);
    this.ChangeAllocation = this.ChangeAllocation.bind(this);
    this.ChangeAuthorFamilyName = this.ChangeAuthorFamilyName.bind(this);
    this.ChangeAuthorFirstName = this.ChangeAuthorFirstName.bind(this);
    this.ChangeAuthorship = this.ChangeAuthorship.bind(this);
    this.changeISBN = this.changeISBN.bind(this);
    this.createNewWork = this.createNewWork.bind(this);
    this.endApplicationConnection = this.endApplicationConnection.bind(this);
    this.EndCheckISBNFormationPartIDConnection = this.EndCheckISBNFormationPartIDConnection.bind(this);
    this.EndFirstConnection = this.EndFirstConnection.bind(this);
    this.EndFirstConnectionError = this.EndFirstConnectionError.bind(this);
    this.EndSecondConnection = this.EndSecondConnection.bind(this);
    this.EndSecondConnectionError = this.EndSecondConnectionError.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.RemoveAuthorship = this.RemoveAuthorship.bind(this);
    this.setShowMessage = this.setShowMessage.bind(this);
    this.ToggleIsAcademic = this.ToggleIsAcademic.bind(this);
    this.ToggleISBNPuudub = this.ToggleISBNPuudub.bind(this);
    this.ValidateAasta = this.ValidateAasta.bind(this);
    this.ValidateAuthorship = this.ValidateAuthorship.bind(this);
    this.ValidateIsAcademic = this.ValidateIsAcademic.bind(this);
    this.ValidateISBN = this.ValidateISBN.bind(this);
    this.ValidateKirjastaja = this.ValidateKirjastaja.bind(this);
    this.ValidateKujunduseOsa = this.ValidateKujunduseOsa.bind(this);
    this.ValidatePealkiri = this.ValidatePealkiri.bind(this);
    this.ValidateTrukisERaamat = this.ValidateTrukisERaamat.bind(this);

    this.APIURL = require('./../config/config.js').APIURL;
    this.config = {
      headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
      timeout: 30000,
      auth: {username: getCookie('AHFToken')},
    };
    this.IsAcademic = 'N';
    this.message = [];
    this.navigateBackAfterMessage = false;
    this.state = {
      application: {},
      authorships: [],
      displayISBN: true,
      displayISBNPuudub: true,
      ISBNPuudub: false,
      isLoading: false,
      showMessage: false
    };

  }
  
  componentDidMount() {

    this.setState({ isLoading: true });

    var URL = this.APIURL + 'applications/' + this.props.response.params.applicationID;

    axios.get(URL, this.config).then(this.endApplicationConnection).catch(this.catchError);

  }

  render() {

    if(getCookie('AHFLevel') === '2') {

      document.body.style.backgroundColor = '#BA491C';

    }

    else {

      document.body.style.backgroundColor = '#E7D074';

    }

    return(

      <div id='applicationsBoxWrapper'>
        <LoadingOverlay isLoading={this.state.isLoading}/>
        <ShowMessage
          showMessage={this.state.showMessage}
          message={this.message}
          setShowMessage={this.setShowMessage}
        />
        <div id='ApplicationsBox' className='OuterBox'>
          <table className='MaxWidth'>
            <tbody>
              <tr>
                <td className='Logo'>
                  <img src='/img/logo.gif' alt=''></img>
                </td>
                <td className='Center'>
                  <h2>Teose lisamine avaldusele nr. {this.props.response.params.applicationID}</h2>
                </td>
                <td className='TopButtons'>
                  <button type='button' className='StandardButton' id='ButtonWidth' onClick={this.back}>Tagasi</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='InnerBox'>
            <form onSubmit={this.HandleSubmit}>
              <table className='MaxWidth'>
                <tbody>
                  <tr>
                    <td className='RequiredField' id='HalfWidth'>
                      Pealkiri (ilma autori nime jm infota):
                    </td>
                    <td>
                      <input
                        type='text'
                        size='60'
                        name='Pealkiri'
                        maxLength='1000'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Trükis/e-raamat:
                    </td>
                    <td>
                      <select name='TrukisERaamat'>
                        <option value='NULL'>Vali trükis/e-raamat</option>
                        <option value='N'>Trükis</option>
                        <option value='Y'>E-raamat</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Kujunduse osa:
                    </td>
                    <td>
                      <select name='KujunduseOsa'>
                        <option value='NULL'>Vali kujunduse maht</option>
                        <option value='1'>Illustreerimata/üksikute illustratsioonidega teos</option>
                        <option value='4'>Illustreeritud teos</option>
                        <option value='5'>Illustratsioone üle poole teosest</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Õppe- ja teadusk.:
                    </td>
                    <td>
                      <input
                        className='Lower'
                        type="checkbox"
                        onChange={this.ToggleIsAcademic}>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='NormalField'>
                      Ilmumiskoht:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='Ilmumiskoht'
                        maxLength='20'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Aasta:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='4'
                        name='Aasta'
                        maxLength='4'>
                      </input>
                    </td>
                  </tr>
                  {!this.state.displayISBNPuudub ? null :
                    <tr>
                      <td className='RequiredField'>
                        ISBN puudub:
                      </td>
                      <td>
                        <input
                          className='Lower'
                          type="checkbox"
                          onChange={this.ToggleISBNPuudub}>
                        </input>
                      </td>
                    </tr>}
                  {!this.state.ISBNPuudub ? null :
                    <tr>
                      <td></td>
                      <td id='FieTooltip'>
                        (ISBN numbri märkimata jätmine vähendab laenutuste tuvastamise tõenäosust)
                      </td>
                    </tr>}
                  {!this.state.displayISBN ? null :
                    <tr>
                      <td className='RequiredField'>
                        ISBN:
                      </td>
                      <td>
                        <input
                          type='text'
                          size='15'
                          name='ISBN'
                          maxLength='13'
                          placeholder='1234567890123'
                          onChange={this.changeISBN}>
                        </input>
                        <span className='redText'>Sisestage ainult numbrid!</span>
                      </td>
                    </tr>}
                  <tr>
                    <td className='RequiredField'>
                      Kirjastaja:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='Kirjastaja'
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className='MaxWidth'>
                <thead>
                  <tr>
                    <td colSpan='5'>
                      <div className='redText moveLower'>
                        NB! Palume märkida ainult enda autorlused.
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className='QuarterWidth'>Autori eesnimi</th>
                    <th className='QuarterWidth'>Autori perenimi</th>
                    <th>Autorsus</th>
                    <th>Sama tüüpi autoreid</th>
                    <th className='SmallWidth'><button type='button' className='StandardButton' onClick={this.AddAuthorship}>Lisa autorsus</button></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.authorships.map(function(author, i) {

                    if(author == null) {

                      return(<tr  key={i}></tr>);

                    }

                    else {

                      return(

                        <tr key={i}>
                          <td id='Center'>
                            <input
                              type='text'
                              size='27'
                              maxLength='50'
                              value={author.author_first_name}
                              onChange={this.ChangeAuthorFirstName.bind(this, i)}>
                            </input>
                          </td>
                          <td id='Center'>
                            <input
                              type='text'
                              size='27'
                              maxLength='50'
                              value={author.author_family_name}
                              onChange={this.ChangeAuthorFamilyName.bind(this, i)}>
                            </input>
                          </td>
                          <td id='Center'>
                            <select onChange={this.ChangeAuthorship.bind(this, i)} value={author.authorship_cd} className='Medium'>
                              <option value='NULL'>Vali autorsus</option>
                              <option value='A'>Autor</option>
                              <option value='K'>Koostaja</option>
                              <option value='E'>Eessõna autor</option>
                              <option value='J'>Järelsõna autor</option>
                              <option value='KU'>Kujundaja</option>
                              <option value='I'>Illustreerija</option>
                              <option value='T'>Tõlkija</option>
                              <option value='TA'>Tõlgitud teose originaali autor</option>
                            </select>
                          </td>
                          <td id='Center'>
                            <input
                              type='text'
                              size='1'
                              maxLength='4'
                              value={author.allocation}
                              onChange={this.ChangeAllocation.bind(this, i)}>
                            </input>
                          </td>
                          <td id='Center'>
                            <button type='button' id='TableButton' onClick={this.RemoveAuthorship.bind(this, i)}>Eemalda autorsus</button>
                          </td>
                        </tr>

                      );

                    }

                  }, this)}
                  <tr>
                    <td className='Center' colSpan='5'>
                      <button className='StandardButton' type='submit'>Lisa teos</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>

    );

  }

  AddAuthorship() {

    var authorships = this.state.authorships.slice(0);

    var authorship = {
      author_first_name: this.state.application.author_first_name == null ? ''
        : this.state.application.author_first_name.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
      author_family_name: this.state.application.author_family_name == null ? ''
        : this.state.application.author_family_name.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
      authorship_cd: 'NULL',
      allocation: '1'
    };

    authorships.push(authorship);

    this.setState({authorships: authorships});

  }

  back() {

    let url = this.props.router.url({
      name: 'EditApplication',
      params: { applicationID: this.props.response.params.applicationID }
    });

    this.props.router.navigate({ url });

  }

  ChangeAllocation(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].allocation = event.target.value;

    this.setState({authorships: authorships});

  }

  ChangeAuthorFamilyName(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].author_family_name = event.target.value;

    this.setState({authorships: authorships});

  }

  ChangeAuthorFirstName(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].author_first_name = event.target.value;

    this.setState({authorships: authorships});

  }

  ChangeAuthorship(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].authorship_cd = event.target.value;

    this.setState({authorships: authorships});

  }

  changeISBN(event) {

    if(event.target.value !== '' && event.target.value.replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"') !== '') {

      if(this.state.displayISBNPuudub) {

        this.setState({ displayISBNPuudub: false });

      }

    }

    else if(!this.state.displayISBNPuudub) {

      this.setState({ displayISBNPuudub: true });

    }

  }

  createNewWork(event) {

    var Ilmumiskoht = '';

    if(event.target.Ilmumiskoht.value.length > 0) {

      Ilmumiskoht = event.target.Ilmumiskoht.value;

    }

    var data =
      '{"party_id": ' + this.state.application.party_id + ','
      + '"title": "' + event.target.Pealkiri.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"is_ework": "' + event.target.TrukisERaamat.value + '",'
      + '"formation_part_id": ' + event.target.KujunduseOsa.value + ','
      + '"is_academic": "' + this.IsAcademic + '",'
      + '"come_out": "' + Ilmumiskoht.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"year_of_issue": ' + event.target.Aasta.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + ','
      + '"isbn": "' + (event.target.ISBN == null ? '' : event.target.ISBN.value).replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"publisher": "' + event.target.Kirjastaja.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

    var URL = this.APIURL
      + 'applications/' + this.state.application.appl_id + '/'
      +  this.state.application.party_id + '/works';

    axios.post(URL, data, this.config).then(this.EndFirstConnection).catch(this.EndFirstConnectionError);

  }

  endApplicationConnection(response) {

    var application = response.data.data;

    var authorships = [{
      author_first_name: application.author_first_name == null ? ''
        : application.author_first_name.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
      author_family_name: application.author_family_name == null ? ''
        : application.author_family_name.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
      authorship_cd: 'NULL',
      allocation: '1'
    }];

    this.setState({
      application: application,
      authorships: authorships,
      isLoading: false
    });

  }

  EndCheckISBNFormationPartIDConnection(Response, event) {

    if(Response.data.data.result != null && Response.data.data.result) {

      var formationPart;

      switch(Response.data.data.formation_part_id) {
        case 1:
          formationPart = 'Illustreerimata/üksikute illustratsioonidega teos'
          break;
        case 4:
          formationPart = 'Illustreeritud teos'
          break;
        case 5:
          formationPart = 'Illustratsioone üle poole teosest'
          break;
        default:
          formationPart = '';
      }

      this.message.push([
        {
          value: 'See teos on AHFis registreeritud erineva kujunduse mahuga (' + formationPart + ').',
          align: 'center'
        },
        {
          value: 'Palun kontrollige ja vajadusel muutke kujunduse mahtu.',
          align: 'center'
        }
      ]);

      this.setState({ showMessage: true });

    }

    this.createNewWork(event);

  }

  EndFirstConnection(Response) {

    this.message.push([{
      value: 'Uus teos lisatud!',
      align: 'center'
    }]);

    this.setState({ showMessage: true });

    var data = '';

    for(var i = 0; i < this.state.authorships.length; i ++) {

      var AuthorFirstName = 'N/A';

      if(this.state.authorships[i].author_first_name.length > 0) {

        AuthorFirstName = this.state.authorships[i].author_first_name;

      }

      var AuthorFamilyName = 'N/A';

      if(this.state.authorships[i].author_family_name.length > 0) {

        AuthorFamilyName = this.state.authorships[i].author_family_name;

      }

      if(this.state.authorships[i] != null) {

        if(data.length === 0) {

          data =
            '{"authorships":[{"author_first_name": "' + AuthorFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"author_family_name": "' + AuthorFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"authorship_cd": "' + this.state.authorships[i].authorship_cd + '",'
            + '"allocation":"' + this.state.authorships[i].allocation + '"}';

        }

        else {

          data =
            data
            + ',{"author_first_name": "' + AuthorFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"author_family_name": "' + AuthorFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"authorship_cd": "' + this.state.authorships[i].authorship_cd + '",'
            + '"allocation":"' + this.state.authorships[i].allocation + '"}';

        }

      }

    }

    if(data.length !== 0) {

      data = data + ']}';

      var URL =
        this.APIURL
        + 'applications/'
        + this.state.application.appl_id
        + '/works/'
        + Response.data.data.work_id
        + '/authorship/'
        + this.state.application.party_id;

      axios.post(URL, data, this.config).then(this.EndSecondConnection).catch(this.EndSecondConnectionError);

    }

    else {

      this.setState({ isLoading: false});

    }

  }

  EndFirstConnectionError(Response) {

    if
      (Response.response != null
      && Response.response.data != null
      && Response.response.data.errcode === 20000) {

      this.message.push([
        {
          value: 'Teose lisamine ebaõnnestus!',
          align: 'center'
        },
        {
          value: Response.response.data.message.substring(
            Response.response.data.message.indexOf('ORA-20000: ') + 11,
            Response.response.data.message.indexOf('\n')),
          align: 'center'
        }
      ]);

      this.setState({
        isLoading: false,
        showMessage: true
      });

    }

    else {

      this.message.push([{
        value: 'Teose lisamine ebaõnnestus!',
        align: 'center'
      }]);

      this.catchError(Response);

    }

  }

  EndSecondConnection(Response) {

    this.navigateBackAfterMessage = true;

    this.message.push([{
      value: 'Autorlus(ed) lisatud!',
      align: 'center'
    }]);

    this.setState({
      isLoading: false,
      showMessage: true
    });

  }

  EndSecondConnectionError(Response) {

    this.message.push([{
      value: 'Autorlus(t)e lisamine ebaõnnestus!',
      align: 'center'
    }]);

    this.catchError(Response);

  }

  HandleSubmit(event) {

    event.preventDefault();

    var errorMessage = [];

    var message = this.ValidatePealkiri(event.target.Pealkiri.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateTrukisERaamat(event.target.TrukisERaamat.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateKujunduseOsa(event.target.KujunduseOsa.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateIsAcademic(this.IsAcademic, event.target.KujunduseOsa.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateAasta(event.target.Aasta.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateISBN(event.target.ISBN == null ? '' : event.target.ISBN.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateKirjastaja(event.target.Kirjastaja.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateAuthorship(this.state.authorships);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    if(errorMessage.length === 0) {

      this.setState({ isLoading: true });

      var ISBN = (event.target.ISBN == null ? '' : event.target.ISBN.value).replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"');

      if(ISBN != null && ISBN.length > 0
        && event.target.KujunduseOsa.value != null
        && event.target.KujunduseOsa.value.length > 0) {

        var URL = this.APIURL + 'applications/'
          + this.state.application.party_id + '/works/isbn/'
          + event.target.KujunduseOsa.value + '/' + ISBN;

        axios.get(URL, this.config).then((response) => this.EndCheckISBNFormationPartIDConnection(response, event)).catch(this.catchError);

      }

      else {

        this.createNewWork(event);

      }

    }

    else {

      this.message.push(errorMessage);

      this.setState({ showMessage: true });

    }

  }

  RemoveAuthorship(Index) {

    if(this.state.authorships.length === 1) {

      this.message.push([{
        value: 'Teosel peab olema märgitud vähemalt üks autorsus!',
        align: 'center'
      }]);

      this.setState({ showMessage: true });

      return;

    }

    var authorships = this.state.authorships.slice(0);

    authorships.splice(Index, 1);

    this.setState({authorships: authorships});

  }

  setShowMessage(showMessage) {

    if(!showMessage) {

      if(this.navigateBackAfterMessage) {

        this.back();

      }

      else {

        this.setState({ showMessage: false });

      }

    }

    else {

      this.setState({showMessage: true});

    }

  }

  ToggleIsAcademic() {

    if(this.IsAcademic === 'N') {

      this.IsAcademic = 'Y';

    }

    else {

      this.IsAcademic = 'N';

    }

  }

  ToggleISBNPuudub() {

    if(this.state.ISBNPuudub) {

      this.setState({
        displayISBN: true,
        ISBNPuudub: false
      });

    }

    else {

      this.setState({
        displayISBN: false,
        ISBNPuudub: true
      });

    }

  }

  ValidateAasta(Value) {

    if(Value.length === 0) {

      return('Aasta peab olema täidetud!');

    }

    if(Value.search(/[^0-9]/) !== -1) {

      return('Aasta formaat on vale!');

    }

    return('');

  }

  ValidateAuthorship(Value) {

    if(Value.length === 0) {

      return('Teosel peab olema märgitud vähemalt üks autorsus!');

    }

    for(var i = 0; i < Value.length; i ++) {

      if(Value[i] != null
        &&
          (Value[i].author_first_name == null
          || Value[i].author_first_name.length === 0)
        &&
          (Value[i].author_family_name.length == null
          || Value[i].author_family_name.length === 0)) {

        return('Autori eesnimi või perenimi peab olema täidetud!');

      }

      if(Value[i].authorship_cd === 'NULL') {

        return('Vali autorsus!');

      }

      if(Value[i] != null &&
        (Value[i].allocation == null
        || Value[i].allocation.length === 0
        || Value[i].allocation.search(/[^0-9]/) !== -1)) {

        return('Sama tüüpi autoreid peab olema täisarv!');

      }

      if(Value[i].allocation === '0') {

        return('Sama tüüpi autoreid on alati vähemalt 1!');

      }

      for(var j = 0; j < Value.length; j ++) {

        if(i < j
          && Value[i].author_first_name === Value[j].author_first_name
          && Value[i].author_family_name === Value[j].author_family_name
          && Value[i].authorship_cd === Value[j].authorship_cd) {

          return('Ei tohi esineda kahte sama kirjeldusega autorsuse rida!');
  
        }

        if(i !== j
          && Value[i].authorship_cd === 'A' && Value[i].allocation === 1
          && (Value[j].authorship_cd === 'A'
            || Value[j].authorship_cd === 'K'
            || Value[j].authorship_cd === 'E'
            || Value[j].authorship_cd === 'J')) {

          return('Teose ainus autor ei saa samal ajal olla selle teose koostaja, ees- või järelsõna autor!');

        }

      }

    }

    return('');

  }

  ValidateIsAcademic(Value, KujunduseOsa) {

    var HasNoneIllustrator = false;

    for(var i = 0; i < this.state.authorships.length; i ++) {

      if(this.state.authorships[i]['authorship_cd'] !== 'I' && this.state.authorships[i]['authorship_cd'] !== 'KU') {

        HasNoneIllustrator = true;

        break;

      }

    }

    if(Value === 'Y' && KujunduseOsa === '1' && !HasNoneIllustrator) {

      return('Illustreerimata teose kujundust ei saa märkida õppe- ja teadusteoseks!');

    }

    return('');

  }

  ValidateISBN(Value) {

    if(!this.state.ISBNPuudub && Value.replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"').length === 0) {

      return('ISBN peab olema täidetud!');

    }

    if
      (!this.state.ISBNPuudub
      &&
        ((Value.toUpperCase().search('^[0-9]+X?$') === -1)
        || !(Value.length === 10 || Value.length === 13))) {

      return('ISBNi formaat on vale! Sisestage ainult 10 või 13 numbrit (nt. 1234567890123).');

    }

    return('');

  }

  ValidateKirjastaja(Value) {

    if(Value.length === 0) {

      return('Kirjastaja peab olema täidetud!');

    }

    return('');

  }

  ValidateKujunduseOsa(Value) {

    if(Value === 'NULL') {

      return('Vali kujunduse maht!');

    }

    return('');

  }

  ValidatePealkiri(Value) {

    if(Value.length === 0) {

      return('Pealkiri peab olema täidetud!');

    }

    return('');

  }

  ValidateTrukisERaamat(Value) {

    if(Value === 'NULL') {

      return('Vali trükis/e-raamat!');

    }

    return('');

  }

}