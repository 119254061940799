import React, { Component } from 'react';
import axios from 'axios';

import EditApplicationWork from './EditApplicationWork';

export default class EditPartyApplication extends Component {

  constructor(props) {

    super(props);

    this.EndSaveApplicationConnection = this.EndSaveApplicationConnection.bind(this);
    this.EndSavePayeeConnection = this.EndSavePayeeConnection.bind(this);
    this.EndWorkConnection = this.EndWorkConnection.bind(this);
    this.CloseEditApplication = this.CloseEditApplication.bind(this);
    this.CloseEditPayee = this.CloseEditPayee.bind(this);
    this.LoadWork = this.LoadWork.bind(this);
    this.OpenEditApplication = this.OpenEditApplication.bind(this);
    this.OpenEditPayee = this.OpenEditPayee.bind(this);
    this.SaveApplication = this.SaveApplication.bind(this);
    this.SavePayee = this.SavePayee.bind(this);
    this.ToggleRahasaaja = this.ToggleRahasaaja.bind(this);
    this.ToggleShowWorks = this.ToggleShowWorks.bind(this);
    this.ValidateAuthorFamilyName = this.ValidateAuthorFamilyName.bind(this);
    this.ValidateAuthorRelRatio = this.ValidateAuthorRelRatio.bind(this);
    this.ValidateIBAN = this.ValidateIBAN.bind(this);
    this.ValidatePayeeCount = this.ValidatePayeeCount.bind(this);
    this.ValidatePayeeFamilyName = this.ValidatePayeeFamilyName.bind(this);
    this.ValidatePayeeFirstName = this.ValidatePayeeFirstName.bind(this);
    this.ValidatePayeeIndividualCode = this.ValidatePayeeIndividualCode.bind(this);
    this.ValidatePayeeLegalCode = this.ValidatePayeeLegalCode.bind(this);
    this.ValidatePayeeLegalName = this.ValidatePayeeLegalName.bind(this);
    this.ValidatePayeePersonalCode = this.ValidatePayeePersonalCode.bind(this);
    this.ValidatePublicAuthor = this.ValidatePublicAuthor.bind(this);
    this.ValidateTaxpayerFamilyName = this.ValidateTaxpayerFamilyName.bind(this);
    this.ValidateTaxpayerFirstName = this.ValidateTaxpayerFirstName.bind(this);
    this.ValidateTaxpayerPersonalCode = this.ValidateTaxpayerPersonalCode.bind(this);
    this.ValidateTaxpayerResidencyCode = this.ValidateTaxpayerResidencyCode.bind(this);

    this.state = {
      EditApplication: false,
      EditPayee: false,
      ShowWorks: false,
    };

  }

  render() {

    var Options = {};

    if(this.props.Connecting) {

      Options['disabled'] = 'disabled';

    }

    return(

      <div>
        <div className='SectionName'>Taotluse andmed</div>
        {!this.state.EditApplication ?
          <table className='CurrentTable'>
            <thead>
              <tr>
                <th>Staatus</th>
                <th>Taotluse aeg</th>
                <th>Kinnituse aeg</th>
                <th>Roll</th>
                <th>Autori eesnimi</th>
                <th>Autori perenimi</th>
                <th>Avalik autor</th>
              </tr>
            </thead>
            <tbody>
              <tr onClick={this.OpenEditApplication}>
                <td>{this.props.Application.status_cd === 'E' ? 'Valmis' : (this.props.Application.status_cd === 'L' || this.props.Application.status_cd === 'K' ? 'Pooleli' : 'Kinnitatud')}</td>
                <td>{this.props.Application.create_dtime}</td>
                <td>{this.props.Application.status_cd === 'L' || this.props.Application.status_cd === 'K' ? '' : this.props.Application.change_dtime}</td>
                <td>{this.props.Application.applicant_role_cd === 'I' ? 'Isiklikult' : (this.props.Application.applicant_role_cd === 'E' ? 'Esindaja' : 'Pärija')}</td>
                <td>{this.props.Application.author_first_name}</td>
                <td>{this.props.Application.author_family_name}</td>
                <td>{this.props.Application.public_author}</td>
              </tr>
            </tbody>
          </table> :
          <form onSubmit={this.SaveApplication.bind(this)}>
            <table className='EditTable'>
              <tbody>
                <tr>
                  <td className='EditTableNormalField'>
                    Staatus:
                  </td>
                  <td className='EditTableValue'>
                    {this.props.Application.status_cd === 'E' ? 'Valmis' : (this.props.Application.status_cd === 'L' || this.props.Application.status_cd === 'K' ? 'Pooleli' : 'Kinnitatud')}
                  </td>
                </tr>
                <tr>
                  <td className='EditTableNormalField'>
                    Taotluse aeg:
                  </td>
                  <td className='EditTableValue'>
                    {this.props.Application.create_dtime}
                  </td>
                </tr>
                <tr>
                  <td className='EditTableNormalField'>
                    Kinnituse aeg:
                  </td>
                  <td className='EditTableValue'>
                    {this.props.Application.status_cd === 'L' || this.props.Application.status_cd === 'K' ? '' : this.props.Application.change_dtime}
                  </td>
                </tr>
                <tr>
                  <td className='EditTableNormalField'>
                    Roll:
                  </td>
                  <td className='EditTableValue'>
                    {this.props.Application.applicant_role_cd === 'I' ? 'Isiklikult' : (this.props.Application.applicant_role_cd === 'E' ? 'Esindaja' : 'Pärija')}
                  </td>
                </tr>
                <tr>
                  <td className='EditTableRequiredField'>
                    Autori eesnimi (või -nimed):
                  </td>
                  <td className='EditTableValue'>
                    <input
                      {...Options}
                      type='text'
                      size='25'
                      name='AuthorFirstName'
                      maxLength='50'
                      defaultValue={this.props.Application.author_first_name}>
                    </input>
                  </td>
                </tr>
                <tr>
                  <td className='EditTableRequiredField'>
                    Autori perekonnanimi:
                  </td>
                  <td className='EditTableValue'>
                    <input
                      {...Options}
                      type='text'
                      size='25'
                      name='AuthorFamilyName'
                      maxLength='50'
                      defaultValue={this.props.Application.author_family_name}>
                    </input>
                  </td>
                </tr>
                <tr>
                  <td className='EditTableRequiredField'>
                    Avalikustatav autorinimi:
                  </td>
                  <td className='EditTableValue'>
                    <input
                      {...Options}
                      type='text'
                      size='25'
                      name='PublicAuthor'
                      maxLength='50'
                      defaultValue={this.props.Application.public_author}>
                    </input>
                  </td>
                </tr>
                <tr>
                  <td className='NormalLabel'>
                    <button {...Options} onClick={this.CloseEditApplication}>Unusta muudatused <img className='Middle' src='/img/undo.png' alt=''></img></button>
                  </td>
                  <td className='Content'>
                    <button {...Options} type='submit'>Salvesta muudatused <img className='Middle' src='/img/ok.png' alt=''></img></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>}
        <p></p>
        <div className='SectionName'>Rahasaaja andmed</div>
        {!this.state.EditPayee ?
          <table className='CurrentTable'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Rahasaaja eesnimi</th>
                <th>Rahasaaja perenimi</th>
                <th>Rahasaaja tüüp</th>
                {this.props.Application.payee_legal_cd == null ? <th>Isikukood</th> : null}
                {this.props.Application.payee_individual_cd != null ? <th>FIE reg. nr.</th> : null}
                {this.props.Application.payee_legal_cd != null ? <th>Jur. isiku nimi</th> : null}
                {this.props.Application.payee_legal_cd != null ? <th>Jur. isiku reg. nr.</th> : null}
                <th>IBAN</th>
                <th>Protsent hüvitisest</th>
                <th>Rahasaajaid taotluses</th>
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <th>Maksumaksja eesnimi</th> : null}
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <th>Maksumaksja perenimi</th> : null}
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <th>Maksumaksja residentsus</th> : null}
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <th>Maksumaksja isikukood</th> : null}
              </tr>
            </thead>
            <tbody>
              <tr onClick={this.OpenEditPayee}>
                <td>{this.props.Application.payee_party_id}</td>
                <td>{this.props.Application.payee_first_name}</td>
                <td>{this.props.Application.payee_family_name}</td>
                <td>{this.props.Application.payee_legal_cd != null ? 'Jur. isik' : (this.props.Application.payee_individual_cd != null ? 'FIE' : 'Füüsiline isik')}</td>
                {this.props.Application.payee_legal_cd == null ? <td>{this.props.Application.payee_personal_cd}</td> : null}
                {this.props.Application.payee_individual_cd != null ? <td>{this.props.Application.payee_individual_cd}</td> : null}
                {this.props.Application.payee_legal_cd != null ? <td>{this.props.Application.payee_legal_name}</td> : null}
                {this.props.Application.payee_legal_cd != null ? <td>{this.props.Application.payee_legal_cd}</td> : null}
                <td>{this.props.Application.iban}</td>
                <td>{this.props.Application.author_rel_ratio}</td>
                <td>{this.props.Application.payee_cnt}</td>
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <td>{this.props.Application.taxpayer_first_name}</td> : null}
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <td>{this.props.Application.taxpayer_family_name}</td> : null}
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <td>{this.props.Application.taxpayer_resident_cd === 'EE' ? 'EE' : (this.props.Application.taxpayer_resident_cd == null ? '' : 'Muu')}</td> : null}
                {this.props.Application.payee_legal_cd == null && this.props.Application.payee_individual_cd == null ? <td>{this.props.Application.taxpayer_personal_cd}</td> : null}
              </tr>
            </tbody>
          </table> :
          <form onSubmit={this.SavePayee.bind(this)}>
            <table className='EditTable'>
              <tbody>
                <tr>
                  <td className='EditTableRequiredField'>
                    Rahasaaja tüüp:
                  </td>
                  <td className='EditTableValue'>
                    <select {...Options} onChange={this.ToggleRahasaaja} value={this.state.Rahasaaja}>
                      <option value='fuusiline_isik'>Füüsiline isik</option>
                      <option value='fie'>FIE</option>
                      <option value='jur_isik'>Jur. isik</option>
                    </select>
                  </td>
                </tr>
                {this.state.Rahasaaja === 'jur_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Eesnimi (või -nimed):
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='25'
                        name='PayeeFirstName'
                        defaultValue={this.props.Application.payee_first_name}
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>}
                {this.state.Rahasaaja === 'jur_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Perekonnanimi:</td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='25'
                        name='PayeeFamilyName'
                        defaultValue={this.props.Application.payee_family_name}
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>}
                {this.state.Rahasaaja === 'jur_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Isikukood:
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='12'
                        name='PayeePersonalCode'
                        defaultValue={this.props.Application.payee_personal_cd}
                        maxLength='20'>
                      </input>
                    </td>
                  </tr>}
                {this.state.Rahasaaja !== 'fie' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      FIE reg. nr.:
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='12'
                        name='PayeeIndividualCode'
                        defaultValue={this.props.Application.payee_individual_cd}
                        maxLength='8'>
                      </input>
                    </td>
                  </tr>}
                {this.state.Rahasaaja !== 'jur_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Jur. isiku nimi:
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='25'
                        name='PayeeLegalName'
                        defaultValue={this.props.Application.payee_legal_name}
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>}
                {this.state.Rahasaaja !== 'jur_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Jur. isiku reg. nr.:
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='12'
                        name='PayeeLegalCode'
                        defaultValue={this.props.Application.payee_legal_cd}
                        maxLength='11'>
                      </input>
                    </td>
                  </tr>}
                <tr>
                  <td className='EditTableRequiredField'>
                    IBAN:
                  </td>
                  <td className='EditTableValue'>
                    <input
                      {...Options} 
                      type='text'
                      size='25'
                      name='IBAN'
                      maxLength='50'
                      defaultValue={this.props.Application.iban}>
                    </input>
                  </td>
                </tr>
                <tr>
                  <td className='EditTableRequiredField'>
                    Protsent hüvitisest:</td>
                  <td className='EditTableValue'>
                    <input
                      {...Options}
                      type='text'
                      size='6'
                      name='AuthorRelRatio'
                      defaultValue={this.props.Application.author_rel_ratio}
                      maxLength='7'>
                    </input>
                  </td>
                </tr>
                <tr>
                  <td className='EditTableNormalField'>
                    Rahasaajaid taotluses:</td>
                  <td className='EditTableValue'>
                    <input
                      {...Options}
                      type='text'
                      size='6'
                      name='PayeeCount'
                      defaultValue={this.props.Application.payee_cnt}
                      maxLength='6'>
                    </input>
                  </td>
                </tr>
                {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Maksumaksja eesnimi (või -nimed):
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='25'
                        name='TaxpayerFirstName'
                        defaultValue={this.props.Application.taxpayer_first_name}
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>}
                {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Maksumaksja perenimi:
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='25'
                        name='TaxpayerFamilyName'
                        defaultValue={this.props.Application.taxpayer_family_name}
                        maxLength='50'>
                      </input>
                    </td>
                  </tr>}
                {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Maksumaksja residentsus:
                    </td>
                    <td className='EditTableValue'>
                      <select {...Options} name='TaxpayerResidencyCode' defaultValue={this.props.Application.taxpayer_resident_cd == null ? 'NULL' : (this.props.Application.taxpayer_resident_cd === 'EE' ? 'EE' : 'Mu')}>
                        {this.props.Application.taxpayer_resident_cd == null ? <option value='NULL'>Vali resident</option> : null}
                        <option value='EE'>EE</option>
                        <option value='Mu'>Muu</option>
                      </select>
                    </td>
                  </tr>}
                {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                  <tr>
                    <td className='EditTableRequiredField'>
                      Maksumaksja isikukood:
                    </td>
                    <td className='EditTableValue'>
                      <input
                        {...Options}
                        type='text'
                        size='12'
                        name='TaxpayerPersonalCode'
                        defaultValue={this.props.Application.taxpayer_personal_cd}
                        maxLength='20'>
                      </input>
                    </td>
                  </tr>}
                <tr>
                  <td className='NormalLabel'>
                    <button {...Options} onClick={this.CloseEditPayee}>Unusta muudatused <img className='Middle' src='/img/undo.png' alt=''></img></button>
                  </td>
                  <td className='Content'>
                    <button {...Options} type='submit'>Salvesta muudatused <img className='Middle' src='/img/ok.png' alt=''></img></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>}
        <p></p>
        <div className='SectionName'><button {...Options} onClick={this.ToggleShowWorks}>{this.state.ShowWorks ? 'Peida teosed' : 'Kuva teosed'}</button><p></p></div>
        {!this.state.ShowWorks ? null :
          (this.state.Work == null || this.state.Work.length === 0 ? 'Teoseid ei leitud!' :
            <table className='CurrentTable'>
              <thead>
                <tr>
                  <th>
                    ID
                  </th>
                  <th>
                    Pealkiri
                  </th>
                  <th>
                    E-raamat
                  </th>
                  <th>
                    Aasta
                  </th>
                  <th>
                    Autorlused
                  </th>
                  <th>
                    ISBN
                  </th>
                  <th>
                    Eri %
                  </th>
                  <th>
                    ÕT
                  </th>
                  <th>
                    Kujunduse osa
                  </th>
                  <th>
                    Laen.
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.Work.map(function(work, i) {

                  return(

                    <EditApplicationWork
                      APIURL={this.props.APIURL}
                      catchError={this.props.catchError}
                      ChangeConnecting={this.props.ChangeConnecting}
                      Connecting={this.props.Connecting}
                      key={i}
                      LoadWork={this.LoadWork}
                      ShowAlert={this.props.ShowAlert}
                      Token={this.props.Token}
                      Work={work}
                    />

                  );

                }, this)}
              </tbody>
            </table>
          )}
      </div>

    );

  }

  EndSaveApplicationConnection(Response) {

    this.props.ChangeConnecting(false);

    this.props.ShowAlert('Muudatused salvestatud!');

    this.props.LoadApplication(this.props.Application.party_id);

    this.CloseEditApplication();

  }

  EndSavePayeeConnection(Response) {

    this.props.ChangeConnecting(false);

    this.props.ShowAlert('Muudatused salvestatud!');

    this.props.LoadApplication(this.props.Application.party_id);

    this.CloseEditPayee();

  }

  EndWorkConnection(Response) {

    this.setState({ShowWorks: true});

    this.props.ChangeConnecting(false);

    var Work = Response[0].data.data.slice(0);

    var Authorship = Response[1].data.data.slice(0);

    for(var i = 0; i < Work.length; i ++) {

      var WorkAuthorship = [];

      for(var j = 0; j < Authorship.length; j ++) {

        if(Work[i].work_id === Authorship[j].work_id) {

          WorkAuthorship.push(Authorship[j]);

        }

      }

      Work[i]['authorship'] = WorkAuthorship;

    }

    this.setState({Work: Work});

  }

  CloseEditApplication() {

    this.setState({EditApplication: false});

  }

  CloseEditPayee() {

    this.setState({EditPayee: false});

  }

  LoadWork() {

    this.props.ChangeConnecting(true);

    var WorkURL = this.props.APIURL + 'applications/' + this.props.Application.appl_id + '/' + this.props.Application.party_id + '/works?sort=year_of_issue,a&sort=title,a';

    var AuthorshipURL = this.props.APIURL + 'applications/' + this.props.Application.appl_id + '/' + this.props.Application.party_id + '/authorships';

    var config = {

      headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
      timeout: 30000,
      auth: {username: this.props.Token}

    };

    axios.all([axios.get(WorkURL, config), axios.get(AuthorshipURL, config)]).then(this.EndWorkConnection).catch(this.props.catchError);

  }

  OpenEditApplication() {

    if(!this.props.Connecting) {

      this.setState({EditApplication: true});

    }

  }

  OpenEditPayee() {

    if(!this.props.Connecting) {

      this.setState({EditPayee: true});

      var Rahasaaja;

      if(this.props.Application.payee_legal_cd != null) {

        Rahasaaja = 'jur_isik';

      }

      else if(this.props.Application.payee_individual_cd != null) {

        Rahasaaja = 'fie';

      }

      else {

        Rahasaaja = 'fuusiline_isik';

      }

      this.setState({Rahasaaja: Rahasaaja});

    }

  }

  SaveApplication(event) {

		event.preventDefault();

    var AuthorFirstName = '';
    var AuthorFamilyName = '';
    var PublicAuthor = '';

    if(event.target.AuthorFirstName != null) {

      AuthorFirstName = event.target.AuthorFirstName.value;

    }

    if(event.target.AuthorFamilyName != null) {

      AuthorFamilyName = event.target.AuthorFamilyName.value;

    }

    if(event.target.PublicAuthor != null) {

      PublicAuthor = event.target.PublicAuthor.value;

    }

    var ErrorMessage =
      this.ValidateAuthorFamilyName(AuthorFamilyName)
      + this.ValidatePublicAuthor(PublicAuthor);

    if(ErrorMessage.length === 0) {

      this.props.ChangeConnecting(true);

      var data =
        '{"author_first_name": "' + AuthorFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"author_family_name": "' + AuthorFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"public_author": "' + event.target.PublicAuthor.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

      var URL = this.props.APIURL + 'applications/' + this.props.Application.appl_id;

      var config = {

        headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
        timeout: 30000,
        auth: {username: this.props.Token}

      };

      axios.put(URL, data, config).then(this.EndSaveApplicationConnection).catch(this.props.catchError);
    }

    else {

      this.props.ShowAlert(ErrorMessage);

    }

  }

  SavePayee(event) {

		event.preventDefault();

    var PayeeFirstName = '';
    var PayeeFamilyName = '';
    var PayeePersonalCode = '';
    var PayeeIndividualCode = '';
    var PayeeLegalName = '';
    var PayeeLegalCode = '';
    var TaxpayerFirstName = '';
    var TaxpayerFamilyName = '';
    var TaxpayerResidencyCode = '';
    var TaxpayerPersonalCode = '';

    if(event.target.PayeeFirstName != null) {

      PayeeFirstName = event.target.PayeeFirstName.value;

    }

    if(event.target.PayeeFamilyName != null) {

      PayeeFamilyName = event.target.PayeeFamilyName.value;

    }

    if(event.target.PayeePersonalCode != null) {

      PayeePersonalCode = event.target.PayeePersonalCode.value;

    }

    if(event.target.PayeeIndividualCode != null) {

      PayeeIndividualCode = event.target.PayeeIndividualCode.value;

    }

    if(event.target.PayeeLegalName != null) {

      PayeeLegalName = event.target.PayeeLegalName.value;

    }

    if(event.target.PayeeLegalCode != null) {

      PayeeLegalCode = event.target.PayeeLegalCode.value;

    }

    if(event.target.TaxpayerFirstName != null) {

      TaxpayerFirstName = event.target.TaxpayerFirstName.value;

    }

    if(event.target.TaxpayerFamilyName != null) {

      TaxpayerFamilyName = event.target.TaxpayerFamilyName.value;

    }

    if(event.target.TaxpayerResidencyCode != null) {

      TaxpayerResidencyCode = event.target.TaxpayerResidencyCode.value;

    }

    if(event.target.TaxpayerPersonalCode != null) {

      TaxpayerPersonalCode = event.target.TaxpayerPersonalCode.value;

    }

    var ErrorMessage =
      this.ValidatePayeeFirstName(PayeeFirstName)
      + this.ValidatePayeeFamilyName(PayeeFamilyName)
      + this.ValidatePayeePersonalCode(PayeePersonalCode)
      + this.ValidatePayeeIndividualCode(PayeeIndividualCode)
      + this.ValidatePayeeLegalName(PayeeLegalName)
      + this.ValidatePayeeLegalCode(PayeeLegalCode)
      + this.ValidateIBAN(event.target.IBAN.value, event.target.PayeeCount.value)
      + this.ValidateAuthorRelRatio(event.target.AuthorRelRatio.value)
      + this.ValidatePayeeCount(event.target.PayeeCount.value)
      + this.ValidateTaxpayerFirstName(TaxpayerFirstName)
      + this.ValidateTaxpayerFamilyName(TaxpayerFamilyName)
      + this.ValidateTaxpayerResidencyCode(TaxpayerResidencyCode)
      + this.ValidateTaxpayerPersonalCode(TaxpayerPersonalCode, event.target.TaxpayerResidencyCode.value);

    if(ErrorMessage.length === 0) {

      this.props.ChangeConnecting(true);

      var data =
        '{"payee_first_name": "' + PayeeFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_family_name": "' + PayeeFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_personal_cd": "' + PayeePersonalCode.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_individual_cd": "' + PayeeIndividualCode.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_legal_name": "' + PayeeLegalName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_legal_cd": "' + PayeeLegalCode.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"iban": "' + event.target.IBAN.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"author_rel_ratio": ' + event.target.AuthorRelRatio.value.replace(/,/g, '.').replace(/\\/g, '\\\\').replace(/"/g, '\\"') + ','
        + '"payee_cnt": ' + (event.target.PayeeCount.value.length > 0 ? event.target.PayeeCount.value : 0) + ','
        + '"taxpayer_first_name": "' + TaxpayerFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"taxpayer_family_name": "' + TaxpayerFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"taxpayer_resident_cd": "' + TaxpayerResidencyCode.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"taxpayer_personal_cd": "' + TaxpayerPersonalCode.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

      var URL = this.props.APIURL + 'applications/' + this.props.Application.appl_id;

      var config = {

        headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
        timeout: 30000,
        auth: {username: this.props.Token}

      };

      axios.put(URL, data, config).then(this.EndSavePayeeConnection).catch(this.props.catchError);

    }

    else {

      this.props.ChangeConnecting(false);

      this.props.ShowAlert(ErrorMessage);

    }

  }

  ToggleRahasaaja(event) {

    this.setState({Rahasaaja: event.target.value});

  }

  ToggleShowWorks() {

    if(this.state.ShowWorks) {

      this.setState({ShowWorks: false});

    }

    else {

      this.LoadWork();

    }

  }

  ValidateAuthorFamilyName(Value) {

    if(Value.length === 0) {

      return('Autori perenimi peab olema täidetud!');

    }

    return('');

  }

  ValidateAuthorRelRatio(Value) {

    Value = Value.replace(/,/g, '.');

    if(Value.length === 0) {

      return('Protsent hüvitisest peab olema täidetud!');

    }

    if(isNaN(Value) || Number(Value) > 100 || Number(Value) <= 0) {

      return('Protsent hüvitisest formaat on vale!');

    }

    return('');

  }

  ValidateIBAN(Value, PayeeCount) {

    var IBAN = Value.replace(/\s+/g, '');

    if(PayeeCount === 0) {

      return('');

    }

    if(IBAN.length === 0) {

      return('IBAN peab olema täidetud!');

    }

    if(IBAN.length > 34 || IBAN.length < 10 ||IBAN.search(/[^a-zA-Z0-9\s]/) !== -1) {

      return('IBAN formaat on vale!');

    }

    IBAN = IBAN.substring(4, IBAN.length).concat(IBAN.substring(0, 4)).toUpperCase();

    for(let i = 0; i < IBAN.length; i ++) {

      if(isNaN(IBAN[i])) {

        IBAN = IBAN.replace(IBAN[i], IBAN[i].charCodeAt(0) - 55);

      }

    }

    var checksum = IBAN.substring(0, 2);

    for (let i = 2; i < IBAN.length; i += 7) {

      var fragment = String(checksum) + IBAN.substring(i, i + 7);

      checksum = parseInt(fragment) % 97;

    }

    if(checksum !== 1) {

      return('IBAN formaat on vale!');

    }

    return('');

  }

  ValidatePayeeCount(Value) {

    if(Value.length > 0 && Value.search(/[^0-9]/) !== -1) {

      return('Rahasaajaid taotluses formaat on vale!');

    }

    return('');

  }

  ValidatePayeeFamilyName(Value) {

    if(this.state.Rahasaaja !== 'jur_isik' && Value.length === 0) {

      return('Perenimi peab olema täidetud!');

    }

    return('');

  }

  ValidatePayeeFirstName(Value) {

    if(this.state.Rahasaaja !== 'jur_isik' && Value.length === 0) {

      return('Eesnimi peab olema täidetud!');

    }

    return('');

  }

  ValidatePayeeIndividualCode(Value) {

    if(this.state.Rahasaaja === 'fie' && Value.length === 0) {

      return('FIE reg. nr. peab olema täidetud!');

    }

    if(this.state.Rahasaaja === 'fie' && Value.length > 0 && (Value.search(/[^0-9]/) !== -1 || Value.length !== 8)) {

      return('FIE reg. nr. formaat on vale!');

    }

    return('');

  }

  ValidatePayeeLegalCode(Value) {

    if(this.state.Rahasaaja === 'jur_isik' && Value.length === 0) {

      return('Jur. isiku reg. nr. peab olema täidetud!');

    }

    return('');

  }

  ValidatePayeeLegalName(Value) {

    if(this.state.Rahasaaja === 'jur_isik' && Value.length === 0) {

      return('Jur. isiku nimi peab olema täidetud!');

    }

    return('');

  }

  ValidatePayeePersonalCode(Value) {

    if(this.state.Rahasaaja !== 'jur_isik') {

      if(Value.length === 0) {

        return('Rahasaaja isikukood peab olema täidetud!');

      }

    }

    return('');

  }

  ValidatePublicAuthor(Value) {

    if(Value.length === 0) {

      return('Avalikustatav autorinimi peab olema täidetud!');

    }

    return('');

  }

  ValidateTaxpayerFamilyName(Value) {

    if(this.state.Rahasaaja === 'fuusiline_isik' && Value.length === 0) {

      return('Maksumaksja perenimi peab olema täidetud!');

    }

    return('');

  }

  ValidateTaxpayerFirstName(Value) {

    if(this.state.Rahasaaja === 'fuusiline_isik' && Value.length === 0) {

      return('Maksjamaksja eesnimi peab olema täidetud!');

    }

    return('');

  }

  ValidateTaxpayerPersonalCode(Value, TaxpayerResidencyCode) {

    if(this.state.Rahasaaja === 'fuusiline_isik') {

      if(Value.length === 0) {

        return('Maksumaksja isikukood peab olema täidetud!');

      }

      if(TaxpayerResidencyCode === 'EE' && (Value.search(/[^0-9]/) !== -1 || Value.length !== 11)) {

        return('Maksumaksja isikukoodi formaat on vale!');

      }

    }

    return('');

  }

  ValidateTaxpayerResidencyCode(Value) {

    if(this.state.Rahasaaja === 'fuusiline_isik' && Value === 'NULL') {

      return('Vali maksumaksja residentsus!');

    }

    return('');

  }

}
