import React from 'react';
import ReactDOM from 'react-dom/client';
import { createRouter, announce } from "@curi/router";
import { browser } from '@hickory/browser';
import { createRouterComponent } from "@curi/react-dom";
import "@babel/polyfill";
import './styles/style.css';
import App from './App';
import routes from "./routes";

let router = createRouter(browser, routes, {
  sideEffects: [
    announce(({ response }) => {
      return `Navigated to ${response.location.pathname}`;
    })
  ]
});
let Router = createRouterComponent(router);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    {!require('./config/config.js').isProduction ? <div className='warning'>Tegu on testkeskkonnaga!</div> : null}
      <App />
    </Router>
  </React.StrictMode>
);