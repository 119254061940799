import React, { Component } from 'react';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';

export default class NotFound extends Component {

	constructor(props) {

		super(props);

		this.setShowMessage = this.setShowMessage.bind(this);

	}

	render() {

		document.body.style.backgroundColor = '#E7D074';

		return(

			<div className='smallBoxWrapper'>
				<LoadingOverlay isLoading={true}/>
				<ShowMessage
					showMessage={true}
					message={[[{value: 'Lehekülge ei leitud!', align: 'center'}]]}
					setShowMessage={this.setShowMessage}
				/>
			</div>

		);

	}

	setShowMessage(showMessage) {

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

	}

}