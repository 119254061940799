import React, { Component } from 'react';

export default class LoadingOverlay extends Component {

	render() {

		if(this.props.isLoading) {

			return (

				<div className="loading"/>

			);

		}

		else {

			return (

				<div/>

			);

		}

	}

}