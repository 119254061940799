import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
  catchError,
  getCookie
} from './Utils';

export default class EditWork extends Component {

  constructor(props) {

    super(props);

    this.AddAuthorship = this.AddAuthorship.bind(this);
    this.back = this.back.bind(this);
    this.catchError = catchError.bind(this);
    this.ChangeAllocation = this.ChangeAllocation.bind(this);
    this.ChangeAuthorFamilyName = this.ChangeAuthorFamilyName.bind(this);
    this.ChangeAuthorFirstName = this.ChangeAuthorFirstName.bind(this);
    this.ChangeAuthorship = this.ChangeAuthorship.bind(this);
    this.changeISBN = this.changeISBN.bind(this);
    this.changeKujunduseOsa = this.changeKujunduseOsa.bind(this);
    this.changeTrukisERaamat = this.changeTrukisERaamat.bind(this);
    this.Delete = this.Delete.bind(this);
    this.endApplicationConnection = this.endApplicationConnection.bind(this);
    this.endAuthorshipsConnection = this.endAuthorshipsConnection.bind(this);
    this.EndCheckISBNFormationPartIDConnection = this.EndCheckISBNFormationPartIDConnection.bind(this);
    this.EndDeleteConnection = this.EndDeleteConnection.bind(this);
    this.EndRemoveAuthorshipConnection = this.EndRemoveAuthorshipConnection.bind(this);
    this.endSaveAuthorshipsConnection = this.endSaveAuthorshipsConnection.bind(this);
    this.endSaveWorkConnection = this.endSaveWorkConnection.bind(this);
    this.endWorkConnection = this.endWorkConnection.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.RemoveAuthorship = this.RemoveAuthorship.bind(this);
    this.saveAuthorships = this.saveAuthorships.bind(this);
    this.saveWork = this.saveWork.bind(this);
    this.setShowMessage = this.setShowMessage.bind(this);
    this.ToggleIsAcademic = this.ToggleIsAcademic.bind(this);
    this.ToggleISBNPuudub = this.ToggleISBNPuudub.bind(this);
    this.ValidateAasta = this.ValidateAasta.bind(this);
    this.ValidateAuthorship = this.ValidateAuthorship.bind(this);
    this.ValidateIsAcademic = this.ValidateIsAcademic.bind(this);
    this.ValidateISBN = this.ValidateISBN.bind(this);
    this.ValidateKirjastaja = this.ValidateKirjastaja.bind(this);
    this.ValidateKujunduseOsa = this.ValidateKujunduseOsa.bind(this);
    this.ValidatePealkiri = this.ValidatePealkiri.bind(this);
    this.ValidateTrukisERaamat = this.ValidateTrukisERaamat.bind(this);

    this.APIURL = require('./../config/config.js').APIURL;
    this.config = {
      headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
      timeout: 30000,
      auth: {username: getCookie('AHFToken')},
    };
    this.doAfterError = false;
    this.IsAcademic = 'N';
    this.message = [];
    this.navigateAfterMessage = false;
    this.state = {
      application: {},
      authorships: [],
      checkboxIsAcademic: false,
      checkboxISBNPuudub: false,
      displayISBN: true,
      displayISBNPuudub: true,
      ISBNPuudub: true,
      ISBNValue: '',
      isLoading: false,
      KujunduseOsa: 'NULL',
      work: {},
      showMessage: false,
      TrukisERaamat: 'NULL'
    };

  }
  
  componentDidMount() {

    this.setState({ isLoading: true });

    var URL = this.APIURL + 'applications/' + this.props.response.params.applicationID;

    axios.get(URL, this.config).then(this.endApplicationConnection).catch(this.catchError);

  }

  render() {

    if(getCookie('AHFLevel') === '2') {

      document.body.style.backgroundColor = '#BA491C';

    }

    else {

      document.body.style.backgroundColor = '#E7D074';

    }

    return(

      <div id='applicationsBoxWrapper'>
        <LoadingOverlay isLoading={this.state.isLoading}/>
        <ShowMessage
          showMessage={this.state.showMessage}
          message={this.message}
          setShowMessage={this.setShowMessage}
        />
        <div id='ApplicationsBox' className='OuterBox'>
          <table className='MaxWidth'>
            <tbody>
              <tr>
                <td className='Logo'>
                  <img src='/img/logo.gif' alt=''></img>
                </td>
                <td className='Center'>
                  <h2>Teose muutmine avaldusel nr. {this.props.response.params.applicationID}</h2>
                </td>
                <td className='TopButtons'>
                  <button type='button' className='StandardButton' id='ButtonWidth' onClick={this.back}>Tagasi</button>
                  <br/>
                  <button type='button' className='StandardButton' id='ButtonWidth' onClick={this.Delete}>Kustuta see teos</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='InnerBox'>
            <form onSubmit={this.HandleSubmit}>
              <table className='MaxWidth'>
                <tbody>
                  <tr>
                    <td className='RequiredField' id='HalfWidth'>
                      Pealkiri:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='60'
                        name='Pealkiri'
                        maxLength='1000'
                        defaultValue={this.state.work.title}>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Trükis/e-raamat:
                    </td>
                    <td>
                      <select
                        name='TrukisERaamat'
                        onChange={this.changeTrukisERaamat}
                        value={this.state.TrukisERaamat}>
                        <option value='NULL'>Vali trükis/e-raamat</option>
                        <option value='N'>Trükis</option>
                        <option value='Y'>E-raamat</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Kujunduse osa:
                    </td>
                    <td>
                      <select
                        name='KujunduseOsa'
                        onChange={this.changeKujunduseOsa}
                        value={this.state.KujunduseOsa}>
                        <option value='NULL'>Vali kujunduse maht</option>
                        <option value='1'>Illustreerimata/üksikute illustratsioonidega teos</option>
                        <option value='4'>Illustreeritud teos</option>
                        <option value='5'>Illustratsioone üle poole teosest</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Õppe- ja teadusk.:
                    </td>
                    <td>
                      <input
                        name='checkboxIsAcademic'
                        checked={this.state.checkboxIsAcademic}
                        className='Lower'
                        type="checkbox"
                        onChange={this.ToggleIsAcademic}>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='NormalField'>
                      Ilmumiskoht:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='Ilmumiskoht'
                        maxLength='20'
                        defaultValue={this.state.work.come_out}>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Aasta:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='4'
                        name='Aasta'
                        maxLength='4'
                        defaultValue={this.state.work.year_of_issue}>
                      </input>
                    </td>
                  </tr>
                  {!this.state.displayISBNPuudub ? null :
                    <tr>
                      <td className='RequiredField'>
                        ISBN puudub:
                      </td>
                      <td>
                        <input
                          name='checkboxISBNPuudub'
                          checked={this.state.checkboxISBNPuudub}
                          className='Lower'
                          type="checkbox"
                          onChange={this.ToggleISBNPuudub}>
                        </input>
                      </td>
                    </tr>}
                  {!this.state.checkboxISBNPuudub ? null :
                    <tr>
                      <td></td>
                      <td id='FieTooltip'>
                        (ISBN numbri märkimata jätmine vähendab laenutuste tuvastamise tõenäosust)
                      </td>
                    </tr>}
                  {!this.state.displayISBN ? null :
                    <tr>
                      <td className='RequiredField'>
                        ISBN:
                      </td>
                      <td>
                        <input
                          type='text'
                          size='15'
                          name='ISBN'
                          value={this.state.ISBNValue}
                          maxLength='13'
                          placeholder='1234567890123'
                          onChange={this.changeISBN}>
                        </input>
                        <span className='redText'>Sisestage ainult numbrid!</span>
                      </td>
                    </tr>}
                  <tr>
                    <td className='RequiredField'>
                      Kirjastaja:
                    </td>
                    <td>
                      <input
                        type='text'
                        size='25'
                        name='Kirjastaja'
                        maxLength='50'
                        defaultValue={this.state.work.publisher}>
                      </input>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className='MaxWidth'>
                <thead>
                  <tr>
                    <td colSpan='5'>
                      <div className='redText moveLower'>
                        NB! Palume märkida ainult enda autorlused.
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className='QuarterWidth'>Autori eesnimi</th>
                    <th className='QuarterWidth'>Autori perenimi</th>
                    <th>Autorsus</th>
                    <th>Sama tüüpi autoreid</th>
                    <th className='SmallWidth'><button type='button' className='StandardButton' onClick={this.AddAuthorship}>Lisa autorsus</button></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.authorships.map(function(author, i) {

                    if(author == null) {

                      return(<tr key={i}></tr>);

                    }

                    else {

                      return(

                        <tr key={i}>
                          <td id='Center'>
                            <input
                              type='text'
                              size='27'
                              maxLength='50'
                              defaultValue={author.author_first_name}
                              onChange={this.ChangeAuthorFirstName.bind(this, i)}>
                            </input>
                          </td>
                          <td id='Center'>
                            <input
                              type='text'
                              size='27'
                              maxLength='50'
                              defaultValue={author.author_family_name}
                              onChange={this.ChangeAuthorFamilyName.bind(this, i)}>
                            </input>
                          </td>
                          <td id='Center'>
                            <select
                              onChange={this.ChangeAuthorship.bind(this, i)}
                              value={author.authorship_cd} className='Medium'>
                              <option value='NULL'>Vali autorsus</option>
                              <option value='A'>Autor</option>
                              <option value='K'>Koostaja</option>
                              <option value='E'>Eessõna autor</option>
                              <option value='J'>Järelsõna autor</option>
                              <option value='KU'>Kujundaja</option>
                              <option value='I'>Illustreerija</option>
                              <option value='T'>Tõlkija</option>
                              <option value='TA'>Tõlgitud teose originaali autor</option>
                            </select>
                          </td>
                          <td id='Center'>
                            <input
                              type='text'
                              size='1'
                              maxLength='4'
                              value={author.allocation}
                              onChange={this.ChangeAllocation.bind(this, i)}>
                            </input>
                          </td>
                          <td id='Center'>
                            <button type='button' id='TableButton' onClick={this.RemoveAuthorship.bind(this, i)}>Eemalda autorsus</button>
                          </td>
                        </tr>

                      );

                    }

                  }, this)}
                  <tr>
                    <td className='Center' colSpan='5'>
                      <button className='StandardButton' type='submit'>Salvesta muudatused</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>

    );

  }

  AddAuthorship() {

    var authorships = this.state.authorships.slice(0);

    authorships.push({
      author_first_name: this.state.application.author_first_name == null ? ''
      : this.state.application.author_first_name.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
      author_family_name: this.state.application.author_family_name == null ? ''
        : this.state.application.author_family_name.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
      authorship_cd: 'NULL',
      allocation: '1',
      status: 'N'
    });

    this.setState({authorships: authorships});

  }

  back() {

    let url = this.props.router.url({
      name: 'EditApplication',
      params: { applicationID: this.props.response.params.applicationID }
    });

    this.props.router.navigate({ url });

  }

  ChangeAllocation(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].allocation = event.target.value;

    this.setState({authorships: authorships});

  }

  ChangeAuthorFamilyName(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].author_family_name = event.target.value;

    this.setState({authorships: authorships});

  }

  ChangeAuthorFirstName(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].author_first_name = event.target.value;

    this.setState({authorships: authorships});

  }

  ChangeAuthorship(Index, event) {

    var authorships = this.state.authorships.slice(0);

    authorships[Index].authorship_cd = event.target.value;

    this.setState({authorships: authorships});

  }

  changeISBN(event) {

    let displayISBNPuudub = this.state.displayISBNPuudub;

    let ISBNValue = event.target.value.replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"');

    if(ISBNValue !== '') {

      displayISBNPuudub = false;

    }

    else {

      displayISBNPuudub = true;

    }

    this.setState({
      displayISBNPuudub: displayISBNPuudub,
      ISBNValue: ISBNValue
    });

  }

  changeKujunduseOsa(event) {

    this.setState({ KujunduseOsa: event.target.value });

  }

  changeTrukisERaamat(event) {

    this.setState({ TrukisERaamat: event.target.value });

  }

  Delete() {

    this.setState({ isLoading: true });

    var WorkURL = this.APIURL + 'applications/' + this.state.work.appl_id + '/' + this.state.work.party_id + '/works/' + this.state.work.work_id + '/' + this.state.work.formation_part_id;

    var AuthorshipURL = this.APIURL + 'applications/' + this.state.work.appl_id + '/works/' + this.state.work.work_id + '/authorship/' + this.state.work.party_id;

    axios.all([axios.delete(WorkURL, this.config), axios.delete(AuthorshipURL, this.config)]).then(this.EndDeleteConnection).catch(this.catchError);

  }

  endApplicationConnection(response) {

    var application = response.data.data;

    var URL = this.APIURL
      + 'applications/' + application.appl_id
      + '/' + application.party_id
      + '/works/' + this.props.response.params.workID;

    axios.get(URL, this.config).then(this.endWorkConnection).catch(this.catchError);

    this.setState({ application: application });

  }

  endAuthorshipsConnection(response) {

    var authorships = response.data.data;

    for(let i = 0; i < authorships.length; i ++) {

      authorships[i].allocation = authorships[i].allocation.toString();

    }
    this.setState({
      authorships: authorships,
      isLoading: false
    });

  }

  EndCheckISBNFormationPartIDConnection(Response, event) {

    if(Response.data.data.result != null && Response.data.data.result) {

      var formationPart;

      switch(Response.data.data.formation_part_id) {
        case 1:
          formationPart = 'Illustreerimata/üksikute illustratsioonidega teos'
          break;
        case 4:
          formationPart = 'Illustreeritud teos'
          break;
        case 5:
          formationPart = 'Illustratsioone üle poole teosest'
          break;
        default:
          formationPart = '';
      }

      this.message.push([
        {
          value: 'See teos on AHFis registreeritud erineva kujunduse mahuga (' + formationPart + ').',
          align: 'center'
        },
        {
          value: 'Palun kontrollige ja vajadusel muutke kujunduse mahtu.',
          align: 'center'
        }
      ]);

      this.setState({ showMessage: true });

    }

    this.saveWork(event);

  }

  EndDeleteConnection(Response) {

    this.message.push([
      {
        value: 'Teos ja autorsused kustutatud!',
        align: 'center'
      }
    ]);

    this.navigateAfterMessage = true;

    this.setState({ showMessage: true });

  }

  EndRemoveAuthorshipConnection(Response) {

    var authorships = this.state.authorships.slice();

    authorships.splice(this.targetAuthorship, 1);

    this.setState({
      authorships: authorships,
      isLoading: false
    });
    
  }

  endSaveAuthorshipsConnection(Response) {

    this.doAfterError = false;

    var authorships = this.state.authorships.slice();

    for(let i = 0; i < authorships.length; i ++) {

      authorships[i].status = null;

    }

    this.message.push([{
      value: 'Autorlus(ed) salvestatud!',
      align: 'center'
    }]);

    this.setState({
      authorships: authorships,
      isLoading: false,
      showMessage: true
    });

  }

  endSaveWorkConnection(Response) {

    this.message.push([{
      value: 'Teos salvestatud!',
      align: 'center'
    }]);

    axios.delete(this.APIURL + 'applications/' + this.state.work.appl_id + '/works/' + this.state.work.work_id + '/authorship/' + this.state.work.party_id, this.config).then(response => this.saveAuthorships(Response, response)).catch(this.catchError);

  }

  endWorkConnection(response) {

    let work = response.data.data;

    let ISBNPuudub = false;

    let checkboxISBNPuudub = false;

    if(work.isbn == null || work.isbn === '') {

      ISBNPuudub = true;

      checkboxISBNPuudub = true;

    }

    this.IsAcademic = work.is_academic;

    let TrukisERaamat = null;

    if(work.is_ework != null) {

      work.is_ework = work.is_ework.toString();

      TrukisERaamat = work.is_ework;

    }

    work.formation_part_id = work.formation_part_id.toString();

    this.setState({
      checkboxIsAcademic: (this.IsAcademic === 'Y'),
      checkboxISBNPuudub: checkboxISBNPuudub,
      displayISBN: !ISBNPuudub,
      displayISBNPuudub: ISBNPuudub,
      ISBNPuudub: ISBNPuudub,
      ISBNValue: work.isbn || '',
      KujunduseOsa: work.formation_part_id,
      TrukisERaamat: TrukisERaamat,
      work: work
    });

    var URL = this.APIURL
      + 'applications/' + this.props.response.params.applicationID
      + '/works/' + this.props.response.params.workID
      + '/authorship/' + this.state.application.party_id;

    axios.get(URL, this.config).then(this.endAuthorshipsConnection).catch(this.catchError);

  }

  HandleSubmit(event) {

    event.preventDefault();

    var errorMessage = [];

    var message = this.ValidatePealkiri(event.target.Pealkiri.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateTrukisERaamat(event.target.TrukisERaamat.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateKujunduseOsa(event.target.KujunduseOsa.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateIsAcademic(this.IsAcademic, event.target.KujunduseOsa.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateAasta(event.target.Aasta.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateISBN(event.target.ISBN == null ? '' : event.target.ISBN.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateKirjastaja(event.target.Kirjastaja.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateAuthorship(this.state.authorships);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    if(errorMessage.length === 0) {

      this.setState({ isLoading: true });

      var ISBN = (event.target.ISBN == null ? '' : event.target.ISBN.value).replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"');

      if(ISBN != null && ISBN.length > 0
        && event.target.KujunduseOsa.value != null
        && event.target.KujunduseOsa.value.length > 0) {

        var URL = this.APIURL + 'applications/'
          + this.state.application.party_id + '/works/isbn/'
          + event.target.KujunduseOsa.value + '/' + ISBN;

        axios.get(URL, this.config).then(response => this.EndCheckISBNFormationPartIDConnection(response, event)).catch(this.catchError);

      }

      else {

        this.saveWork(event);

      }

    }

    else {

      this.message.push(errorMessage);

      this.setState({ showMessage: true });

    }

  }

  RemoveAuthorship(Index) {

    if(this.state.authorships.length === 1) {

      this.message.push([{
        value: 'Teosel peab olema märgitud vähemalt üks autorsus!',
        align: 'center'
      }]);

      this.setState({ showMessage: true });

    }

    else {

      this.targetAuthorship = Index;

      this.EndRemoveAuthorshipConnection();

    }

  }

  saveAuthorships(Response, response) {

    var data = '';

    for(let i = 0; i < this.state.authorships.length; i ++) {

      var AuthorFirstName = 'N/A';

      if(this.state.authorships[i].author_first_name.length > 0) {

        AuthorFirstName = this.state.authorships[i].author_first_name;

      }

      var AuthorFamilyName = 'N/A';

      if(this.state.authorships[i].author_family_name.length > 0) {

        AuthorFamilyName = this.state.authorships[i].author_family_name;

      }

      if(this.state.authorships[i] != null) {

        if(data.length === 0) {

          data =
            '{"authorships":[{"author_first_name": "' + AuthorFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"author_family_name": "' + AuthorFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"authorship_cd": "' + this.state.authorships[i].authorship_cd + '",'
            + '"allocation":"' + this.state.authorships[i].allocation + '"}';

        }

        else {

          data =
            data
            + ',{"author_first_name": "' + AuthorFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"author_family_name": "' + AuthorFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
            + '"authorship_cd": "' + this.state.authorships[i].authorship_cd + '",'
            + '"allocation":"' + this.state.authorships[i].allocation + '"}';

        }

      }

    }

    if(data.length !== 0) {

      this.doAfterError = true;

      data = data + ']}';

      var URL =
        this.APIURL
        + 'applications/'
        + this.state.application.appl_id
        + '/works/'
        + Response.data.data.work_id
        + '/authorship/'
        + this.state.application.party_id;

      axios.post(URL, data, this.config).then(this.endSaveAuthorshipsConnection).catch(this.catchError);

    }

    else {

      this.setState({
        isLoading: false,
        showMessage: true
      });

    }

  }

  saveWork(event) {

    event.preventDefault();

    var Ilmumiskoht = '';

    if(event.target.Ilmumiskoht.value.length > 0) {

      Ilmumiskoht = event.target.Ilmumiskoht.value;

    }

    var data =
      '{"party_id": ' + this.state.application.party_id + ','
      + '"title": "' + event.target.Pealkiri.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"is_ework": "' + event.target.TrukisERaamat.value + '",'
      + '"formation_part_id": ' + event.target.KujunduseOsa.value + ','
      + '"is_academic": "' + this.IsAcademic + '",'
      + '"come_out": "' + Ilmumiskoht.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"year_of_issue": ' + event.target.Aasta.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + ','
      + '"isbn": "' + (event.target.ISBN == null ? '' : event.target.ISBN.value).replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"publisher": "' + event.target.Kirjastaja.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

    var URL = this.APIURL
      + 'applications/' + this.state.work.appl_id
      + '/' + this.state.work.party_id
      + '/works/' + this.state.work.work_id;

    axios.put(URL, data, this.config).then(this.endSaveWorkConnection).catch(this.catchError);

  }

  setShowMessage(showMessage) {

    if(!showMessage) {

      if(this.navigateBackAfterMessage) {

        let url = this.props.router.url({ name: 'Applications' });

        this.props.router.navigate({ url });

      }

      else if(this.navigateAfterMessage) {

        this.back();

      }

      else if(this.doAfterError) {

        this.doAfterError = false;

        this.message.push([{
          value: 'Autorlus(t)e lisamine ebaõnnestus!',
          align: 'center'
        }]);

        this.setState({showMessage: true});

      }

      else {

        this.setState({showMessage: false});

      }

    }

    else {

      this.setState({showMessage: true});

    }

  }

  ToggleIsAcademic() {

    if(this.IsAcademic === 'N') {

      this.IsAcademic = 'Y';

      this.setState({checkboxIsAcademic: true});

    }

    else {

      this.IsAcademic = 'N';

      this.setState({checkboxIsAcademic: false});

    }

  }

  ToggleISBNPuudub() {

    if(this.state.ISBNPuudub) {

      this.setState({
        checkboxISBNPuudub: false,
        displayISBN: true,
        ISBNPuudub: false
      });

    }

    else {

      this.setState({
        checkboxISBNPuudub: true,
        displayISBN: false,
        ISBNPuudub: true
      });

    }

  }

  ValidateAasta(Value) {

    if(Value.length === 0) {

      return('Aasta peab olema täidetud!');

    }

    if(Value.search(/[^0-9]/) !== -1) {

      return('Aasta formaat on vale!');

    }

    return('');

  }

  ValidateAuthorship(Value) {

    if(Value.length === 0) {

      return('Teosel peab olema märgitud vähemalt üks autorsus!');

    }

    for(var i = 0; i < Value.length; i ++) {

      if(Value[i] != null
        &&
          (Value[i].author_first_name == null
          || Value[i].author_first_name.length === 0)
        &&
          (Value[i].author_family_name.length == null
          || Value[i].author_family_name.length === 0)) {

        return('Autori eesnimi ja perenimi peavad olema täidetud!');

      }

      if(Value[i].authorship_cd === 'NULL') {

        return('Vali autorsus!');

      }

      if(Value[i] != null &&
        (Value[i].allocation == null
        || Value[i].allocation.length === 0
        || Value[i].allocation.search(/[^0-9]/) !== -1)) {

        return('Sama tüüpi autoreid peab olema täisarv!');

      }

      if(Value[i].allocation === '0') {

        return('Sama tüüpi autoreid on alati vähemalt 1!');

      }

      for(var j = 0; j < Value.length; j ++) {

        if(i < j
          && Value[i].author_first_name === Value[j].author_first_name
          && Value[i].author_family_name === Value[j].author_family_name
          && Value[i].authorship_cd === Value[j].authorship_cd) {

          return('Ei tohi esineda kahte sama kirjeldusega autorsuse rida!');
  
        }

        if(i !== j
          && Value[i].authorship_cd === 'A' && Value[i].allocation === 1
          && (Value[j].authorship_cd === 'A'
            || Value[j].authorship_cd === 'K'
            || Value[j].authorship_cd === 'E'
            || Value[j].authorship_cd === 'J')) {

          return('Teose ainus autor ei saa samal ajal olla selle teose koostaja, ees- või järelsõna autor!');

        }

      }

    }

    return('');

  }

  ValidateIsAcademic(Value, KujunduseOsa) {

    var HasNoneIllustrator = false;

    for(var i = 0; i < this.state.authorships.length; i ++) {

      if(this.state.authorships[i]['authorship_cd'] !== 'I' && this.state.authorships[i]['authorship_cd'] !== 'KU') {

        HasNoneIllustrator = true;

        break;

      }

    }

    if(Value === 'Y' && KujunduseOsa === '1' && !HasNoneIllustrator) {

      return('Illustreerimata teose kujundust ei saa märkida õppe- ja teadusteoseks!');

    }

    return('');

  }

  ValidateISBN(Value) {

    if(!this.state.ISBNPuudub && Value.replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"').length === 0) {

      return('ISBN peab olema täidetud!');

    }

    if
      (!this.state.ISBNPuudub
      &&
        ((Value.toUpperCase().search('^[0-9]+X?$') === -1)
        || !(Value.length === 10 || Value.length === 13))) {

      return('ISBNi formaat on vale! Sisestage ainult 10 või 13 numbrit (nt. 1234567890123).');

    }

    return('');

  }

  ValidateKirjastaja(Value) {

    if(Value.length === 0) {

      return('Kirjastaja peab olema täidetud!');

    }

    return('');

  }

  ValidateKujunduseOsa(Value) {

    if(Value === 'NULL') {

      return('Vali kujunduse maht!');

    }

    return('');

  }

  ValidatePealkiri(Value) {

    if(Value.length === 0) {

      return('Pealkiri peab olema täidetud!');

    }

    return('');

  }

  ValidateTrukisERaamat(Value) {

    if(Value === 'NULL') {

      return('Vali trükis/e-raamat!');

    }

    return('');

  }

}