import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	catchError,
	getCookie
} from './Utils';

export default class AdminEditApplication extends Component {

	constructor(props) {

		super(props);

		this.accept = this.accept.bind(this);
		this.addNewWork = this.addNewWork.bind(this);
		this.back = this.back.bind(this);
		this.catchError = catchError.bind(this);
		this.changeWorkAcademicStatus = this.changeWorkAcademicStatus.bind(this);
		this.endApplicationConnection = this.endApplicationConnection.bind(this);
		this.endAuthorshipsConnection = this.endAuthorshipsConnection.bind(this);
		this.endIsAcademicConnection = this.endIsAcademicConnection.bind(this);
		this.endPartyConnection = this.endPartyConnection.bind(this);
		this.endWorksConnection = this.endWorksConnection.bind(this);
		this.nullify = this.nullify.bind(this);
		this.reject = this.reject.bind(this);
		this.saveIsAcademic = this.saveIsAcademic.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			application: {},
			applicationPayee: '',
			isLoading: true,
			party: {},
			showMessage: false,
			works: [],
			worksAcademicStatus: []
		};

	}

	componentDidMount() {

		var URL = this.APIURL + 'applications/' + this.props.response.params.applicationID;

		axios.get(URL, this.config).then(this.endApplicationConnection).catch(this.catchError);

	}

	render() {

		document.body.style.backgroundColor = '#BA491C';

		var Options = {};

		if(this.state.isLoading) {

			Options['disabled'] = 'disabled';

		}

		var NoWorks = {};

		if(this.state.works.length === 0) {

			NoWorks['disabled'] = 'disabled';

		}

		return(

			<div id='applicationsBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='ApplicationsBox' className='OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center' colSpan='2'>
									<h2>Avaldus nr: {this.state.application.appl_id}</h2>
								</td>
								<td className='TopButtons'>
									<button
										{...Options}
										type='button'
										className='StandardButton'
										id='ButtonWidth'
										onClick={this.back}>
										Tagasi
									</button>
									<br/>
									<button
										{...Options}
										type='button'
										className='StandardButton'
										id='ButtonWidth'
										onClick={this.reject}>
										Saada tagasi
									</button>
									<br/>
									<button
										{...Options}
										{...NoWorks}
										type='button'
										className='StandardButton'
										id='ButtonWidth'
										onClick={this.accept}>
										Kinnita
									</button>
									<br/>
									<button
										{...Options}
										type='button'
										className='StandardButton'
										id='ButtonWidth'
										onClick={this.nullify}>
										Tühista
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='Header'>
						<table className='MaxWidth'>
							<tbody>
								<tr>
									<td className='HeadingCell'>
										Esitaja: {
											this.state.party.applicant_first_name == null
											&& this.state.party.applicant_family_name == null ? null :
											<Link
												className='linkLikeText'
												name="EditApplicant"
												params={{ partyID: this.state.party.party_id }}
												state={{ applicationID: this.state.application.appl_id }}>
												{this.state.party.applicant_first_name} {this.state.party.applicant_family_name}
											</Link>
										}
									</td>
									<td className='HeadingCell'>
										Autor: {
											this.state.application.author_first_name == null
											&& this.state.application.author_family_name == null ? null :
											<Link
												className='linkLikeText'
												name="EditAuthor"
												params={{ applicationID: this.state.application.appl_id }}>
												{this.state.application.author_first_name} {this.state.application.author_family_name}
											</Link>
										}
									</td>
									<td className='HeadingCell'>
										Rahasaaja(d): {
											this.state.application.payee_party_id == null
											|| this.state.applicationPayee == null ? null :
											<Link
												className='linkLikeText'
												name="EditPayee"
												params={{ applicationID: this.state.application.appl_id }}>
												{this.state.applicationPayee}
											</Link>
										}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div>
						<p></p>
						<div className='InnerBox'>
							<table className='ApplicationTable'>
								<thead>
									<tr>
										<th className='WidthRemaining'>Pealkiri</th>
										<th className='Width60'>Aasta</th>
										<th className='Width120'>Autor</th>
										<th className='Width120'>Autorlus</th>
										<th className='Width60'>Sama tüüpi autoreid</th>
										<th className='Width120'>ISBN</th>
										<th className='Width120'>Kujunduse osa</th>
										<th className='Width60'>Õppe- ja teadusk.</th>
									</tr>
								</thead>
								<tbody>
									{this.state.works.map(function(work, i) {

										var Checked = { checked: '' };

										if(this.state.worksAcademicStatus[i].isAcademic === 'Y') {

											Checked['checked'] = 'checked';

										}

										var Disabled = {};

										if(this.state.worksAcademicStatus[i].allowChange === 'N') {

											Disabled['disabled'] = 'disabled';

										}

										var Borders = {};

										Borders['style'] = {'backgroundColor': 'white', 'borderLeft': '1px solid gray', 'borderRight': '1px solid gray'};

										if(work.title != null) {

											Borders['style']['borderTop'] = '1px solid gray';

										}

										return(

											<tr key={i}>
												<td className='WidthRemaining' {...Borders}>
													{work.work_id == null ? null :
														<Link
															className='linkLikeText'
															name="EditWork"
															params={{
																applicationID: this.state.application.appl_id,
																workID: this.state.works[i].work_id
															}}>
															{work.title}
														</Link>}
												</td>
												<td className='Width60' {...Borders}>
													{work.year_of_issue}
												</td>
												<td className='Width120' {...Borders}>
													{work.author_first_name} {work.author_family_name}
												</td>
												<td className='Width120' {...Borders}>
													{work.relation_name}
												</td>
												<td className='Width60' {...Borders}>
													{work.allocation == null ? 1 : work.allocation}
												</td>
												<td className='Width120' {...Borders}>
													{work.work_id == null ? '' : work.isbn}
												</td>
												<td className='Width120' {...Borders}>
													{work.work_id == null ? '' : (work.formation_part_id === 5 ? 'illustratsioone üle poole teosest' : (work.formation_part_id === 4 ? 'illustreeritud teos' : 'illustreerimata/üksikute illustratsioonidega teos'))}
												</td>
												<td className='Width60' {...Borders}>
													{work.work_id == null ? '' :
														<input
															{...Options}
															{...Checked}
															{...Disabled}
															className='Lower'
															type="checkbox"
															onChange={this.changeWorkAcademicStatus.bind(this, i)}>
														</input>}
												</td>
											</tr>

										);

									}, this)}
									<tr style={{'borderTop': '1px solid gray'}}>
										<td colSpan='7'>
											<button
												{...Options}
												className='StandardButton'
												type='button'
												onClick={this.addNewWork}>
												Lisa uus teos
											</button>
										</td>
										<td>
											<button
												{...Options}
												className='StandardButton'
												type='button'
												onClick={this.saveIsAcademic}>
												Salvesta
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

		);

	}

	accept() {

		this.setState({ isLoading: true });

		var data = '{"status_cd":"C"}';

		var URL = this.APIURL + 'applications/' + this.state.application.appl_id;

		axios.put(URL, data, this.config).then(this.back).catch(this.catchError);
		
	}

	addNewWork() {

    let url = this.props.router.url({
			name: 'NewWork',
			params: { applicationID: this.state.application.appl_id}
		});

    this.props.router.navigate({ url });

	}

	back() {

    let url = this.props.router.url({ name: 'Applications' });

    this.props.router.navigate({ url });

	}

	changeWorkAcademicStatus(index) {

		var worksAcademicStatus = this.state.worksAcademicStatus.slice();

		worksAcademicStatus[index].hasChanged = 'Y';

		if(worksAcademicStatus[index].isAcademic === 'Y') {

			worksAcademicStatus[index].isAcademic = 'N';

		}

		else {

			worksAcademicStatus[index].isAcademic = 'Y';

		}

		this.setState({worksAcademicStatus: worksAcademicStatus});

	}

	endApplicationConnection(response) {

		var application = response.data.data;

		var applicationPayee;

		if(application.payee_legal_name == null || application.payee_legal_name === '') {

			applicationPayee = application.payee_first_name + ' ' + application.payee_family_name;

		}

		else {

			applicationPayee = application.payee_legal_name;

		}

		this.setState({
			application: application,
			applicationPayee: applicationPayee
		});

		var URL = this.APIURL + 'parties/' + application.party_id;

		axios.get(URL, this.config).then(this.endPartyConnection).catch(this.catchError);

	}

	endAuthorshipsConnection(response) {

		var works = this.works;

		var authorships = response.data.data;

		var worksAcademicStatus = [];

		var workID;

		var match;

		var hasNoneIllustrator;

		for(let i = 0; i < works.length; i ++) {

			worksAcademicStatus.push({
				workID: works[i].work_id,
				isAcademic: works[i].is_academic,
				hasChanged: 'N',
			});

			workID = works[i].work_id;

			match = false;

			hasNoneIllustrator = false;

			for(let j = 0; j < authorships.length; j ++) {

				if(workID === authorships[j].work_id) {

					if(authorships[j].authorship_cd !== 'I' && authorships[j].authorship_cd !== 'KU') {

						hasNoneIllustrator = true;

					}

					if(match) {

						works.splice(i + 1, 0, {});

						worksAcademicStatus.push({
							hasChanged: 'N',
							allowChange: 'N'
						});

						i ++;

					}

					else {

						match = true;

					}

					works[i].author_first_name = authorships[j].author_first_name;
					works[i].author_family_name = authorships[j].author_family_name;
					works[i].relation_name = authorships[j].authorship_name;
					works[i].allocation = authorships[j].allocation;
					

				}

			}

			if(!match) {

				works[i].author_first_name = '';
				works[i].author_family_name = '';
				works[i].relation_name = '';
				works[i].allocation = '';

			}

			if(works[i].formation_part_id === 1 && !hasNoneIllustrator) {

				worksAcademicStatus[i].allowChange = 'N';

			}

			else {

				worksAcademicStatus[i].allowChange = 'Y';

			}

		}

		this.setState({
			isLoading: false,
			works: works,
			worksAcademicStatus: worksAcademicStatus
		});

	}

	endIsAcademicConnection(response) {

		this.message.push([{
			value: 'Salvestatud.',
			align: 'center'
		}]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	endPartyConnection(response) {

		this.setState({ party: response.data.data });

		var URL = this.APIURL + 'applications/' + this.state.application.appl_id + '/' + this.state.application.party_id + '/works?sort=year_of_issue,a&sort=title,a';

		axios.get(URL, this.config).then(this.endWorksConnection).catch(this.catchError);

	}

	endWorksConnection(response) {

		this.works = response.data.data;

		var URL = this.APIURL
			+ 'applications/' + this.state.application.appl_id
			+ '/' + this.state.party.party_id + '/authorships';

		axios.get(URL, this.config).then(this.endAuthorshipsConnection).catch(this.catchError);

	}

	nullify() {

		this.setState({ isLoading: true });

		var data = '{"status_cd":"X"}';

		var URL = this.APIURL + 'applications/' + this.state.application.appl_id;

		axios.put(URL, data, this.config).then(this.back).catch(this.catchError);
		
	}

	reject() {

		this.setState({ isLoading: true });

		var data = '{"status_cd":"L"}';

		var URL = this.APIURL + 'applications/' + this.state.application.appl_id;

		axios.put(URL, data, this.config).then(this.back).catch(this.catchError);
		
	}

	saveIsAcademic() {

		var data = '';

		for(let i = 0; i < this.state.worksAcademicStatus.length; i ++) {

			if(this.state.worksAcademicStatus[i].hasChanged === 'Y') {

				if(data.length === 0) {

					data =
							'{"works":[{"work_id": ' + this.state.worksAcademicStatus[i].workID + ','
							+ '"is_academic": "' + this.state.worksAcademicStatus[i].isAcademic + '"}';

				}

				else {

					data =
						data
						+ ',{"work_id": ' + this.state.worksAcademicStatus[i].workID  + ','
						+ '"is_academic": "' + this.state.worksAcademicStatus[i].isAcademic + '"}';

				}

			}

		}

		if(data.length !== 0) {

			this.setState({ isLoading: true });

			data = data + ']}';

			var URL =
				this.APIURL
				+ 'applications/'
				+ this.state.application.appl_id
				+ '/'
				+ this.state.party.party_id
				+ '/works';

			axios.put(URL, data, this.config).then(this.endIsAcademicConnection).catch(this.catchError);

		}

		else {

			this.message.push([{
				value: 'Muudatused puuduvad.',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

	}

	setShowMessage(showMessage) {

		if(!showMessage) {

			if(this.navigateBackAfterMessage) {

				this.back();

			}

			else {

				this.setState({showMessage: false});

			}

		}

		else {

			this.setState({showMessage: true});

		}

	}

}