import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
  catchError,
  getCookie
} from './Utils';

export default class EditPayee extends Component {

  constructor(props) {

    super(props);

    this.back = this.back.bind(this);
    this.catchError = catchError.bind(this);
    this.changeTaxpayerResidentsus = this.changeTaxpayerResidentsus.bind(this);
    this.endApplicationConnection = this.endApplicationConnection.bind(this);
    this.EndSubmitConnection = this.EndSubmitConnection.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.setShowMessage = this.setShowMessage.bind(this);
    this.ToggleRahasaaja = this.ToggleRahasaaja.bind(this);
    this.ValidateCount = this.ValidateCount.bind(this);
    this.ValidateFamilyName = this.ValidateFamilyName.bind(this);
    this.ValidateFirstName = this.ValidateFirstName.bind(this);
    this.ValidateIBAN = this.ValidateIBAN.bind(this);
    this.ValidateIndividualCd = this.ValidateIndividualCd.bind(this);
    this.ValidateLegalCd = this.ValidateLegalCd.bind(this);
    this.ValidateLegalName = this.ValidateLegalName.bind(this);
    this.ValidatePayeePersonalCd = this.ValidatePayeePersonalCd.bind(this);
    this.ValidateRatio = this.ValidateRatio.bind(this);
    this.ValidateTaxpayerFamilyName = this.ValidateTaxpayerFamilyName.bind(this);
    this.ValidateTaxpayerFirstName = this.ValidateTaxpayerFirstName.bind(this);
    this.ValidateTaxpayerPersonalCd = this.ValidateTaxpayerPersonalCd.bind(this);
    this.ValidateTaxpayerResidency = this.ValidateTaxpayerResidency.bind(this);

    this.APIURL = require('./../config/config.js').APIURL;
    this.config = {
      headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
      timeout: 30000,
      auth: {username: getCookie('AHFToken')},
    };
    this.message = [];
    this.state = {
      application: {},
      isLoading: true,
      Rahasaaja: 'fuusiline_isik',
      showMessage: false,
      TaxpayerResidentsus: ''
    };

  }

  componentDidMount() {

    var URL = this.APIURL + 'applications/' + this.props.response.params.applicationID;

    axios.get(URL, this.config).then(this.endApplicationConnection).catch(this.catchError);

  }

  render() {

    document.body.style.backgroundColor = '#BA491C';

    var Options = {};

    if(this.state.isLoading) {

      Options['disabled'] = 'disabled';

    }

    return(

      <div className='mediumBoxWrapper'>
        <LoadingOverlay isLoading={this.state.isLoading}/>
        <ShowMessage
          showMessage={this.state.showMessage}
          message={this.message}
          setShowMessage={this.setShowMessage}
        />
        <div className='mediumBox OuterBox'>
          <table className='MaxWidth'>
            <tbody>
              <tr>
                <td className='Logo'>
                  <img src='/img/logo.gif' alt=''></img>
                </td>
                <td className='Center'>
                  <h2>Rahasaaja andmete muutmine</h2>
                  <h2>avaldusel nr. {this.props.response.params.applicationID}</h2>
                </td>
                <td className='TopButtons'>
                  <button {...Options} type='button' className='StandardButton' id='ButtonWidth' onClick={this.back}>Tagasi</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='InnerBox'>
            <form onSubmit={this.HandleSubmit}>
              <table className='MaxWidth'>
                <tbody>
                  <tr>
                    <td className='RequiredField'>
                      Rahasaaja tüüp:
                    </td>
                    <td>
                      <select name='payee' {...Options} value={this.state.Rahasaaja} onChange={this.ToggleRahasaaja}>
                        <option value='fuusiline_isik'>Füüsiline isik</option>
                        <option value='fie'>FIE</option>
                        <option value='jur_isik'>Jur. isik</option>
                      </select>
                    </td>
                  </tr>
                  {this.state.Rahasaaja === 'jur_isik' ? null :
                    <tr>
                      <td className='RequiredField' id='HalfWidth'>
                        Eesnimi (või -nimed):
                      </td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='Eesnimed'
                          defaultValue={this.state.application.payee_first_name}
                          maxLength='50'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja === 'jur_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Perekonnanimi:</td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='Perenimi'
                          defaultValue={this.state.application.payee_family_name}
                          maxLength='50'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja === 'jur_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Isikukood:
                      </td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='12'
                          name='PayeeIsikukood'
                          defaultValue={this.state.application.payee_personal_cd}
                          maxLength='20'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja !== 'fie' ? null :
                    <tr>
                      <td className='RequiredField'>
                        FIE reg. nr.:
                      </td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='12'
                          name='FIERegNr'
                          defaultValue={this.state.application.payee_individual_cd}
                          maxLength='8'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja !== 'jur_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Jur. isiku nimi:
                      </td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='Nimi'
                          defaultValue={this.state.application.payee_legal_name}
                          maxLength='50'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja !== 'jur_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Jur. isiku reg. nr.:
                      </td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='12'
                          name='JurIsikuRegNr'
                          defaultValue={this.state.application.payee_legal_cd}
                          maxLength='11'>
                        </input>
                      </td>
                    </tr>}
                  <tr>
                    <td className='RequiredField'>
                      IBAN:
                    </td>
                    <td>
                      <input
                        {...Options} 
                        type='text'
                        size='25'
                        name='IBAN'
                        maxLength='50'
                        defaultValue={this.state.application.iban}>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br></br>
                    </td>
                  </tr>
                  <tr>
                    <td className='RequiredField'>
                      Protsent hüvitisest:</td>
                    <td>
                      <input
                        {...Options}
                        type='text'
                        size='6'
                        name='Protsent'
                        defaultValue={this.state.application.author_rel_ratio}
                        maxLength='7'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='NormalField'>
                      Rahasaajaid taotluses:</td>
                    <td>
                      <input
                        {...Options}
                        type='text'
                        size='6'
                        name='Rahasaajaid'
                        defaultValue={this.state.application.payee_cnt}
                        maxLength='1'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br></br>
                    </td>
                  </tr>
                  {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Maksumaksja eesnimi (või -nimed):
                      </td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='MaksumaksjaEesnimed'
                          defaultValue={this.state.application.taxpayer_first_name}
                          maxLength='50'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Maksumaksja perenimi:
                      </td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='MaksumaksjaPerenimi'
                          defaultValue={this.state.application.taxpayer_family_name}
                          maxLength='50'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Maksumaksja residentsus:
                      </td>
                      <td>
                        <select {...Options} name='TaxpayerResidentsus' value={this.state.TaxpayerResidentsus} onChange={this.changeTaxpayerResidentsus}>
                          {this.state.application.taxpayer_resident_cd == null ?
                          <option value='NULL'>Vali resident</option> : null}
                          <option value='EE'>EE</option>
                          <option value='Mu'>Muu</option>
                        </select>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                    <tr>
                      <td className='RequiredField'>
                        Maksumaksja isikukood:</td>
                      <td>
                        <input
                          {...Options}
                          type='text'
                          size='12'
                          name='TaxpayerIsikukood'
                          defaultValue={this.state.application.taxpayer_personal_cd}
                          maxLength='20'>
                        </input>
                      </td>
                    </tr>}
                  {this.state.Rahasaaja !== 'fuusiline_isik' ? null :
                    <tr>
                      <td>
                        <br></br>
                      </td>
                    </tr>}
                  <tr>
                    <td className='NormalField' style={{'verticalAlign' : 'text-top'}}>
                      Kommentaar:
                    </td>
                    <td>
                      <textarea
                        {...Options}
                        id='Comment'
                        name='Kommentaar'
                        maxLength='500'>
                      </textarea>
                    </td>
                  </tr>
                  <tr>
                    <td className='Center' colSpan='2'>
                      <button {...Options} className='StandardButton' type='submit'>Salvesta muudatused</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>

    );

  }

  back() {

    let url = this.props.router.url({
      name: 'EditApplication',
      params: { applicationID: this.props.response.params.applicationID }
    });

    this.props.router.navigate({ url });

  }

  changeTaxpayerResidentsus(event) {

    this.setState({ TaxpayerResidentsus: event.target.value || ''});

  }

  endApplicationConnection(response) {

    var application = response.data.data;

    var Rahasaaja;

    if(application.payee_legal_cd != null) {

      Rahasaaja = 'jur_isik';

    }

    else if(application.payee_individual_cd != null) {

      Rahasaaja = 'fie';

    }

    else {

      Rahasaaja = 'fuusiline_isik';

    }

    this.setState({
      application: application,
      isLoading: false,
      Rahasaaja: Rahasaaja,
      TaxpayerResidentsus: (application.taxpayer_resident_cd == null ? 'NULL' : (application.taxpayer_resident_cd === 'EE' ? 'EE' : 'Mu'))
    });

  }

  EndSubmitConnection(Response) {

    this.message.push([{
      value: 'Salvestatud.',
      align: 'center'
    }]);

    this.setState({
      isLoading: false,
      showMessage: true
    });

  }

  HandleSubmit(event) {

    event.preventDefault();

    var Eesnimed = '';
    var Perenimi = '';
    var PayeeIsikukood = '';
    var FIERegNr = '';
    var Nimi = '';
    var JurIsikuRegNr = '';
    var MaksumaksjaEesnimed = '';
    var MaksumaksjaPerenimi = '';
    var TaxpayerIsikukood = '';
    var TaxpayerResidentsus = '';

    if(event.target.Eesnimed != null) {

      Eesnimed = event.target.Eesnimed.value;

    }

    if(event.target.Perenimi != null) {

      Perenimi = event.target.Perenimi.value;

    }

    if(event.target.PayeeIsikukood != null) {

      PayeeIsikukood = event.target.PayeeIsikukood.value;

    }

    if(event.target.FIERegNr != null) {

      FIERegNr = event.target.FIERegNr.value;

    }

    if(event.target.Nimi != null) {

      Nimi = event.target.Nimi.value;

    }

    if(event.target.JurIsikuRegNr != null) {

      JurIsikuRegNr = event.target.JurIsikuRegNr.value;

    }

    if(event.target.MaksumaksjaEesnimed != null) {

      MaksumaksjaEesnimed = event.target.MaksumaksjaEesnimed.value;

    }

    if(event.target.MaksumaksjaPerenimi != null) {

      MaksumaksjaPerenimi = event.target.MaksumaksjaPerenimi.value;

    }

    if(event.target.TaxpayerIsikukood != null) {

      TaxpayerIsikukood = event.target.TaxpayerIsikukood.value;

    }

    if(event.target.TaxpayerResidentsus != null) {

      TaxpayerResidentsus = event.target.TaxpayerResidentsus.value;

    }

    var errorMessage = [];

    var message = this.ValidateFirstName(Eesnimed);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateFamilyName(Perenimi);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidatePayeePersonalCd(PayeeIsikukood);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateIndividualCd(FIERegNr);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateLegalName(Nimi);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateLegalCd(JurIsikuRegNr);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateIBAN(event.target.IBAN.value, event.target.Rahasaajaid.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateRatio(event.target.Protsent.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateCount(event.target.Rahasaajaid.value);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateTaxpayerFirstName(MaksumaksjaEesnimed);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    message = this.ValidateTaxpayerFamilyName(MaksumaksjaPerenimi);

    if(message !== '') {

      errorMessage.push({value: message, align: 'center'});

    }

    if(event.target.TaxpayerResidentsus != null) {

      message = this.ValidateTaxpayerPersonalCd(TaxpayerIsikukood, event.target.TaxpayerResidentsus.value);

      if(message !== '') {

        errorMessage.push({value: message, align: 'center'});

      }

      message = this.ValidateTaxpayerResidency(TaxpayerResidentsus);

      if(message !== '') {

        errorMessage.push({value: message, align: 'center'});

      }

    }

    if(errorMessage.length === 0) {

      this.setState({ isLoading: true });

      var data =
        '{"payee_first_name": "' + Eesnimed.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_family_name": "' + Perenimi.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_personal_cd": "' + PayeeIsikukood.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_individual_cd": "' + FIERegNr.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_legal_name": "' + Nimi.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"payee_legal_cd": "' + JurIsikuRegNr.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"iban": "' + event.target.IBAN.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"author_rel_ratio": ' + event.target.Protsent.value.replace(/,/g, '.').replace(/\\/g, '\\\\').replace(/"/g, '\\"') + ','
        + '"payee_cnt": ' + (event.target.Rahasaajaid.value.length > 0 ? event.target.Rahasaajaid.value : 0) + ','
        + '"taxpayer_first_name": "' + MaksumaksjaEesnimed.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"taxpayer_family_name": "' + MaksumaksjaPerenimi.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"taxpayer_personal_cd": "' + TaxpayerIsikukood.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"taxpayer_resident_cd": "' + TaxpayerResidentsus.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

      var URL = this.APIURL + 'applications/' + this.state.application.appl_id;

      axios.put(URL, data, this.config).then(this.EndSubmitConnection).catch(this.catchError);

    }

    else {

      this.message.push(errorMessage);

      this.setState({ showMessage: true });

    }

  }

  setShowMessage(showMessage) {

    if(!showMessage) {

      if(this.navigateBackAfterMessage) {

        let url = this.props.router.url({ name: 'Applications' });

        this.props.router.navigate({ url });

      }

      else {

        this.setState({showMessage: false});

      }

    }

    else {

      this.setState({showMessage: true});

    }

  }

  ToggleRahasaaja(event) {

    this.setState({Rahasaaja: event.target.value});

  }

  ValidateCount(Value) {

    if(Value.search(/[^0-9]/) !== -1) {

      return('Rahasaajaid taotluses formaat on vale!');

    }

    return('');

  }

  ValidateFamilyName(Value) {

    if(this.state.Rahasaaja !== 'jur_isik' && Value.length === 0) {

      return('Perenimi peab olema täidetud!');

    }

    return('');

  }

  ValidateFirstName(Value) {

    if(this.state.Rahasaaja !== 'jur_isik' && Value.length === 0) {

      return('Eesnimi peab olema täidetud!');

    }

    return('');

  }

  ValidateIBAN(Value, Rahasaajaid) {

    var IBAN = Value.replace(/\s+/g, '');

    if(Rahasaajaid === 0) {

      return('');

    }

    if(IBAN.length === 0) {

      return('IBAN peab olema täidetud!');

    }

    if(IBAN.length > 34 || IBAN.length < 10 ||IBAN.search(/[^a-zA-Z0-9\s]/) !== -1) {

      return('IBAN formaat on vale!');

    }

    IBAN = IBAN.substring(4, IBAN.length).concat(IBAN.substring(0, 4)).toUpperCase();

    for(let i = 0; i < IBAN.length; i ++) {

      if(isNaN(IBAN[i])) {

        IBAN = IBAN.replace(IBAN[i], IBAN[i].charCodeAt(0) - 55);

      }

    }

    var checksum = IBAN.substring(0, 2);

    for (let i = 2; i < IBAN.length; i += 7) {

      var fragment = String(checksum) + IBAN.substring(i, i + 7);

      checksum = parseInt(fragment) % 97;

    }

    if(checksum !== 1) {

      return('IBAN formaat on vale!');

    }

    return('');

  }

  ValidateIndividualCd(Value) {

    if(this.state.Rahasaaja === 'fie' && Value.length === 0) {

      return('FIE reg. nr. peab olema täidetud!');

    }

    if(this.state.Rahasaaja === 'fie' && Value.length > 0 && (Value.search(/[^0-9]/) !== -1 || Value.length !== 8)) {

      return('FIE reg. nr. formaat on vale!');

    }

    return('');

  }

  ValidateLegalCd(Value) {

    if(this.state.Rahasaaja === 'jur_isik' && Value.length === 0) {

      return('Jur. isiku reg. nr. peab olema täidetud!');

    }

    return('');

  }

  ValidateLegalName(Value) {

    if(this.state.Rahasaaja === 'jur_isik' && Value.length === 0) {

      return('Jur. isiku nimi peab olema täidetud!');

    }

    return('');

  }

  ValidatePayeePersonalCd(Value) {

    if(this.state.Rahasaaja !== 'jur_isik') {

      if(Value.length === 0) {

        return('Rahasaaja isikukood peab olema täidetud!');

      }

    }

    return('');

  }

  ValidateRatio(Value) {

    Value = Value.replace(/,/g, '.');

    if(Value.length === 0) {

      return('Protsent hüvitisest peab olema täidetud!');

    }

    if(isNaN(Value) || Number(Value) > 100 || Number(Value) <= 0) {

      return('Protsent hüvitisest formaat on vale!');

    }

    return('');

  }

  ValidateTaxpayerFamilyName(Value) {

    if(this.state.Rahasaaja === 'fuusiline_isik' && Value.length === 0) {

      return('Maksumaksja perenimi peab olema täidetud!');

    }

    return('');

  }

  ValidateTaxpayerFirstName(Value) {

    if(this.state.Rahasaaja === 'fuusiline_isik' && Value.length === 0) {

      return('Maksumaksja eesnimi peab olema täidetud!');

    }

    return('');

  }

  ValidateTaxpayerPersonalCd(Value, TaxpayerResidentsus) {

    if(this.state.Rahasaaja === 'fuusiline_isik') {

      if(Value.length === 0) {

        return('Maksumaksja isikukood peab olema täidetud!');

      }

      if(TaxpayerResidentsus === 'EE' && (Value.search(/[^0-9]/) !== -1 || Value.length !== 11)) {

        return('Maksumaksja isikukoodi formaat on vale!');

      }

    }

    return('');

  }

  ValidateTaxpayerResidency(Value) {

    if(this.state.Rahasaaja === 'fuusiline_isik' && Value === 'NULL') {

      return('Vali maksumaksja residentsus!');

    }

    return('');

  }

}