import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	getCookie,
	getErrorMessage,
	setCookie
} from './Utils';

export default class MemberApplications extends Component {

	constructor(props) {

		super(props);

		this.catchError = this.catchError.bind(this);
		this.changeApplicantRoleCode = this.changeApplicantRoleCode.bind(this);
		this.endApplicationsConnection = this.endApplicationsConnection.bind(this);
		this.endNewApplicationConnection = this.endNewApplicationConnection.bind(this);
		this.endPartyConnection = this.endPartyConnection.bind(this);
		this.loadApplications = this.loadApplications.bind(this);
		this.logOut = this.logOut.bind(this);
		this.newApplication = this.newApplication.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);
		this.validateAutoriEesnimed = this.validateAutoriEesnimed.bind(this);
		this.validateAutoriPerenimi = this.validateAutoriPerenimi.bind(this)
		this.validateBothNames = this.validateBothNames.bind(this);
		this.validateKellenaTaotlusEsitatakse = this.validateKellenaTaotlusEsitatakse.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			applications: [],
			applicantRoleCode: 'NULL',
			isLoading: true,
			party: {},
			showMessage: false
		};

	}

	componentDidMount() {

		var URL = this.APIURL + 'parties/' + getCookie('AHFUser');

		axios.get(URL, this.config).then(this.endPartyConnection).catch(this.catchError);

	}

	render() {

		document.body.style.backgroundColor = '#E7D074';

		return(

			<div className='mediumBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div className='mediumBox OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='MaxWidth'></td>
								<td className='TopButtons'>
									<button
										type='button'
										className='StandardButton'
										id='ButtonWidth'
										onClick={this.logOut}>
										Logi välja
									</button>
								</td>
							</tr>
							<tr>
								<td colSpan='3' className='Center'>
									Tere tulemast!
								</td>
							</tr>
							<tr>
								<td colSpan='3' className='Center'>
									<p></p>
									<Link
										className='linkLikeText'
										name="EditApplicant"
										params={{ partyID: getCookie('AHFUser') }}>
										{this.state.party.applicant_first_name} {this.state.party.applicant_family_name}
									</Link> ({this.state.party.email})
									<p></p>
									{this.state.party.legal_name}
									<p></p>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='Header'>
						<table className='MaxWidth'>
							<tbody>
								<tr>
									<td>
										<ul>
											<li>Esitaja andmete muutmiseks klõpsake oma nimele ülal.</li>
											<li>Avalduse täiendamiseks ja muutmiseks klõpsake taotluse numbrile Pooleli olekus vormil, selle puudumisel Valmis olekus vormil.</li>
											<li>Kehtivas olekus avaldus kuvatakse teadmiseks. Seda ei saa muuta.</li>
                      <li>Oma teoste laenutuste arvu nägemiseks klõpsake kehtiva avalduse numbrile.</li>
											<li>Vastused korduma kippuvatele küsimustele leiate <a href="https://ahf.ee/kkk/" target="_blank" rel="noopener noreferrer">
												siit</a>.</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p></p>
					<div className='InnerBox'>
						<table id='ApplicationsTable'>
							<thead>
								<tr>
									<th className='Width60'>Taotlus</th>
									<th className='WidthRemaining'>Autor</th>
									<th className='Width80'>Lõpetamise aeg</th>
									<th className='Width80'>Olek</th>
									<th className='Width60'>Roll</th>
								</tr>
							</thead>
							<tbody>
								{this.state.applications.map(function(application, index) {

									var Status;

									var Options = {};

									if(application.status_cd === 'E') {

										Status = 'Valmis'

										Options['id'] = 'Applied';

									}

									else if(application.status_cd === 'L' || application.status_cd === 'K') {

										Status = 'Pooleli';

										Options['id'] = 'Unfinished';

									}

									else {

										Status = 'Kehtiv';

										Options['id'] = 'Accepted';

									}

									var applicant_role = '';

									if(application.applicant_role_cd === 'I') {

										applicant_role = 'Isiklikult';

									}

									else if(application.applicant_role_cd === 'E') {

										applicant_role = 'Esindaja';

									}

									else if(application.applicant_role_cd === 'P') {

										applicant_role = 'Pärija';

									}

									return(

										<tr key={index}>
											<td {...Options} style={{paddingTop: '5px', paddingBottom: '5px'}} className='Width60'>
											{application.status_cd === 'C' ?
												<Link
													className='linkLikeText'
													name="ApplicationView"
													params={{ applicationID: application.appl_id }}>
													{application.appl_id}
												</Link> :
												<Link
													className='linkLikeText'
													name="EditApplication"
													params={{ applicationID: application.appl_id }}>
													{application.appl_id}
												</Link>}
											</td>
											<td {...Options} className='WidthRemaining'>{application.author_first_name} {application.author_family_name}</td>
											<td {...Options} className='Width80'>{(Status === 'Valmis' || Status === 'Kehtiv') && application.change_dtime != null ? application.change_dtime.substring(0, 4) : ''}</td>
											<td {...Options} className='Width80'>{Status}</td>
											<td {...Options} className='Width60'>{applicant_role}</td>
										</tr>

									);

								}, this)}
							</tbody>
						</table>
					</div>
					<p></p>
					<form onSubmit={this.newApplication}>
						<table style={{margin: 'auto'}}>
							<tbody>
								<tr>
									<td id='Heading' colSpan='2'>
										Uue taotluse loomine
										<p></p>
									</td>
								</tr>
								<tr>
									<td className='RequiredField' id='HalfWidth'>
										Kellena taotlus esitatakse:
									</td>
									<td>
										<select onChange={this.changeApplicantRoleCode}>
											<option value='NULL'>Vali esitaja</option>
											{
												this.state.party.legal_name == null
												? <option value='I'>Isiklikult</option>
												: null}
											<option value='E'>Esindajana</option>
											<option value='P'>Pärijana</option>
										</select>
									</td>
								</tr>
								{this.state.applicantRoleCode === 'E' || this.state.applicantRoleCode === 'P' ?
									<tr>
										<td className='RequiredField'>
											Autori eesnimi (või -nimed):
										</td>
										<td>
											<input
												type='text'
												size='27'
												name='AutoriEesnimed'
												defaultValue={this.state.party.author_first_name}
												maxLength='50'>
											</input>
										</td>
									</tr>
									: null}
								{this.state.applicantRoleCode === 'E' || this.state.applicantRoleCode === 'P' ?
									<tr>
										<td className='RequiredField'>
											Autori perekonnanimi:
										</td>
										<td>
											<input
												type='text'
												size='27'
												name='AutoriPerenimi'
												defaultValue={this.state.party.author_family_name}
												maxLength='50'>
											</input>
										</td>
									</tr>
									: null}
								<tr>
									<td colSpan='2' className='Center'>
										<button type='submit' className='StandardButton'>Loo uus</button>
									</td>
								</tr>
							</tbody>
						</table>
					</form>
				</div>
			</div>

		);

	}

	catchError(error) {

		this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	changeApplicantRoleCode(event) {

		this.setState({applicantRoleCode: event.target.value});

	}

  /*
    AHF tavakasutaja avalduste filtreerimine:

    0.
      Juhul, kui avalduse viimane muutmise kuupäev on puudu, siis kasutatakse selle asemel loomise kuupäeva.
      Avaldused jaotatakse piltlikult grupidesse, kus on sama applicant_role_cd ja author_party_id.
      Olekute (status_cd) tähendused:
        C - kinnitatud, roheline;
        E - valmis, valge;
        K - kloonitud, hall;
        L - poolik, hall.
      Avaldused käiakse läbi ja eemaldatakse APIlt saadud kindlas järjekorras.

    1.
      Eemaldatakse avaldused, mille viimase muutmise kuupäeva aasta on väiksem, kui käimasolev - 2 aastat.

    2.
      Eemaldatakse avaldused, millel on samas grupis ja samas olekus API järjestuses varasem eemaldamata avaldus.
      Avaldused arvestatakse samasse olekusse ka siis, kui mõlema avalduse status_cd kuulub hulka {E, K, L}.

    3.
      Eemaldatakse avaldused, millel on samas grupis ja samas olekus API järjestuses hilisem avaldus.
      Eemaldamiseks peab API järjestuses hilisema avalduse muutmise aasta olema suurem, kui API järjestuses varasema avalduse muutmise aasta.
      Avaldused arvestatakse samasse olekusse ka siis, kui mõlema avalduse status_cd kuulub hulka {E, K, L} või {C, E}.
  */
	endApplicationsConnection(response) {

    let today = new Date();

		var applications = response.data.data;

		var removeFromList = false;

		var filteredApplications = [];

		for(let i = 0; i < applications.length; i ++) {

			removeFromList = false;

      //Märgitakse eemaldamisele need avaldused, mis on muudetud (muutmise kuupäeva puudumisel loodud) rohkem, kui 2 täisaastat tagasi.
      if(Number((applications[i].change_dtime == null
        ? applications[i].create_dtime
        : applications[i].change_dtime).substring(0, 4)) < today.getFullYear() - 2) {

        removeFromList = true;

      }

      if(!removeFromList) {

        for(let j = 0; j < filteredApplications.length; j ++) {

          if(applications[i].applicant_role_cd === filteredApplications[j].applicant_role_cd
            && applications[i].author_party_id === filteredApplications[j].author_party_id
            &&
              (applications[i].status_cd === filteredApplications[j].status_cd
              ||
                  ((applications[i].status_cd === 'E'
                  || applications[i].status_cd === 'K'
                  || applications[i].status_cd === 'L')
                &&
                  (filteredApplications[j].status_cd === 'E'
                  || filteredApplications[j].status_cd === 'K'
                  || filteredApplications[j].status_cd === 'L')))) {

            removeFromList = true;

            break;

          }

        }

      }

      if(!removeFromList) {

        for(let j = i + 1; j < applications.length; j ++) {

          if(applications[i].applicant_role_cd === applications[j].applicant_role_cd
            && applications[i].author_party_id === applications[j].applicant_role_cd
            && Number((applications[i].change_dtime == null
              ? applications[i].create_dtime
              : applications[i].change_dtime).substring(0, 4))
              < Number((applications[j].change_dtime == null
                ? applications[j].create_dtime
                : applications[j].change_dtime).substring(0, 4))
            &&
              (applications[i].status_cd === applications[j].status_cd
              ||
                  ((applications[i].status_cd === 'C'
                  || applications[i].status_cd === 'E')
                &&
                  (applications[j].status_cd === 'C'
                  || applications[j].status_cd === 'E'))
              ||
                  ((applications[i].status_cd === 'E'
                  || applications[i].status_cd === 'K'
                  || applications[i].status_cd === 'L')
                &&
                  (applications[j].status_cd === 'E'
                  || applications[j].status_cd === 'K'
                  || applications[j].status_cd === 'L')))) {

            removeFromList = true;

            break;

          }

        }

      }

      else {

        removeFromList = true;

      }

			if(!removeFromList) {

				filteredApplications.push(applications[i]);

			}

		}

		this.setState({
			applications: filteredApplications,
			isLoading: false
		});

	}

	endNewApplicationConnection(response) {

		this.message.push([{
			value: 'Loodi avaldus: ' + response.data.data.appl_id,
			align: 'center'
		}]);

		this.setState({ showMessage: true });

		this.loadApplications(this.state.party.party_id);

	}

	endPartyConnection(response) {

		this.setState({ party: response.data.data});

		this.loadApplications(response.data.data.party_id);

	}

	loadApplications(partyID) {

		var URL = this.APIURL + 'applications?filtered=false&party_id=' + partyID;

		axios.get(URL, this.config).then(this.endApplicationsConnection).catch(this.catchError);

	}

	logOut() {

		setCookie('AHFLevel', getCookie('AHFLevel'), -1);
		setCookie('AHFToken', getCookie('AHFToken'), -1);
		setCookie('AHFUser', getCookie('AHFToken'), -1);

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

	}

	newApplication(event) {

		event.preventDefault();

		var errorMessage = [];

    let AutoriEesnimed = event.target.AutoriEesnimed != null ? event.target.AutoriEesnimed.value : null;

    let AutoriPerenimi = event.target.AutoriPerenimi != null ? event.target.AutoriPerenimi.value : null;

		var message = this.validateKellenaTaotlusEsitatakse(this.state.applicantRoleCode, AutoriEesnimed, AutoriPerenimi);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.validateAutoriEesnimed(AutoriEesnimed);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.validateAutoriPerenimi(AutoriPerenimi);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.validateBothNames(AutoriEesnimed, AutoriPerenimi);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		if(errorMessage.length === 0) {

			this.setState({ isLoading: true });

			var eesnimed;

			var perenimi;

			if(this.state.applicantRoleCode === 'I') {

				eesnimed = this.state.party.applicant_first_name;

				perenimi = this.state.party.applicant_family_name;

			}

			else {

				eesnimed = AutoriEesnimed;

				perenimi = AutoriPerenimi;

			}

			var URL = this.APIURL + 'applications';

			var data =
				'{"version_id": "1",'
				+ '"party_id": "' + this.state.party.party_id + '",'
				+ '"author_first_name": "' + eesnimed.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"author_family_name": "' + perenimi.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"applicant_role_cd": "' + this.state.applicantRoleCode + '"}';

			axios.post(URL, data, this.config).then(this.endNewApplicationConnection).catch(this.catchError);

		}

		else {

			this.message.push(errorMessage);

			this.setState({ showMessage: true });

		}

	}

	setShowMessage(showMessage) {

		this.setState({showMessage: showMessage});

	}

	validateAutoriEesnimed(AutoriEesnimed) {

		if((this.state.applicantRoleCode === 'E' || this.state.applicantRoleCode === 'P') && AutoriEesnimed.length === 0) {

			return('Autori eesnimi peab olema täidetud!');

		}

		return('');

	}

	validateAutoriPerenimi(AutoriPerenimi) {

		if((this.state.applicantRoleCode === 'E' || this.state.applicantRoleCode === 'P') && AutoriPerenimi.length === 0) {

			return('Autori perenimi peab olema täidetud!');

		}

		return('');

	}

	validateBothNames(AutoriEesnimed, AutoriPerenimi) {

		if(this.state.party.legal_cd == null
				&& (this.state.applicantRoleCode === 'E' || this.state.applicantRoleCode === 'P')
				&& AutoriEesnimed.toUpperCase() === this.state.party.applicant_first_name.toUpperCase()
				&& AutoriPerenimi.toUpperCase() === this.state.party.applicant_family_name.toUpperCase()) {

			return('Autori nimed kattuvad esitaja nimedega! Vali esitajaks "Isiklikult"!');

		}

		return('');

	}

	validateKellenaTaotlusEsitatakse(Value, AutoriEesnimed, AutoriPerenimi) {

		if(Value === 'NULL') {

			return('Vali kellena taotlus esitatakse!');

		}

		for(var i = 0; i < this.state.applications.length; i ++) {

			if((this.state.applicantRoleCode === 'I' && this.state.applications[i].applicant_role_cd === 'I') ||
				(this.state.applicantRoleCode !== 'I'
				&& this.state.applicantRoleCode === this.state.applications[i].applicant_role_cd
				&& this.state.applications[i].author_first_name.toUpperCase() === AutoriEesnimed.toUpperCase()
				&& this.state.applications[i].author_family_name.toUpperCase() === AutoriPerenimi.toUpperCase())) {

				return('Selline avaldus juba eksisteerib! Muuda olemasolevat!');

			}

		}

		return('');

	}

}