import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	getErrorMessage,
	validateEMail
} from './Utils';

export default class Recover extends Component {

	constructor(props) {

		super(props);

		this.catchError = this.catchError.bind(this);
		this.endRecoverConnection = this.endRecoverConnection.bind(this);
		this.handleRecover = this.handleRecover.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.message = [];
		this.navigateToLogin = false;
		this.state = {
			isLoading: false,
			showMessage: false
		};

	}

	render() {

		document.body.style.backgroundColor = '#E7D074';

		return(

			<div className='smallBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div className='smallBox OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Parooli taastamine</h2>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
						<form onSubmit={this.handleRecover.bind(this)}>
							<table className='MaxWidth'>
								<tbody>
									<tr>
										<td className='NormalLabel'>
											Isikukood:
										</td>
										<td>
											<input
												className='MaxWidth'
												type='text'
												name='Isikukood'
												maxLength='20'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='NormalLabel'>
											E-posti aadress:
										</td>
										<td>
											<input
												className='MaxWidth'
												type='text'
												name='EPost'
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td colSpan='2' className='Center'>
											<button className='StandardButton' type='submit'>Taasta parool</button>
										</td>
									</tr>
								</tbody>
							</table>
						</form>
					</div>
				</div>
			</div>
		);

	}

	catchError(error) {

		this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	endRecoverConnection(Response, EPost) {

		this.message.push([{
			value: 'Uue parooli loomiseks saadeti link e-posti aadressile: ' + EPost,
			align: 'center'
		}]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

		this.navigateToLogin = true;

	}

	handleRecover(event) {

		event.preventDefault();

		var errorMessage = [];

		if(event.target.Isikukood.value.length === 0) {

			errorMessage.push({value: 'Isikukood peab olema täidetud!', align: 'center'});

		}

		var message = validateEMail(event.target.EPost.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		if(errorMessage.length === 0) {

			this.setState({ isLoading: true });

			var data =
				'{"username": "' + event.target.Isikukood.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"email": "' + event.target.EPost.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

			var URL = this.APIURL + 'login/passwordreset';

			var config = {

				headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
				timeout: 30000,

			};

			axios.post(URL, data, config).then((response) => this.endRecoverConnection(response, event.target.EPost.value)).catch(this.catchError);
		}

		else {

			this.message.push(errorMessage);

			this.setState({ showMessage: true });

		}

	}

	setShowMessage(showMessage) {

		if(this.navigateToLogin) {

      let url = this.props.router.url({ name: 'Login' });

      this.props.router.navigate({ url });

		}

		else {

			this.setState({showMessage: showMessage});

		}

	}

}