import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	getCookie,
	getErrorMessage,
	setCookie
} from './Utils';

export default class UploadFile extends Component {

	constructor(props) {

		super(props);

		this.catchError = this.catchError.bind(this);
		this.EndConnection = this.EndConnection.bind(this);
		this.logOut = this.logOut.bind(this);
		this.SelectFile = this.SelectFile.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);
		this.Upload = this.Upload.bind(this);
		this.ValidateFileType = this.ValidateFileType.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.message = [];
		this.state = {
			isLoading: false,
			showMessage: false,
			FileSelected: false,
			Progress: 0,
      VisibleInput: ''
		};

	}

	render() {

		document.body.style.backgroundColor = '#BA491C';

		var Options = {};

    if(this.state.isLoading) {

        Options['disabled'] = 'disabled';

    }

		var borderRadius = '0px';

		if(this.state.Progress === 100) {

			borderRadius = '7px'

		}

		return(

			<div className='largeBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='Tabs'>
					<Link
						className='unselectedTab'
						name="Applications">
						Laekunud avaldused
					</Link>
					<Link
						className='unselectedTab'
						name="AdministrationTool">
						Administreerimine
					</Link>
					<Link
						className='unselectedTab'
						name="Archive">
						Arhiiv
					</Link>
					<Link
						className='unselectedTab'
						name="Reports">
						Raportid
					</Link>
					<div id='SelectedTab'>Failide üleslaadimine<p></p></div>
				</div>
				<div id='AdminApplicationsBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Raamatukogu failide üleslaadimine</h2>
								</td>
								<td className='TopButtons'>
									<button {...Options} type='button' className='StandardButton' onClick={this.logOut}>Logi välja</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
						<div id='Upload'>
              <form onSubmit={this.Upload}>
                <div>
                  <select {...Options} name='FilType'>
                    <option value='NULL'>Vali tüüp</option>
                    <option value='tartu'>Tartu INNOPAC</option>
                    <option value='kesk'>Urram keskserver</option>
                    <option value='polva'>Põlva keskraamatukogu</option>
                  </select>
                  <span id='UploadBox'>
                    <input
                      {...Options}
                      type='file'
                      name='File'
                      id='UploadInput'
                      onChange={this.SelectFile.bind(this)}>
                    </input>
                    <div id='UploadCover'>
                      <input value={this.state.VisibleInput} readOnly></input>
                      <button id='UploadButton'>Vali fail</button>
                    </div>
                  </span>
                  {this.state.FileSelected ? <button {...Options} type='submit' className='StandardButton'>Lae üles</button> : null}
                  {this.state.FileSelected ?
                    <div>
                      <div id='ProgressBar'>
                        <div id='Progress' style={{width: this.state.Progress + '%', borderTopRightRadius: borderRadius, borderBottomRightRadius: borderRadius}}></div>
                      </div>
                      {this.state.Progress}%
                    </div>
                    : null}
                </div>
              </form>
						</div>
					</div>
				</div>
			</div>

		);

	}

	catchError(error) {

		this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

		this.setState({
			isLoading: false,
			progress: 0,
			showMessage: true
		});

	}

	EndConnection(Response) {

		this.message.push([
			{
				value: 'Laetud!',
				align: 'center'
			}
		]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	logOut() {

		setCookie('AHFLevel', getCookie('AHFLevel'), -1);
		setCookie('AHFToken', getCookie('AHFToken'), -1);
		setCookie('AHFUser', getCookie('AHFToken'), -1);

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

	}

	SelectFile(event) {

    let value = event.target.value;

    let i = value.lastIndexOf('\\') + 1;

    if(i !== -1) {

      value = value.substring(i, value.length);

    }

		if(value != null) {

			this.setState({
        FileSelected: true,
        Progress: 0,
        VisibleInput: value
      });

		}

		else {

			this.setState({
        FileSelected: false,
        Progress: 0,
        VisibleInput: ''
      });

		}

	}

	setShowMessage(showMessage) {

		this.setState({showMessage: showMessage});

	}

	Upload(event) {

    event.preventDefault();

		var errorMessage = [];

		var message = this.ValidateFileType(event.target.FilType.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		if(errorMessage.length === 0) {

			this.setState({ isLoading: true });

			var URL = this.APIURL + 'upload/' + event.target.FilType.value;

			var data = new FormData();

			data.append('file', event.target.File.files[0].name);

			data.append('file', event.target.File.files[0]);

			var config = {
				headers: {'Content-Type': 'application/binary', 'Pragma': 'no-cache'},
				timeout: 600000,
				auth: {username: getCookie('AHFToken')},
				onUploadProgress: function (progressEvent) {

					var progress = Math.round(progressEvent.loaded / progressEvent.total * 100);

					if(progressEvent.total > 0) {

						this.setState({Progress: progress});

					}

				}.bind(this),
			};

			axios.post(URL, data, config).then(this.EndConnection).catch(this.catchError);

		}

		else {

			this.message.push(errorMessage);

			this.setState({ showMessage: true });

		}

	}

	ValidateFileType(value) {

		if(value === 'NULL') {

			return('Vali faili tüüp!');

		}

		return('');

	}

}