import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import Application from './Application';
import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
  getApplicationFile,
  getCookie,
  getErrorMessage,
  setCookie
} from './Utils';

export default class AdminApplications extends Component {

  constructor(props) {

    super(props);

    this.answer = this.answer.bind(this);
    this.catchError = this.catchError.bind(this);
    this.cloneApplication = this.cloneApplication.bind(this);
    this.downloadApplication = this.downloadApplication.bind(this);
    this.endApplicationsConnection = this.endApplicationsConnection.bind(this);
    this.endCloneApplicationConnection = this.endCloneApplicationConnection.bind(this);
    this.endDownloadApplicationConnection = this.endDownloadApplicationConnection.bind(this);
    this.endMarkNotificationConnection = this.endMarkNotificationConnection.bind(this);
    this.endNotifyConnection = this.endNotifyConnection.bind(this);
    this.endSwitchToUserConnection = this.endSwitchToUserConnection.bind(this);
    this.logOut = this.logOut.bind(this);
    this.markNotification = this.markNotification.bind(this);
    this.notify = this.notify.bind(this);
    this.openNotificationDialogue = this.openNotificationDialogue.bind(this);
    this.search = this.search.bind(this);
    this.setShowMessage = this.setShowMessage.bind(this);
    this.switchToUser = this.switchToUser.bind(this);
    this.toggleFiltered = this.toggleFiltered.bind(this);

    this.APIURL = require('./../config/config.js').APIURL;
    this.config = {
      headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
      timeout: 30000,
      auth: {username: getCookie('AHFToken')},
    };
    this.filtered = 'True';
    this.message = [];
    this.searchValue = '';
    this.state = {
      applications: [],
      isLoading: false,
      showMessage: false
    };

    if(getCookie('AHFFiltered') === 'False') {

      this.filtered = 'False';

    }

  }

  componentDidMount() {

    this.searchValue = getCookie('AHFSearch');

    this.search();

  }

  render() {

    document.body.style.backgroundColor = '#BA491C';

    var Checked= {};

    if(this.filtered === 'False') {

      Checked['defaultChecked'] = 'defaultChecked';

    }

    return(

      <div className='largeBoxWrapper'>
        <LoadingOverlay isLoading={this.state.isLoading}/>
        <ShowMessage
          answer={this.answer}
          message={this.message}
          setShowMessage={this.setShowMessage}
          showMessage={this.state.showMessage}
        />
        <div id='Tabs'>
          <div id='SelectedTab'>Laekunud avaldused<p></p></div>
          <Link
            className='unselectedTab'
            name="AdministrationTool">
            Administreerimine
          </Link>
          <Link
            className='unselectedTab'
            name="Archive">
            Arhiiv
          </Link>
          <Link
            className='unselectedTab'
            name="Reports">
            Raportid
          </Link>
          <Link
            className='unselectedTab'
            name="UploadFile">
            Failide üleslaadimine
          </Link>
        </div>
        <div id='AdminApplicationsBox'>
          <form onSubmit={this.search.bind(this)}>
            <table className='MaxWidth'>
              <tbody>
                <tr>
                  <td className='Logo'>
                    <img src='/img/logo.gif' alt=''></img>
                  </td>
                  <td className='Center'>
                    <h2>Avalduste registreerimine</h2>
                  </td>
                  <td className='TopButtons'>
                    <button type='button' className='StandardButton' onClick={this.logOut}>Logi välja</button>
                  </td>
                </tr>
                <tr>
                  <td className='Center' colSpan='3'>
                    <input
                      defaultValue={this.searchValue}
                      name='Search'
                      type='text'
                      size='24'
                      maxLength='50'>
                    </input>
                    <button className='StandardButton' type='submit'>Otsi</button>
                    <span className='Space'></span>
                    Kuva kõik:
                    <input
                      {...Checked}
                      className='Lower'
                      type="checkbox"
                      onChange={this.toggleFiltered}>
                    </input>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className='InnerBox'>
            <table id='ApplicationsTable'>
              <thead>
                <tr>
                  <th className='Width100'>Isikukood/ Registri number</th>
                  <th className='Width70'>Sisene kasutajana</th>
                  <th className='Width120'>Taotleja</th>
                  <th className='WidthRemaining'>E-posti aadress</th>
                  <th className='Width80'>Viimati muudetud</th>
                  <th className='Width70'>Taotlus</th>
                  <th className='Width80'>Fail laetud</th>
                  <th className='Width120'>Autor</th>
                  <th className='Width80'>Olek</th>
                  <th className='Width60'>Roll</th>
                  <th className='Width40'>Uus</th>
                  <th className='Width80'>Teavitus</th>
                </tr>
              </thead>
              <tbody>
              {this.state.applications.map(function(application, i) {
                return(
                  <Application
                    APIURL={this.APIURL}
                    application={application}
                    cloneApplication={this.cloneApplication}
                    downloadApplication={this.downloadApplication}
                    index={i}
                    key={i}
                    openNotificationDialogue={this.openNotificationDialogue}
                    switchToUser={this.switchToUser}
                    router={this.props.router}
                  />
                );
              }, this)}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    );

  }

  answer(index) {

    switch(index) {
      case 0:
        this.notify();
        break;
      case 1:
        this.markNotification();
        break;
      default:
    }

  }

  catchError(error) {

    this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

    this.setState({
      isLoading: false,
      showMessage: true
    });

  }

  cloneApplication(applicationID) {

    this.setState({ isLoading: true });

    var URL = this.APIURL + 'applications/' + applicationID + '/clone';

    axios.get(URL, this.config).then(this.endCloneApplicationConnection).catch(this.catchError);

  }

  downloadApplication(index) {

    this.setState({ isLoading: true });

    var URL = this.APIURL
      + 'applications/' + this.state.applications[index].appl_id
      + '/' + this.state.applications[index].party_id
      + '/files/' + this.state.applications[index].file_id;

    axios.get(URL, this.config).then(this.endDownloadApplicationConnection).catch(this.catchError);

  }

  endApplicationsConnection(response) {

    var applications = response.data.data;

    var partyID = -1;

    for(let i = 0; i < applications.length; i ++) {

      if(partyID !== applications[i].party_id) {

        partyID = applications[i].party_id;

        if(applications[i].legal_cd != null) {

          applications[i].personal_cd = applications[i].legal_cd;

        }

      }

      else {

        applications[i].personal_cd = '';
        applications[i].user_password = '';
        applications[i].party_create_dtime = '';
        applications[i].applicant_first_name = '';
        applications[i].applicant_family_name = '';

      }

    }

    this.setState({
      applications: applications,
      isLoading: false
    });

  }

  endCloneApplicationConnection(response) {

    this.search();

  }

  endDownloadApplicationConnection(response) {

    getApplicationFile(response.data.data);

    this.setState({ isLoading: false });

  }

  endMarkNotificationConnection(response) {

    var applications = this.state.applications.slice();

    applications[this.index].message_sent = 'M';

    const date = new Date();

    applications[this.index].message_sent_date = date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate();

    this.message.push([{
      value: 'Avaldus ' + applications[this.index].appl_id + ' märgitud teavitatuks.',
      align: 'center'
    }]);

    this.setState({
      applications: applications,
      isLoading: false,
      showMessage: true
    });
  }

  endNotifyConnection(response) {

    var applications = this.state.applications.slice();

    applications[this.index].message_sent = 'Y';

    this.message.push([{
      value: 'Kinnituse e-mail saadetud aadressile ' + this.notificationEMail,
      align: 'center'
    }]);

    this.setState({
      applications: applications,
      isLoading: false,
      showMessage: true
    });

  }

  endSwitchToUserConnection(response) {

    setCookie('AHFLevel', response.data.data.user_level, 4);
    setCookie('AHFToken', response.data.data.token, 4);
    setCookie('AHFUser', response.data.data.user_id, 4);

    let url = this.props.router.url({ name: 'Applications' });

    this.props.router.navigate({ url });

  }

  logOut() {

    setCookie('AHFLevel', getCookie('AHFLevel'), -1);
    setCookie('AHFToken', getCookie('AHFToken'), -1);
    setCookie('AHFUser', getCookie('AHFToken'), -1);

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

  }

  markNotification() {

    this.setState({ isLoading: true });

    var data = '{"message_sent":"M"}';

    var URL = this.APIURL + 'applications/' + this.state.applications[this.index].appl_id;

    axios.put(URL, data, this.config).then(this.endMarkNotificationConnection).catch(this.catchError);

  }

  notify() {

    this.setState({ isLoading: true });

    var data =
      '{"application_id": ' + this.state.applications[this.index].appl_id + ',"email": "'
      + this.state.applications[this.index].party_email.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
      + '"}';

    var URL = this.APIURL + 'applications/confirmemail';

    this.notificationEMail = this.state.applications[this.index].party_email;

    axios.post(URL, data, this.config).then(this.endNotifyConnection).catch(this.catchError);

  }

  openNotificationDialogue(index) {

    this.message.push([{
      value: 'Avalduse number ' + this.state.applications[index].appl_id + ' teavitus',
      align: 'center',
      choices: ['Saada teavitus', 'Märgi teavitatuks', 'Loobu']
    }]);

    this.index = index;

    this.setState({
      isLoading: false,
      showMessage: true
    });

  }

  search(event) {

    this.setState({ isLoading: true });

    if(event != null) {

      event.preventDefault();
    
      this.searchValue = event.target.Search.value;

      setCookie('AHFFiltered', this.filtered, 4);

      setCookie('AHFSearch', this.searchValue, 4);

    }

    var search = '';

    if(this.searchValue.length > 0) {

      search = '&search=' + this.searchValue;

    }

    var URL = this.APIURL + 'applications?filtered=' + this.filtered + search;

    axios.get(URL, this.config).then(this.endApplicationsConnection).catch(this.catchError);

  }

  setShowMessage(showMessage) {

    this.setState({showMessage: showMessage});

  }

  switchToUser(index) {

    this.setState({ isLoading: true });

    var URL = this.APIURL + 'login/idswitch';

    var data = '{"party_id":' + this.state.applications[index].party_id + '}';

    axios.post(URL, data, this.config).then(this.endSwitchToUserConnection).catch(this.catchError);

  }

  toggleFiltered() {

    if(this.filtered === 'True') {

      this.filtered ='False';

    }

    else {

      this.filtered = 'True';

    }

  }

}