import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import { getErrorMessage } from './Utils';

export default class NewPassword extends Component {

	constructor(props) {

		super(props);

		this.back = this.back.bind(this);
		this.catchError = this.catchError.bind(this);
		this.changeParool1 = this.changeParool1.bind(this);
		this.changeParool2 = this.changeParool2.bind(this);
		this.endCheckPasswordConnection = this.endCheckPasswordConnection.bind(this);
		this.endNewPasswordConnection = this.endNewPasswordConnection.bind(this);
    this.endTestRegTokenConnection = this.endTestRegTokenConnection.bind(this);
    this.endTestRegTokenConnectionError = this.endTestRegTokenConnectionError.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.message = [];
		this.navigateToLogin = false;
		this.state = {
			isLoading: false,
      parool1: '',
      parool2: '',
			showMessage: false,
      showRepeat: false
		};
    this.token = null;

	}

  componentDidMount() {

    this.token = window.location.search.substring(10, window.location.search.length);

    if(window.location.search.substring(0, 10) === '?regtoken=' && this.token !== '') {

      this.setState({ isLoading: true });

      var URL = this.APIURL + 'login/test';

      var data = '{"regtoken": "' + this.token + '"}';

      var config = {
        headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
        timeout: 30000
      };

      axios.post(URL, data, config).then(this.endTestRegTokenConnection).catch(this.endTestRegTokenConnectionError);

    }

    else {

      let url = this.props.router.url({ name: 'Login' });

      this.props.router.navigate({ url });

    }

  }

	render() {

		document.body.style.backgroundColor = '#E7D074';

		var Options = {};

		if(this.state.isLoading || this.state.showMessage) {

			Options['disabled'] = 'disabled';

		}

		return(

			<div className='smallBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div className='smallBox OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Parooli loomine</h2>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
            <ul>
              <li>Parool peab olema vähemalt 8 sümbolit pikk.</li>
              <li>Parool peab sisaldama suurtähte.</li>
              <li>Parool peab sisaldama väiketähte.</li>
              <li>Parool peab sisaldama numbrit.</li>
            </ul>
						<form onSubmit={this.handleSubmit.bind(this)}>
							<table className='MaxWidth'>
								<tbody>
                  {!this.state.showRepeat ?
                    <tr>
                      <td className='NormalLabel'>
                        Sisesta uus parool:
                      </td>
                      <td>
                        <input
                          className='MaxWidth'
                          {...Options}
                          autoFocus
                          type='password'
                          name='Parool1'
                          maxLength='50'
                          onChange={this.changeParool1}
                          value={this.state.parool1}>
                        </input>
                      </td>
                    </tr> :
                    <tr>
                      <td className='NormalLabel'>
                        Korda uut parooli:
                      </td>
                      <td>
                        <input
                          className='MaxWidth'
                          {...Options}
                          autoFocus
                          type='password'
                          name='Parool2'
                          maxLength='50'
                          onChange={this.changeParool2}
                          value={this.state.parool2}>
                        </input>
                      </td>
                    </tr>
                  }
									<tr>
										<td colSpan='2' className='Center'>
                      {this.state.showRepeat ? 
                        <button {...Options} className='StandardButton' type='button' onClick={this.back}>
                          Tagasi
                        </button> : ''
                      }
											<button {...Options} className='StandardButton' type='submit'>
                        {!this.state.showRepeat ? 'Edasi' : 'Loo'}
                      </button>
										</td>
									</tr>
								</tbody>
							</table>
						</form>
					</div>
				</div>
			</div>
		);

	}

	back() {

		this.setState({
      parool1: '',
      parool2: '',
      showRepeat: false
    });

	}

	catchError(error) {

		this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

  changeParool1(event) {

		this.setState({parool1: event.target.value});

  }

  changeParool2(event) {

		this.setState({parool2: event.target.value});

  }

	endCheckPasswordConnection(response) {

		this.setState({
      isLoading: false,
      parool1: response.data.data.password,
      parool2: '',
      showRepeat: true
    });

	}

  endTestRegTokenConnection(response) {

		this.setState({isLoading: false});

  }

	endNewPasswordConnection(response) {

    this.message.push([{value: 'Uus parool loodud!', align: 'center'}]);

    this.setState({
      isLoading: false,
      showMessage: true
    });

    this.navigateToLogin = true;

	}

  endTestRegTokenConnectionError(error) {

    if
      (error.response != null
      && error.response.data != null
      && error.response.data.message === 'Token expired') {

      this.message.push([
        {value: 'Otselink ei kehti!', align: 'center'},
        {value: 'Vajadusel telli uus vajutades parooli taastamise nupule.', align: 'center'}
      ]);

      this.setState({
        isLoading: false,
        showMessage: true
      });

      this.navigateToLogin = true;

    }

    else {

      this.catchError(error);

    }

  }

	handleSubmit(event) {

		event.preventDefault();

    let data = '';
    let URL = '';
    let config = {};

    if(!this.state.showRepeat) {

      if(event.target.Parool1.value.length === 0) {

        this.message.push([{value: 'Sisesta parool!', align: 'center'}]);

        this.setState({ showMessage: true });

      }

      else {

        this.setState({ isLoading: true });

        data = '{"password":"' + event.target.Parool1.value + '"}';

        URL = this.APIURL + 'login/strongenough';

        config = {

          headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
          timeout: 30000,

        };

        axios.post(URL, data, config).then(this.endCheckPasswordConnection).catch(this.catchError);

      }
      
    }

    else {

      if(event.target.Parool2.value.length === 0) {

        this.message.push([{value: 'Korda uut parooli!', align: 'center'}]);

        this.setState({ showMessage: true });

      }

      else if(event.target.Parool2.value !== this.state.parool1) {

        this.message.push([{value: 'Parool ei kattu varem sisestatuga!', align: 'center'}]);

        this.setState({ showMessage: true });

      }

      else {

        this.setState({ isLoading: true });

        data = '{'
            + '"regtoken":"' + this.token + '",'
            + '"password":"' + event.target.Parool2.value + '"'
            +'}';

        URL = this.APIURL + 'login/newpassword';

        config = {

          headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
          timeout: 30000,

        };

        axios.post(URL, data, config).then(this.endNewPasswordConnection).catch(this.catchError);

      }

    }

	}

	setShowMessage(showMessage) {

		if(this.navigateToLogin) {

      let url = this.props.router.url({ name: 'Login' });

      this.props.router.navigate({ url });

		}

		else {

			this.setState({showMessage: showMessage});

		}

	}

}