import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	catchError,
	getCookie
} from './Utils';

export default class UploadApplication extends Component {

	constructor(props) {

		super(props);

		this.back = this.back.bind(this);
		this.catchError = catchError.bind(this);
		this.catchUploadError = this.catchUploadError.bind(this);
		this.endApplicationConnection = this.endApplicationConnection.bind(this);
		this.endUploadFileConnection = this.endUploadFileConnection.bind(this);
		this.selectFile = this.selectFile.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);
		this.uploadFile = this.uploadFile.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.doAfterError = false;
		this.message = [];
		this.state = {
			application: {},
			fileSelected: false,
			isLoading: false,
			progress: 0,
			showMessage: false,
      visibleInputValue: ''
		};

	}

	componentDidMount() {

		var URL = this.APIURL + 'applications/' + this.props.response.params.applicationID;

		axios.get(URL, this.config).then(this.endApplicationConnection).catch(this.catchError);

	}

	render() {

		document.body.style.backgroundColor = '#E7D074';

		var borderRadius = '0px';

		if(this.state.progress === 100) {

			borderRadius = '7px'

		}

		return(

			<div id='applicationsBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='ApplicationsBox' className='OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Avalduse nr. {this.props.response.params.applicationID} üleslaadimine</h2>
								</td>
								<td className='TopButtons'>
									<button type='button' className='StandardButton' id='ButtonWidth' onClick={this.back}>Tagasi</button>
								</td>
							</tr>
						</tbody>
					</table>
					<ul>
						<li>Looge ja digiallkirjastage PDF ning laadige digikonteiner üles.</li>
					</ul>
					<div className='InnerBox'>
						<div id='Upload'>
              <form onSubmit={this.uploadFile}>
                <span id='UploadBox'>
                  <div id='UploadCover'>
                    <input value={this.state.visibleInputValue} name='VisibleInput' size='30' readOnly></input>
                    <button id='UploadButton'>Vali digi- allkirjastatud avaldus</button>
                  </div>
                  <input
                    type='file'
                    accept='.bdoc,.ddoc,.cdoc,.asice'
                    name='File'
                    id='UploadInput'
                    size='30'
                    onChange={this.selectFile.bind(this)}>
                  </input>
                </span>
                {this.state.fileSelected ? <button type='submit' className='StandardButton'>Lae üles</button> : null}
                {this.state.fileSelected ?
                  <div>
                    <div id='ProgressBar'>
                      <div id='Progress' style={{width: this.state.progress + '%', borderTopRightRadius: borderRadius, borderBottomRightRadius: borderRadius}}></div>
                    </div>
                    {this.state.progress}%
                  </div>
                  : null}
              </form>
						</div>
					</div>
				</div>
			</div>

		);

	}

	back() {

    let url = this.props.router.url({
			name: 'ApplicationView',
			params: { applicationID: this.props.response.params.applicationID }
		});

    this.props.router.navigate({ url });

	}

	endApplicationConnection(response) {

		var application = response.data.data;

		if(application.status_cd !== 'C' && application.status_cd !== 'E') {

      let url = this.props.router.url({
				name: 'EditApplication',
				params: { applicationID: application.appl_id }
      });

      this.props.router.navigate({ url });

		}

		else {

			this.setState({application: application});

		}

	}

	endUploadFileConnection(response) {

		this.doAfterError = false;

		this.message.push([
			{
				value: 'Laadimine edukalt lõpetatud.',
				align: 'center'
			},
			{
				value: 'Avalduse laekumise kohta saadab AHF teie epostile kinnituskirja hiljemalt kolme tööpäeva jooksul.',
				align: 'center'
			}
		]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	selectFile(event) {

    let value = event.target.value;

    let i = value.lastIndexOf('\\') + 1;

    if(i !== -1) {

      value = value.substring(i, value.length);

    }

		if(value != null) {

			this.setState({
        fileSelected: true,
        progress: 0,
        visibleInputValue: value
      });

		}

		else {

			this.setState({
        fileSelected: false,
        progress: 0,
        visibleInputValue: ''
      });

		}

	}

	setShowMessage(showMessage) {

		if(!showMessage) {

			if(this.navigateBackAfterMessage) {

        let url = this.props.router.url({ name: 'Applications' });

        this.props.router.navigate({ url });

			}

			else if(this.doAfterError) {

				this.doAfterError = false;

				this.setState({
					progress: 0,
					showMessage: false
				});

			}

			else {

				this.setState({showMessage: false});

			}

		}

		else {

			this.setState({showMessage: true});

		}

	}

  catchUploadError(error) {

      this.catchError(error);

			this.message.push([{
				value: 'Faili üleslaadimine ebaõnnestus!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

  }

	uploadFile(event) {

    event.preventDefault();

		var nameLength = event.target.File.files[0].name.length;

		var fileExtension = '';

		if(nameLength > 5) {

			fileExtension = event.target.File.files[0].name.substring(nameLength - 5, nameLength).toUpperCase();

		}

		if(fileExtension === '.BDOC' || fileExtension === '.DDOC' || fileExtension === '.CDOC' || fileExtension === 'ASICE') {

			this.setState({ isLoading: true });

			var URL = this.APIURL
				+ 'applications/' + this.props.response.params.applicationID
				+ '/' + this.state.application.party_id + '/files';

			var data = new FormData();

			data.append('file', event.target.File.files[0].name);

			data.append('file', event.target.File.files[0]);

			this.doAfterError = true;

			var config = {
				headers: {'Content-Type': 'application/binary', 'Pragma': 'no-cache'},
				timeout: 600000,
				auth: {username: getCookie('AHFToken')},
				onUploadProgress: function (progressEvent) {
					var progress = Math.round(progressEvent.loaded / progressEvent.total * 100);
					if(progressEvent.total > 0) {
						this.setState({progress: progress});
					}
				}.bind(this),
			};

			axios.post(URL, data, config).then(this.endUploadFileConnection).catch(this.catchUploadError);

		}

		else {

			this.message.push([{
				value: 'Sisestatud fail ei ole DigiDoc laiendiga!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

	}

}