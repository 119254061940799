import React, { Component } from 'react';
import axios from 'axios';

export default class EditApplicationWork extends Component {

  constructor(props) {

    super(props);

    this.EndAuthorshipConnection = this.EndAuthorshipConnection.bind(this);
    this.endCheckISBNFormationPartIDConnection = this.endCheckISBNFormationPartIDConnection.bind(this);
    this.EndWorkConnection = this.EndWorkConnection.bind(this);
    this.SaveAuthorship = this.SaveAuthorship.bind(this);
    this.save = this.save.bind(this);
    this.saveWork = this.saveWork.bind(this);
    this.ToggleEditAuthorship = this.ToggleEditAuthorship.bind(this);
    this.ToggleEditWork = this.ToggleEditWork.bind(this);
    this.ToggleIsAcademic = this.ToggleIsAcademic.bind(this);
    this.ToggleISBNPuudub = this.ToggleISBNPuudub.bind(this);
    this.ValidateAllocation = this.ValidateAllocation.bind(this);
    this.ValidateAuthorName = this.ValidateAuthorName.bind(this);
    this.ValidateAuthorshipCd = this.ValidateAuthorshipCd.bind(this);
    this.ValidateFormationPartID = this.ValidateFormationPartID.bind(this);
    this.ValidateIsAcademic = this.ValidateIsAcademic.bind(this);
    this.ValidateISBN = this.ValidateISBN.bind(this);
    this.ValidateIsEWork = this.ValidateIsEWork.bind(this);
    this.ValidatePublisher = this.ValidatePublisher.bind(this);
    this.ValidateSpecialPercentage = this.ValidateSpecialPercentage.bind(this);
    this.ValidateTitle = this.ValidateTitle.bind(this);
    this.ValidateYearOfIssue = this.ValidateYearOfIssue.bind(this);

    this.state = {
      EditWork: false,
      EditAuthorship: -1,
    };

  }

  render() {

    var Options = {};

    if(this.props.Connecting) {

      Options['disabled'] = 'disabled';

    }

    if(this.state.EditAuthorship !== -1) {

      return(

        <tr>
          <td colSpan='9'>
            <div className='MaxWidth'>
              <form onSubmit={this.SaveAuthorship.bind(this)}>
                <table className='EditTable' style={{'border': 'none'}}>
                  <tbody>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Autori eesnimi:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='27'
                          maxLength='50'
                          name='AuthorFirstName'
                          defaultValue={this.props.Work.authorship[this.state.EditAuthorship].author_first_name}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Autori perenimi:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='27'
                          maxLength='50'
                          name='AuthorFamilyName'
                          defaultValue={this.props.Work.authorship[this.state.EditAuthorship].author_family_name}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Autorsus:
                      </td>
                      <td id='EditTableValue'>
                        <select {...Options} name='AuthorshipCd' defaultValue={this.props.Work.authorship[this.state.EditAuthorship].authorship_cd}>
                          <option value='NULL'>Vali autorsus</option>
                          <option value='A'>Autor</option>
                          <option value='K'>Koostaja</option>
                          <option value='E'>Eessõna autor</option>
                          <option value='J'>Järelsõna autor</option>
                          <option value='KU'>Kujundaja</option>
                          <option value='I'>Illustreerija</option>
                          <option value='T'>Tõlkija</option>
                          <option value='TA'>Tõlgitud teose originaali autor</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Sama tüüpi autoreid:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='1'
                          maxLength='4'
                          name='Allocation'
                          defaultValue={this.props.Work.authorship[this.state.EditAuthorship].allocation}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td className='NormalLabel'>
                        <button {...Options} onClick={this.ToggleEditAuthorship}>Unusta muudatused <img className='Middle' src='/img/undo.png' alt=''></img></button>
                      </td>
                      <td className='Content'>
                        <button {...Options} type='submit'>Salvesta muudatused <img className='Middle' src='/img/ok.png' alt=''></img></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </td>
        </tr>

      );

    }

    else if(this.state.EditWork) {

      var Checked= {};

      if(this.props.Work.is_academic === 'Y') {

        Checked['defaultChecked'] = 'defaultChecked';

      }

      var Class = {};

      var ISBNChecked= {};

      if(!this.state.ISBNPuudub) {

        Class['id'] = 'EditTableRequiredField';

      }

      else {

        Class['id'] = 'EditTableNormalField';

        ISBNChecked['defaultChecked'] = 'defaultChecked';

      }

      return(

        <tr>
          <td colSpan='9'>
            <div>
              <form onSubmit={this.save.bind(this)}>
                <table className='EditTable' style={{'border': 'none'}}>
                  <tbody>
                    <tr>
                      <td id='EditTableNormalField'>
                        ID:
                      </td>
                      <td id='EditTableValue'>
                        {this.props.Work.work_id}
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Pealkiri:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options} 
                          type='text'
                          size='60'
                          name='Title'
                          maxLength='1000'
                          defaultValue={this.props.Work.title}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Trükis/e-raamat:
                      </td>
                      <td id='EditTableValue'>
                        <select
                          {...Options}
                          name='IsEWork'
                          defaultValue={this.props.Work.is_ework}>
                          <option value='NULL'>Vali trükis/e-raamat</option>
                          <option value='N'>Trükis</option>
                          <option value='Y'>E-raamat</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Kujunduse osa:
                      </td>
                      <td id='EditTableValue'>
                        <select
                          {...Options}
                          name='FormationPartID'
                          defaultValue={this.props.Work.formation_part_id}>
                          <option value='NULL'>Vali kujunduse maht</option>
                          <option value='1'>Illustreerimata/üksikute illustratsioonidega teos</option>
                          <option value='4'>Illustreeritud teos</option>
                          <option value='5'>Illustratsioone üle poole teosest</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Õppe- ja teadusk.:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          {...Checked}
                          className='Lower'
                          type="checkbox"
                          onChange={this.ToggleIsAcademic}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableNormalField'>
                        Ilmumiskoht:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='ComeOut'
                          maxLength='20'
                          defaultValue={this.props.Work.come_out}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Aasta:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='4'
                          name='YearOfIssue'
                          maxLength='4'
                          defaultValue={this.props.Work.year_of_issue}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        ISBN puudub:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          {...ISBNChecked}
                          className='Lower'
                          type="checkbox"
                          onChange={this.ToggleISBNPuudub}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td {...Class}>
                        ISBN:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='ISBN'
                          maxLength='30'
                          defaultValue={this.props.Work.isbn}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableRequiredField'>
                        Kirjastaja:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='25'
                          name='Publisher'
                          maxLength='50'
                          defaultValue={this.props.Work.publisher}>
                        </input>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableNormalField'>
                        Eraldi kokkulepitud jaotus:
                      </td>
                      <td id='EditTableValue'>
                        <input
                          {...Options}
                          type='text'
                          size='1'
                          maxLength='3'
                          name='SpecialPercentage'
                          defaultValue={this.props.Work.special_percentage}>
                        </input>
                        %
                      </td>
                    </tr>
                    <tr>
                      <td className='NormalLabel'>
                        <button {...Options} onClick={this.ToggleEditWork}>Unusta muudatused <img className='Middle' src='/img/undo.png' alt=''></img></button>
                      </td>
                      <td className='Content'>
                        <button {...Options} type='submit'>Salvesta muudatused <img className='Middle' src='/img/ok.png' alt=''></img></button>
                      </td>
                    </tr>
                    <tr>
                      <td id='EditTableNormalField'>
                        Laenutuste arv:
                      </td>
                      <td id='EditTableValue'>
                        {this.props.Work.renting_qty}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </td>
        </tr>

      );

    }

    else {

      var Background = {};

      if(this.props.Work.is_academic === 'Y') {

        Background['id'] = 'Accepted';

      }

      return(

        <tr {...Background}>
          <td onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.work_id}
          </td>
          <td className='TextLeft' onClick={this.ToggleEditWork}>
            {this.props.Work.title}
          </td>
          <td onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.is_ework ? (this.props.Work.is_ework === 'Y' ? 'Jah' : 'Ei') : ''}
          </td>
          <td onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.year_of_issue}
          </td>
          <td className='TextLeft' style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.authorship.map(function(authorship, i) {

              return(

                <div key={i} onClick={this.ToggleEditAuthorship.bind(this, i)}>
                  {authorship.author_first_name} {authorship.author_family_name}, {authorship.authorship_name}: {authorship.allocation}
                </div>

              );
  
            }, this)}
          </td>
          <td className='TextLeft' onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.isbn}
          </td>
          <td onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.special_percentage}
          </td>
          <td onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.is_academic === 'Y' ? 'Jah' : 'Ei'}
          </td>
          <td className='TextLeft' onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.work_id == null ? '' : (this.props.Work.formation_part_id === 5 ? 'Illustratsioone üle poole teosest' : (this.props.Work.formation_part_id === 4 ? 'Illustreeritud teos' : 'Illustreerimata/ üksikute illustratsioonidega teos'))}
          </td>
          <td onClick={this.ToggleEditWork} style={{'whiteSpace': 'nowrap'}}>
            {this.props.Work.renting_qty}
          </td>
        </tr>

      );

    }

  }

  endCheckISBNFormationPartIDConnection(response, event) {

    if(response.data.data.result != null && response.data.data.result) {

      this.props.ShowAlert('See teos on AHFis registreeritud erineva kujunduse mahuga.');
      
      this.props.ShowAlert('Palun kontrollige ja vajadusel muutke kujunduse mahtu.');

    }

    this.saveWork(event);
    
  }

  EndAuthorshipConnection(Response) {

    this.props.ShowAlert('Muudatused salvestatud!');

    this.props.ChangeConnecting(false);

    this.props.LoadWork();

    this.setState({EditAuthorship: -1});

  }

  EndWorkConnection(Response) {

    this.props.ShowAlert('Muudatused salvestatud!');

    this.props.ChangeConnecting(false);

    this.props.LoadWork();

    this.setState({EditWork: false});

  }

  save(event) {

		event.preventDefault();

    var ErrorMessage =
      this.ValidateTitle(event.target.Title.value)
      + this.ValidateIsEWork(event.target.IsEWork.value)
      + this.ValidateFormationPartID(event.target.FormationPartID.value)
      + this.ValidateIsAcademic(this.IsAcademic, event.target.FormationPartID.value)
      + this.ValidateYearOfIssue(event.target.YearOfIssue.value)
      + this.ValidateISBN(event.target.ISBN.value)
      + this.ValidatePublisher(event.target.Publisher.value)
      + this.ValidateSpecialPercentage(event.target.SpecialPercentage.value);

    if(ErrorMessage.length === 0) {

      this.props.ChangeConnecting(true);

      var ISBN = (event.target.ISBN == null ? '' : event.target.ISBN.value).replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"');

      if(ISBN != null && ISBN.length > 0
        && event.target.FormationPartID.value != null
        && event.target.FormationPartID.value.length > 0) {

        var URL = this.props.APIURL + 'applications/'
          + this.props.Work.party_id + '/works/isbn/'
          + event.target.FormationPartID.value + '/' + ISBN;

        var config = {

          headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
          timeout: 30000,
          auth: {username: this.props.Token}

        };

        axios.get(URL, config).then((response) => this.endCheckISBNFormationPartIDConnection(response, event)).catch(this.catchError);

      }

      else {

        this.saveWork(event);

      }

    }

    else {

      this.props.ShowAlert(ErrorMessage);

    }

  }

  SaveAuthorship(event) {

		event.preventDefault();

    var ErrorMessage =
      this.ValidateAuthorName(event.target.AuthorFirstName.value)
      + this.ValidateAuthorshipCd(event.target.AuthorshipCd.value)
      + this.ValidateAllocation(event.target.Allocation.value);

    if(ErrorMessage.length === 0) {

      this.props.ChangeConnecting(true);

      var URL =
        this.props.APIURL
        + 'applications/'
        + this.props.Work.appl_id
        + '/works/'
        + this.props.Work.work_id
        + '/authorship/'
        + this.props.Work.party_id
        + '/'
        + this.props.Work.authorship[this.state.EditAuthorship].authorship_cd;

      var AuthorFirstName = 'N/A';

      if(event.target.AuthorFirstName.value.length > 0) {

        AuthorFirstName = event.target.AuthorFirstName.value;

      }

      var AuthorFamilyName = 'N/A';

      if(event.target.AuthorFamilyName.value.length > 0) {

        AuthorFamilyName = event.target.AuthorFamilyName.value;

      }

      var data =
        '{"author_first_name": "' + AuthorFirstName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"author_family_name": "' + AuthorFamilyName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"authorship_cd": "' + event.target.AuthorshipCd.value + '",'
        + '"allocation": "' + event.target.Allocation.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

      var config = {

        headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
        timeout: 30000,
        auth: {username: this.props.Token}

      };

      axios.put(URL, data, config).then(this.EndAuthorshipConnection).catch(this.props.catchError);

    }

    else {

      this.props.ShowAlert(ErrorMessage);

    }

  }

  saveWork(event) {

    var ComeOut = '';

    if(event.target.ComeOut.value.length > 0) {

      ComeOut = event.target.ComeOut.value;

    }

    var data =
      '{"title": "' + event.target.Title.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"is_ework": "' + event.target.IsEWork.value + '",'
      + '"formation_part_id": ' + event.target.FormationPartID.value + ','
      + '"is_academic": "' + this.IsAcademic + '",'
      + '"come_out": "' + ComeOut.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"year_of_issue": ' + event.target.YearOfIssue.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + ','
      + '"isbn": "' + event.target.ISBN.value.replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
      + '"publisher": "' + event.target.Publisher.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"';

    if(event.target.SpecialPercentage != null && event.target.SpecialPercentage !== '') {
      data += ',"special_percentage": "' + event.target.SpecialPercentage.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';
    }

    var URL = this.props.APIURL + 'applications/' + this.props.Work.appl_id + '/' + this.props.Work.party_id + '/works/' + this.props.Work.work_id;

    var config = {

      headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
      timeout: 30000,
      auth: {username: this.props.Token}

    };

    axios.put(URL, data, config).then(this.EndWorkConnection).catch(this.props.catchError);

  }

  ToggleEditAuthorship(Index) {

    if(this.state.EditAuthorship === -1) {

      this.setState({EditAuthorship: Index});

    }

    else {

      this.setState({EditAuthorship: -1});

    }

  }

  ToggleEditWork() {

    if(this.state.EditWork) {

      this.setState({EditWork: false});

    }

    else {

      var ISBNPuudub = false;

      if(this.props.Work.isbn == null || this.props.Work.isbn.length === 0) {

        ISBNPuudub = true;

      }

      this.setState({ISBNPuudub: ISBNPuudub});

      this.IsAcademic = this.props.Work.is_academic;

      this.setState({EditWork: true});

    }

  }

  ToggleIsAcademic() {

    if(this.IsAcademic === 'N') {

      this.IsAcademic = 'Y';

    }

    else {

      this.IsAcademic = 'N';

    }

  }

  ToggleISBNPuudub() {

    if(this.state.ISBNPuudub) {

      this.setState({ISBNPuudub: false});

    }

    else {

      this.setState({ISBNPuudub: true});

    }

  }

  ValidateAllocation(Value) {

    if(Value.length === 0) {

      return('Sama tüüpi autoreid peab olema täidetud!');

    }

    else if(Value.search(/[^0-9]/) !== -1) {

      return('Sama tüüpi autoreid peab olema täisarv!');

    }

    else if(Value === 0) {

      return('Sama tüüpi autoreid on alati vähemalt 1!');

    }

    return('');

  }

  ValidateAuthorName(value) {

    if(value.length === 0 && value.length === 0) {

      return('Autori eesnimi või perenimi peab olema täidetud!');

    }

    return('');

  }

  ValidateAuthorshipCd(Value) {

    if(Value === 'NULL') {

      return('Vali autorsus!');

    }

    return('');

  }

  ValidateFormationPartID(Value) {

    if(Value === 'NULL') {

      return('Vali kujunduse maht!');

    }

    return('');

  }

  ValidateIsAcademic(Value, FormationPartID) {

    var HasNoneIllustrator = false;

    for(var i = 0; i < this.props.Work.authorship.length; i ++) {

      if(this.props.Work.authorship[i].authorship_cd !== 'I' && this.props.Work.authorship[i].authorship_cd !== 'KU') {

        HasNoneIllustrator = true;

        break;

      }

    }

    if(Value === 'Y' && FormationPartID === '1' && !HasNoneIllustrator) {

      return('Illustreerimata teose kujundust ei saa märkida õppe- ja teadusteoseks!');

    }

    return('');

  }

  ValidateISBN(Value) {

    if(!this.state.ISBNPuudub && Value.replace(/[-\s+]/g, '').replace(/\\/g, '\\\\').replace(/"/g, '\\"').length === 0) {

      return('ISBN peab olema täidetud!');

    }

    if
      (!this.state.ISBNPuudub
      &&
        ((Value.toUpperCase().search('^[0-9]+X?$') === -1)
        || !(Value.length === 10 || Value.length === 13))) {

      return('ISBNi formaat on vale! Sisestage ainult 10 või 13 numbrit (nt. 1234567890123).');

    }

    return('');

  }

  ValidateIsEWork(Value) {

    if(Value === 'NULL') {

      return('Trükis/e-raamat peab olema täidetud!');

    }

    return('');

  }

  ValidatePublisher(Value) {

    if(Value.length === 0) {

      return('Kirjastaja peab olema täidetud!');

    }

    return('');

  }

  ValidateSpecialPercentage(Value) {

    if(Value.search(/[^0-9]/) !== -1 || Number(Value) < 0 || Number(Value) > 100) {

      return('Eraldi kokkulepitud jaotuse formaat on vale!');

    }

    return('');

  }

  ValidateTitle(Value) {

    if(Value.length === 0) {

      return('Pealkiri peab olema täidetud!');

    }

    return('');

  }

  ValidateYearOfIssue(Value) {

    if(Value.length === 0) {

      return('Aasta peab olema täidetud!');

    }

    if(Value.search(/[^0-9]/) !== -1) {

      return('Aasta formaat on vale!');

    }

    return('');

  }

}
