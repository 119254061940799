import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	catchError,
	getApplicationFile,
	getCookie
} from './Utils';

export default class ArchiveApplicationView extends Component {

	constructor(props) {

		super(props);

		this.back = this.back.bind(this);
		this.catchError = catchError.bind(this);
		this.downloadApplication = this.downloadApplication.bind(this);
		this.endApplicationConnection = this.endApplicationConnection.bind(this);
		this.endAuthorshipsConnection = this.endAuthorshipsConnection.bind(this);
		this.endDownloadApplicationConnection = this.endDownloadApplicationConnection.bind(this);
		this.endPartyConnection = this.endPartyConnection.bind(this);
		this.endWorksConnection = this.endWorksConnection.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			application: {},
			isLoading: true,
			party: {},
			showMessage: false,
			works: []
		};

	}
	
	componentDidMount() {

		var URL = this.APIURL + 'applications/archive/' + this.props.response.params.applicationID;

		axios.get(URL, this.config).then(this.endApplicationConnection).catch(this.catchError);

	}

	render() {

		document.body.style.backgroundColor = '#BA491C';

		return(

			<div id='applicationsBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='ApplicationsBox' className='OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center' colSpan='2'>
									<h2>Avaldus Autorihüvitusfondile<br></br>nr: {this.state.application.appl_id}</h2>
								</td>
								<td>
									{this.state.application.file_id != null ? <img onClick={this.downloadApplication} className='ResizeImage' src='/img/attach.png' alt=''></img> : null}
								</td>
								<td className='TopButtons'>
									<button type='button' className='StandardButton' id='ButtonWidth' onClick={this.back}>Tagasi</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='Header'>
						<table className='MaxWidth'>
							<tbody>
								<tr>
									<td className='Label'>
										Avalduse esitaja:
									</td>
									<td className='Content'>
										{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0
											? (this.state.party.applicant_first_name == null ? '' : this.state.party.applicant_first_name + ' ' + this.state.party.applicant_family_name)
											: this.state.party.legal_name}
									</td>
								</tr>
								{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0 ? null :
									<tr>
										<td className='Label'>
											Esindaja:
										</td>
										<td className='Content'>
											{this.state.party.applicant_first_name} {this.state.party.applicant_family_name}
										</td>
									</tr>}
								<tr>
									<td className='Label'>
										Autor:
									</td>
									<td className='Content'>
										{this.state.application.author_first_name} {this.state.application.author_family_name}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										Pseudonüüm(id):
									</td>
									<td className='Content'>
										{this.state.party.pseudonym}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										Aadress, postiindeks:
									</td>
									<td className='Content'>
										{this.state.party.address_line1}
										{this.state.party.address_line1 != null && (this.state.party.address_line2 != null || this.state.party.zip_cd != null) ? ', ' : ''}
										{this.state.party.address_line2}
										{this.state.party.address_line2 != null && this.state.party.zip_cd != null ? ', ' : ''}
										{this.state.party.zip_cd}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										Telefon:
									</td>
									<td className='Content'>
										{this.state.party.phone1}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										E-post:
									</td>
									<td className='Content'>
										{this.state.party.email}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0 ? 'Isikukood:' : 'Registreerimisnumber:'}
									</td>
									<td className='Content'>
										{this.state.party.legal_cd == null || this.state.party.legal_cd.length === 0 ? this.state.party.personal_cd : this.state.party.legal_cd}
									</td>
								</tr>
								{this.state.party.individual_cd == null || this.state.party.individual_cd.length === 0 ? null :
									<tr>
										<td className='Label'>
											FIE reg. nr:
										</td>
										<td className='Content'>
											{this.state.party.individual_cd}
										</td>
									</tr>}
								<tr>
									<td className='Label'>
										IBAN:
									</td>
									<td className='Content'>
										{this.state.party.iban}
									</td>
								</tr>
								{this.state.party.bic == null || this.state.party.bic.length === 0 ? null :
									<tr>
										<td className='Label'>
											BIC:
										</td>
										<td className='Content'>
											{this.state.party.bic}
										</td>
									</tr>}
								<tr>
									<td colSpan='2'>
										<ul>
											<li>Taotlen autorihüvitust teoste laenutamise eest avalikest raamatukogudest ja reprograafilise reprodutseerimise eest vastavalt lisale.</li>
											<br></br>
											<li>Olen teadlik autorihüvituse taotlemise korrast ja kinnitan minu poolt esitatud andmete õigsust.</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
						<div>
							<div className='Column'>
								<b>Kuupäev:</b> {this.state.application.change_dtime}
							</div>
							<div className='Column'>
								<b>Allkiri:</b>
							</div>
						</div>
					</div>
					<div>
						<div id='Title'>Lisa: teoste nimekiri</div>
						<div className='InnerBox'>
							<table className='ApplicationTable'>
								<thead>
									<tr>
										<th className='Width250'>Pealkiri</th>
										<th className='WidthRemaining'>Aasta</th>
										<th className='Width120'>Autor</th>
										<th className='Width120'>Autorlus</th>
										<th className='Width60'>Sama tüüpi autoreid</th>
										<th className='Width120'>ISBN</th>
										<th className='Width120'>Kujunduse osa</th>
										<th className='Width60'>Õppe- ja teadusk.</th>
									</tr>
								</thead>
								<tbody>
									{this.state.works.map(function(work, i) {

										var Borders = {};

										Borders['style'] = {'backgroundColor': 'white', 'borderLeft': '1px solid gray', 'borderRight': '1px solid gray'};

										if(work.title != null) {

											Borders['style']['borderTop'] = '1px solid gray';

										}

										return(

											<tr className='Space' key={i}>
												<td className='Width250' {...Borders}>
													{work.work_id == null ? '' : work.title}
												</td>
												<td className='WidthRemaining' {...Borders}>
													{work.work_id == null ? '' : work.year_of_issue}
												</td>
												<td className='Width120' {...Borders}>
													{work.author_first_name} {work.author_family_name}
												</td>
												<td className='Width120' {...Borders}>
													{work.relation_name}
												</td>
												<td className='Width60' {...Borders}>
													{work.allocation}
												</td>
												<td className='Width120' {...Borders}>
													{work.work_id == null ? '' : work.isbn}
												</td>
												<td className='Width120' {...Borders}>
													{work.work_id == null ? '' : (work.formation_part_id === 5 ? 'Illustratsioone üle poole teosest' : (work.formation_part_id === 4 ? 'Illustreeritud teos' : 'Illustreerimata/ üksikute illustratsioonidega teos'))}
												</td>
												<td className='Width60' {...Borders}>
													{work.work_id == null ? '' : (work.is_academic === 'Y' ? 'Jah' : 'Ei')}
												</td>
											</tr>

										);

									}, this)}
									{this.state.works == null || this.state.works.length === 0 ? null :
										<tr style={{'borderTop': '1px solid gray'}}><td colSpan='8'></td></tr>}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

		);

	}

	back() {

    let url = this.props.router.url({ name: 'Archive' });

    this.props.router.navigate({ url });

	}

	downloadApplication() {

		this.setState({ isLoading: true });

		var URL = this.APIURL
			+ 'applications/' + this.state.application.appl_id
			+ '/' + this.state.application.party_id
			+ '/files/' + this.state.application.file_id;

		axios.get(URL, this.config).then(this.endDownloadApplicationConnection).catch(this.catchError);

	}

	endApplicationConnection(response) {

		var application = response.data.data;

		var URL = this.APIURL + 'parties/' + application.party_id;

		axios.get(URL, this.config).then(this.endPartyConnection).catch(this.catchError);

		this.setState({ application: application });

	}

	endAuthorshipsConnection(response) {

		var works = this.state.works.slice();

		var authorships = response.data.data;

		var workID;

		var match;

		for(let i = 0; i < works.length; i ++) {

			workID = works[i].work_id;

			match = false;

			for(let j = 0; j < authorships.length; j ++) {

				if(workID === authorships[j].work_id) {

					if(match) {

						works.splice(i + 1, 0, {});

						i ++;

					}

					else {

						match = true;

					}

					works[i].author_first_name = authorships[j].author_first_name;
					works[i].author_family_name = authorships[j].author_family_name;
					works[i].relation_name = authorships[j].authorship_name;
					works[i].allocation = authorships[j].allocation;
					

				}

			}

			if(!match) {

				works[i].author_first_name = '';
				works[i].author_family_name = '';
				works[i].relation_name = '';
				works[i].allocation = '';

			}

		}

		this.setState({
			isLoading: false,
			works: works
		});

	}

	endDownloadApplicationConnection(response) {

		getApplicationFile(response.data.data);

		this.setState({ isLoading: false });

	}

	endPartyConnection(response) {

		var party = response.data.data;

		var URL = this.APIURL + 'applications/' + this.props.response.params.applicationID + '/' + party.party_id + '/works?sort=year_of_issue,a&sort=title,a';

		axios.get(URL, this.config).then(this.endWorksConnection).catch(this.catchError);

		this.setState({ party: party });

	}

	endWorksConnection(response) {

		this.setState({ works: response.data.data });

		var URL = this.APIURL
			+ 'applications/' + this.state.application.appl_id
			+ '/' + this.state.party.party_id + '/authorships';

		axios.get(URL, this.config).then(this.endAuthorshipsConnection).catch(this.catchError);

	}

	setShowMessage(showMessage) {

		if(!showMessage) {

			if(this.navigateBackAfterMessage) {

				this.back();

			}

			else {

				this.setState({showMessage: false});

			}

		}

		else {

			this.setState({showMessage: true});

		}

	}

}