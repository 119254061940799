import React, { Component } from 'react';
import axios from 'axios';

export default class EditParty extends Component {

	constructor(props) {

		super(props);

		this.EndSavePartyConnection = this.EndSavePartyConnection.bind(this);
		this.SaveParty = this.SaveParty.bind(this);
		this.ToggleBIC = this.ToggleBIC.bind(this);
		this.ToggleEsitaja = this.ToggleEsitaja.bind(this);
		this.ValidateBIC = this.ValidateBIC.bind(this);
		this.ValidateEMail = this.ValidateEMail.bind(this);
		this.ValidateFamilyName = this.ValidateFamilyName.bind(this);
		this.ValidateFirstName = this.ValidateFirstName.bind(this);
		this.ValidateIBAN = this.ValidateIBAN.bind(this);
		this.ValidateIndividualCode = this.ValidateIndividualCode.bind(this);
		this.ValidateLegalName = this.ValidateLegalName.bind(this);
		this.ValidatePhone = this.ValidatePhone.bind(this);
		this.ValidateZipCode = this.ValidateZipCode.bind(this);

		var BIC = false;

		if((this.props.Party.iban.length >= 10 && this.props.Party.iban.substring(0, 2).toLowerCase() !== 'ee') || this.props.Party.bic != null) {

			BIC = true;

		}

		var Esitaja;

		if(this.props.Party.legal_cd != null) {

			Esitaja = 'jur_isik';

		}

		else if(this.props.Party.individual_cd != null) {

			Esitaja = 'fie';

		}

		else {

			Esitaja = 'fuusiline_isik';

		}

		this.state = {
			Esitaja: Esitaja,
			BIC: BIC,
		};

	}

	render() {

		var Options = {};

		if(this.props.Connecting) {

			Options['disabled'] = 'disabled';

		}

		var ConditionalFixed = {};

		var Disabled = {};

		if(this.props.Party.legal_cd != null) {

			ConditionalFixed['disabled'] = 'disabled';

		}

		else {

			Disabled['disabled'] = 'disabled';

		}

		return(

      <form onSubmit={this.SaveParty.bind(this)}>
        <table className='EditTable'>
          <tbody>
            <tr>
              <td className='EditTableNormalField'>
                ID:
              </td>
              <td className='EditTableValue'>
                {this.props.Party.party_id}
              </td>
            </tr>
            <tr>
              <td className='EditTableRequiredField'>
                Esitaja eesnimi:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='25'
                  name='FirstName'
                  defaultValue={this.props.Party.applicant_first_name}
                  maxLength='50'>
                </input>
              </td>
            </tr>
            <tr>
              <td className='EditTableRequiredField'>
                Esitaja perekonnanimi:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='25'
                  name='FamilyName'
                  defaultValue={this.props.Party.applicant_family_name}
                  maxLength='50'>
                </input>
              </td>
            </tr>
            <tr>
              <td className='EditTableNormalField'>
                Esitaja tüüp:
              </td>
              <td className='EditTableValue'>
                <select {...ConditionalFixed} onChange={this.ToggleEsitaja} name='Esitaja' value={this.state.Esitaja}>
                  <option value='fuusiline_isik'>Füüsiline isik</option>
                  <option value='fie'>FIE</option>
                  <option {...Disabled} value='jur_isik'>Jur. isik</option>
                </select>
              </td>
            </tr>
            {this.state.Esitaja === 'jur_isik' ? null :
              <tr>
                <td className='EditTableNormalField'>
                  Resident:
                </td>
                <td className='EditTableValue'>
                  <select name='Resident' defaultValue={this.state.Esitaja === 'fie' || this.props.Party.resident_country_cd === 'EE' ? 'EE' : 'Mu'}>
                    <option value='EE'>EE</option>
                    {this.state.Esitaja === 'fuusiline_isik' ? <option value='Mu'>Muu</option> : null}
                  </select>
                </td>
              </tr>}
            {this.state.Esitaja === 'jur_isik' ? null :
              <tr>
                <td className='EditTableNormalField'>
                  Isikukood:
                </td>
                <td className='EditTableValue'>
                  {this.props.Party.personal_cd}
                </td>
              </tr>}
            {this.state.Esitaja !== 'fie' ? null :
              <tr>
                <td className='EditTableRequiredField'>
                  FIE reg. nr.:
                </td>
                <td className='EditTableValue'>
                  <input
                    {...Options}
                    type='text'
                    size='12'
                    name='IndividualCode'
                    defaultValue={this.props.Party.individual_cd}
                    maxLength='8'>
                  </input>
                </td>
              </tr>}
            {this.state.Esitaja !== 'jur_isik' ? null :
              <tr>
                <td className='EditTableRequiredField'>
                  Jur. isiku nimi:
                </td>
                <td className='EditTableValue'>
                  <input
                    {...Options}
                    type='text'
                    size='25'
                    name='LegalName'
                    defaultValue={this.props.Party.legal_name}
                    maxLength='50'>
                  </input>
                </td>
              </tr>}
            {this.state.Esitaja !== 'jur_isik' ? null :
              <tr>
                <td className='EditTableNormalField'>
                  Jur. isiku reg. nr.:
                </td>
                <td className='EditTableValue'>
                  {this.props.Party.legal_cd}
                </td>
              </tr>}
            <tr>
              <td className='EditTableNormalField'>
                Parool:
              </td>
              <td className='EditTableValue'>
                {this.props.Party.password_}
              </td>
            </tr>
            <tr>
              <td className='EditTableNormalField'>
                Parooli e-post:
              </td>
              <td className='EditTableValue'>
                {this.props.Party.password_epost}
              </td>
            </tr>
            <tr>
              <td className='EditTableNormalField'>
                Tänav, maja, korter:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='25'
                  name='Address'
                  defaultValue={this.props.Party.address_line2}
                  maxLength='50'>
                </input>
              </td>
            </tr>
            <tr>
              <td className='EditTableNormalField'>
                Linn:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='25'
                  name='City'
                  defaultValue={this.props.Party.address_line1}
                  maxLength='30'>
                </input>
              </td>
            </tr>
            <tr>
              <td className='EditTableNormalField'>
                Postiindeks:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='12'
                  name='ZipCode'
                  defaultValue={this.props.Party.zip_cd}
                  maxLength='5'>
                </input>
              </td>
            </tr>
            <tr>
              <td className='EditTableRequiredField'>
                E-posti aadress:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='25'
                  name='EMail'
                  defaultValue={this.props.Party.email}
                  maxLength='50'>
                </input>
              </td>
            </tr>
            <tr>
              <td className='EditTableNormalField'>
                Telefon:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='25'
                  name='Phone'
                  defaultValue={this.props.Party.phone1}
                  maxLength='20'>
                </input>
              </td>
            </tr>
            <tr>
              <td className='EditTableRequiredField'>
                IBAN:
              </td>
              <td className='EditTableValue'>
                <input
                  {...Options}
                  type='text'
                  size='25'
                  name='IBAN'
                  defaultValue={this.props.Party.iban}
                  maxLength='50'
                  onChange={this.ToggleBIC}>
                </input>
              </td>
            </tr>
            {!this.state.BIC ? null :
              <tr>
                <td className='EditTableRequiredField'>
                  BIC:
                </td>
                <td className='EditTableValue'>
                  <input
                    {...Options}
                    type='text'
                    size='12'
                    name='BIC'
                    defaultValue={this.props.Party.bic}
                    maxLength='8'>
                  </input>
                </td>
              </tr>}
            <tr>
              <td className='EditTableNormalField' style={{'verticalAlign' : 'text-top'}}>
                Kommentaar:
              </td>
              <td className='EditTableValue'>
                <textarea
                  {...Options}
                  id='Comment'
                  name='Comment'
                  defaultValue={this.props.Party.party_comment}
                  maxLength='500'>
                </textarea>
              </td>
            </tr>
            <tr>
              <td className='NormalLabel'>
                <button {...Options} onClick={this.props.Return}>Unusta muudatused <img className='Middle' src='/img/undo.png' alt=''></img></button>
              </td>
              <td className='Content'>
                <button {...Options}  type='submit'>Salvesta muudatused <img className='Middle' src='/img/ok.png' alt=''></img></button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

		);

	}

	EndSavePartyConnection(Response) {

		this.props.ChangeConnecting(false);

		this.props.ShowAlert('Muudatused salvestatud!');

		this.props.LoadParty(this.props.Party.party_id);

		this.props.RunSearch();

		this.props.Return();

	}

	SaveParty(event) {

		event.preventDefault();

		var Resident = '';
		var LegalName = '';
		var IndividualCode = '';
		var BIC = '';

		if(event.target.Resident != null) {

			Resident = event.target.Resident.value;

		}

		if(event.target.LegalName != null) {

			LegalName = event.target.LegalName.value;

		}

		if(event.target.IndividualCode != null) {

			IndividualCode = event.target.IndividualCode.value;

		}

		if(event.target.BIC != null) {

			BIC = event.target.BIC.value;

		}

		var ErrorMessage =
			this.ValidateFirstName(event.target.FirstName.value)
			+ this.ValidateFamilyName(event.target.FamilyName.value)
			+ this.ValidateIndividualCode(IndividualCode)
			+ this.ValidateLegalName(LegalName)
			+ this.ValidateZipCode(event.target.ZipCode.value)
			+ this.ValidateEMail(event.target.EMail.value)
			+ this.ValidatePhone(event.target.Phone.value)
			+ this.ValidateIBAN(event.target.IBAN.value)
			+ this.ValidateBIC(BIC);

		if(ErrorMessage.length === 0) {

			this.props.ChangeConnecting(true);

			var URL = this.props.APIURL + 'parties/' + this.props.Party.party_id;

			var data =
				'{"applicant_first_name": "' + event.target.FirstName.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"applicant_family_name": "' + event.target.FamilyName.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"individual_cd": "' + IndividualCode.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"resident_country_cd": "' + Resident + '",'
				+ '"legal_name": "' + LegalName.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"address_line2": "' + event.target.Address.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"address_line1": "' + event.target.City.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"zip_cd": "' + event.target.ZipCode.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"email": "' + event.target.EMail.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"phone1": "' + event.target.Phone.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"iban": "' + event.target.IBAN.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"bic": "' + BIC.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"party_comment": "' + event.target.Comment.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

			var config = {
				headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
				timeout: 30000,
				auth: {username: this.props.Token},
			};

			axios.put(URL, data, config).then(this.EndSavePartyConnection).catch(this.props.catchError);
		}

		else {

			this.props.ShowAlert(ErrorMessage);

		}

	}

	ToggleBIC(event) {

		if(event.target.value.length >= 10 && event.target.value.substring(0, 2).toLowerCase() !== 'ee') {

			this.setState({BIC: true});

		}

		else {

			this.setState({BIC: false});

		}

	}

	ToggleEsitaja(event) {

		this.setState({Esitaja: event.target.value});

	}

	ValidateBIC(Value) {

		if(this.state.BIC) {

			if(Value.length === 0) {

				return('BIC peab olema täidetud!');

			}

			if(Value.search(/^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/) === -1) {

				return('BIC formaat on vale!');

			}

		}

		return('');

	}

	ValidateEMail(Value) {

		if(Value.length === 0) {

			return('E-posti aadress peab olema täidetud!');

		}

		if(Value.search(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === -1) {

			return('E-posti aadressi formaat on vale!');

		}

		return('');

	}

	ValidateFamilyName(Value) {

		if(Value.length === 0) {

			return('Esitaja perenimi peab olema täidetud!');

		}

		return('');

	}

	ValidateFirstName(Value) {

		if(Value.length === 0) {

			return('Esitaja eesnimi peab olema täidetud!');

		}

		return('');

	}

	ValidateIBAN(Value) {

		var IBAN = Value.replace(/\s+/g, '');

		if(IBAN.length === 0) {

			return('IBAN peab olema täidetud!');

		}

		if(IBAN.length > 34 || IBAN.length < 10 ||IBAN.search(/[^a-zA-Z0-9\s]/) !== -1) {

			return('IBAN formaat on vale!');

		}

		IBAN = IBAN.substring(4, IBAN.length).concat(IBAN.substring(0, 4)).toUpperCase();

		for(let i = 0; i < IBAN.length; i ++) {

			if(isNaN(IBAN[i])) {

				IBAN = IBAN.replace(IBAN[i], IBAN[i].charCodeAt(0) - 55);

			}

		}

		var checksum = IBAN.substring(0, 2);

		for (let i = 2; i < IBAN.length; i += 7) {

			var fragment = String(checksum) + IBAN.substring(i, i + 7);

			checksum = parseInt(fragment) % 97;

		}

		if(checksum !== 1) {

			return('IBAN formaat on vale!');

		}

		return('');

	}

	ValidateIndividualCode(Value) {

		if(this.state.Esitaja === 'fie' && Value.length === 0) {

			return('FIE reg. nr. peab olema täidetud!');

		}

		if(this.state.Esitaja === 'fie' && Value.length > 0 && (Value.search(/[^0-9]/) !== -1 || Value.length !== 8)) {

			return('FIE reg. nr. formaat on vale!');

		}

		return('');

	}

	ValidateLegalName(Value) {

		if(this.state.Esitaja === 'jur_isik' && Value.length === 0) {

			return('Jur. isiku nimi peab olema täidetud!');

		}

		return('');

	}

	ValidatePhone(Value) {

		var Telefon = Value.replace(/\s/g, '');

		if(Telefon.length > 0 && (Telefon.length < 4 || Telefon.search(/[^0-9+-\\(\\)]/) !== -1)) {

			return('Telefoni formaat on vale!');

		}

		return('');

	}

	ValidateZipCode(Value) {

		if(Value.length > 0 && Value.search(/[^0-9]/) !== -1) {

			return('Postiindeksi formaat on vale!');

		}

		return('');

	}

}
