import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
  getErrorMessage,
  setCookie
} from './Utils';

export default class Login extends Component {

  constructor(props) {

    super(props);

    this.catchError = this.catchError.bind(this);
    this.endLoginConnection = this.endLoginConnection.bind(this);
    this.endVersionConnection = this.endVersionConnection.bind(this);
    this.finishLogin = this.finishLogin.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleRecoverPassword = this.handleRecoverPassword.bind(this);
    this.setShowMessage = this.setShowMessage.bind(this);

    this.APIURL = require('./../config/config.js').APIURL;
    this.finishLoginAfterMessage = false;
    this.message = [];
    this.state = {
      isLoading: false,
      showMessage: false
    };
    this.version = '4.2.5';

  }

  render() {

    document.body.style.backgroundColor = '#E7D074';

    return(

      <div className='smallBoxWrapper'>
        <LoadingOverlay isLoading={this.state.isLoading}/>
        <ShowMessage
          showMessage={this.state.showMessage}
          message={this.message}
          setShowMessage={this.setShowMessage}
        />
        <div className='smallBox OuterBox'>
          <table className='MaxWidth'>
            <tbody>
              <tr>
                <td className='Logo'>
                  <img src='/img/logo.gif' alt=''></img>
                </td>
                <td className='Center'>
                  <h2>Sisselogimine</h2>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='InnerBox'>
            <form onSubmit={this.handleLogin} autoComplete="false">
              <table className='MaxWidth'>
                <tbody>
                  <tr>
                    <td className='NormalLabel'>
                      Isikukood / Registrikood:
                    </td>
                    <td>
                      <input
                        autoFocus 
                        className='MaxWidth'
                        type='text'
                        name='username'
                        maxLength='20'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='NormalLabel'>
                      Parool:
                    </td>
                    <td>
                      <input
                        className='MaxWidth'
                        type='password'
                        name='password'
                        maxLength='75'>
                      </input>
                    </td>
                  </tr>
                  <tr>
                    <td className='textRight'>
                      <button className='StandardButton' type='button' onClick={this.handleRecoverPassword}>Parooli taastamine</button>
                    </td>
                    <td>
                      <button className='StandardButton' type='submit'>Logi sisse</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
        <div id='Version'>Versioon {this.version}</div>
      </div>

    );

  }

  catchError(error) {

    this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

    this.setState({
      isLoading: false,
      showMessage: true
    });

  }

  endLoginConnection(response) {

    this.loginData = response.data.data;

    var URL = this.APIURL + 'version';

    axios.get(URL, this.config).then(this.endVersionConnection).catch(this.catchError);

  }

  endVersionConnection(response) {

    var version = response.data.data.ui_version;

    if(version != null && version !== 'dev-0.0.0' && version !== this.version) {

      this.message.push([
        {
          value: 'Veebilehe versioon on aegunud!',
          align: 'center'
        },
        {
          value: 'Palun lae veebileht uuesti!',
          align: 'center'
        },
        {
          value: 'Enamikel veeblehitsejatel: Ctrl+F5',
          align: 'center'
        }
      ]);

      this.finishLoginAfterMessage = true;

      this.setState({ showMessage: true });

    }

    else {

      this.finishLogin();

    }

  }

  finishLogin() {

    setCookie('AHFLevel', this.loginData.user_level, 4);
    setCookie('AHFToken', this.loginData.token, 4);
    setCookie('AHFUser', this.loginData.user_id, 4);

    let url = this.props.router.url({ name: 'Applications' });

    this.props.router.navigate({ url });

  }

  handleLogin(event) {

    event.preventDefault();

    if(event.target.username.value.length === 0 || event.target.password.value.length === 0) {

      this.message.push([{value: 'Kasutajanimi ja parool peavad olema täidetud!', align: 'center'}]);

      this.setState({ showMessage: true });

    }

    else {

      this.setState({ isLoading: true });

      var URL = this.APIURL + 'login';

      var config = {
        headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
        timeout: 30000,
        auth: {
          username: event.target.username.value,
          password: event.target.password.value
        }
      };

      axios.get(URL, config).then(this.endLoginConnection).catch(this.catchError);

    }

  }

  handleRecoverPassword() {

    let url = this.props.router.url({ name: 'Recover' });

    this.props.router.navigate({ url });

  }

  setShowMessage(showMessage) {

    if(!showMessage && this.finishLoginAfterMessage) {

      this.finishLoginAfterMessage = false

      this.finishLogin();

    }

    else {

      this.setState({showMessage: showMessage});

    }

  }

}