import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	getCookie,
	getErrorMessage,
	setCookie
} from './Utils';

export default class Reports extends Component {

	constructor(props) {

		super(props);

		this.catchError = this.catchError.bind(this);
		this.logOut = this.logOut.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.CheckStatus = this.CheckStatus.bind(this);
		this.EndGetReportConnection = this.EndGetReportConnection.bind(this);
		this.EndStartReportConnection = this.EndStartReportConnection.bind(this);
		this.EndStatusConnection = this.EndStatusConnection.bind(this);
		this.GetReport = this.GetReport.bind(this);
		this.HandleGetApplications = this.HandleGetApplications.bind(this);
		this.HandleGetBankSEPA = this.HandleGetBankSEPA.bind(this);
		this.HandleGetBankXML = this.HandleGetBankXML.bind(this);
		this.HandleGetCalculate = this.HandleGetCalculate.bind(this);
		this.HandleGetEMTATSD = this.HandleGetEMTATSD.bind(this);
		this.HandleGetEMTAXML = this.HandleGetEMTAXML.bind(this);
		this.HandleGetHeir = this.HandleGetHeir.bind(this);
		this.HandleGetHomepage = this.HandleGetHomepage.bind(this);
		this.HandleGetPott = this.HandleGetPott.bind(this);
		this.HandleGetReminders = this.HandleGetReminders.bind(this);
		this.HandleGetReproCalculate = this.HandleGetReproCalculate.bind(this);
		this.HandleStartApplications = this.HandleStartApplications.bind(this);
		this.HandleStartBankSEPA = this.HandleStartBankSEPA.bind(this);
		this.HandleStartBankXML = this.HandleStartBankXML.bind(this);
		this.HandleStartCalculate = this.HandleStartCalculate.bind(this);
		this.HandleStartEMTATSD = this.HandleStartEMTATSD.bind(this);
		this.HandleStartEMTAXML = this.HandleStartEMTAXML.bind(this);
		this.HandleStartHeir = this.HandleStartHeir.bind(this);
		this.HandleStartHomepage = this.HandleStartHomepage.bind(this);
		this.HandleStartPott = this.HandleStartPott.bind(this);
		this.HandleStartReminders = this.HandleStartReminders.bind(this);
		this.HandleStartReproCalculate = this.HandleStartReproCalculate.bind(this);
		this.StartReport = this.StartReport.bind(this);
		this.ToggleCompare = this.ToggleCompare.bind(this);
		this.ToggleReproCompare = this.ToggleReproCompare.bind(this);

		var Status = {
			applications: 'Puudub',
			calculate: 'Puudub',
			banksepa: 'Puudub',
			bankxml: 'Puudub',
			emtatsd: 'Puudub',
			emtaxml: 'Puudub',
			heir: 'Puudub',
			homepage: 'Puudub',
			pott: 'Puudub',
			reminders: 'Puudub',
			reprocalculate: 'Puudub'
		};

		var Timestamp = {
			applications: '',
			calculate: '',
			banksepa: '',
			bankxml: '',
			emtatsd: '',
			emtaxml: '',
			heir: '',
			homepage: '',
			pott: '',
			reminders: '',
			reprocalculate: ''
		};

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			isLoading: false,
			showMessage: false,
			Compare: 'N',
			FileSelected: false,
			Progress: 0,
			ReproCompare: 'N',
			Status: Status,
			Timestamp: Timestamp,
		};

		this.KeepChecking = true;

    this.yearOptions = [];

    let today = new Date();

    for(let i = 2016; i <= today.getFullYear(); i ++) {

      this.yearOptions.push(i);

    }

	}

  componentDidMount() {

		this.CheckStatus();

  }

	render() {

		document.body.style.backgroundColor = '#BA491C';

		var Options = {};

    if(this.state.isLoading) {

        Options['disabled'] = 'disabled';

    }

		var ApplicationsOptions = {};

		if(this.state.Status['applications'] !== 'Lõpetatud') {

			ApplicationsOptions['disabled'] = 'disabled';

		}

		var CalculateOptions = {};

		if(this.state.Status['calculate'] !== 'Lõpetatud') {

			CalculateOptions['disabled'] = 'disabled';

		}

		var BankSEPAOptions = {};

		if(this.state.Status['banksepa'] !== 'Lõpetatud') {

			BankSEPAOptions['disabled'] = 'disabled';

		}

		var BankXMLOptions = {};

		if(this.state.Status['bankxml'] !== 'Lõpetatud') {

			BankXMLOptions['disabled'] = 'disabled';

		}

		var EMTATSDOptions = {};

		if(this.state.Status['emtatsd'] !== 'Lõpetatud') {

			EMTATSDOptions['disabled'] = 'disabled';

		}

		var EMTAXMLOptions = {};

		if(this.state.Status['emtaxml'] !== 'Lõpetatud') {

			EMTAXMLOptions['disabled'] = 'disabled';

		}

		var HeirOptions = {};

		if(this.state.Status['heir'] !== 'Lõpetatud') {

			HeirOptions['disabled'] = 'disabled';

		}

		var HomepageOptions = {};

		if(this.state.Status['homepage'] !== 'Lõpetatud') {

			HomepageOptions['disabled'] = 'disabled';

		}

		var PottOptions = {};

		if(this.state.Status['pott'] !== 'Lõpetatud') {

			PottOptions['disabled'] = 'disabled';

		}

		var RemindersOptions = {};

		if(this.state.Status['reminders'] !== 'Lõpetatud') {

			RemindersOptions['disabled'] = 'disabled';

		}

		var ReproCalculateOptions = {};

		if(this.state.Status['reprocalculate'] !== 'Lõpetatud') {

			ReproCalculateOptions['disabled'] = 'disabled';

		}

		return(

			<div className='largeBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='Tabs'>
					<Link
						className='unselectedTab'
						name="Applications">
						Laekunud avaldused
					</Link>
					<Link
						className='unselectedTab'
						name="AdministrationTool">
						Administreerimine
					</Link>
					<Link
						className='unselectedTab'
						name="Archive">
						Arhiiv
					</Link>
					<div id='SelectedTab'>Raportid<p></p></div>
					<Link
						className='unselectedTab'
						name="UploadFile">
						Failide üleslaadimine
					</Link>
				</div>
				<div id='AdminApplicationsBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Raportid</h2>
								</td>
								<td className='TopButtons'>
									<button {...Options} type='button' className='StandardButton' onClick={this.logOut}>Logi välja</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
            <form onSubmit={this.HandleStartReminders.bind(this)}>
              <table id='ApplicationsTable'>
                <thead>
                  <tr>
                    <th className='Width200'>Raport</th>
                    <th className='Width80'>Staatus</th>
                    <th className='Width170'>Aeg</th>
                    <th className='WidthRemaining'>Koostamine</th>
                    <th className='Width100'>Allalaadimine</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='Width200'>Meeldetuletused</td>
                    <td className='Width80'>{this.state.Status['reminders']}</td>
                    <td className='Width170'>{this.state.Timestamp['reminders']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='RemindersYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...RemindersOptions} type='button' className='StandardButton' onClick={this.HandleGetReminders}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartApplications.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>Avalduste kontroll</td>
                    <td className='Width80'>{this.state.Status['applications']}</td>
                    <td className='Width170'>{this.state.Timestamp['applications']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='ApplicationsYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      {' '}
                      <select {...Options} name='Status'>
                        <option value='NULL'>Vali staatus</option>
                        <option value='All'>Kõik staatused</option>
                        <option value='C'>Kinnitatud</option>
                        <option value='E'>Valmis</option>
                        <option value='L'>Poolik</option>
                        <option value='X'>Tühistatud</option>
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...ApplicationsOptions} type='button' className='StandardButton' onClick={this.HandleGetApplications}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartCalculate.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>Laenutushüvitis</td>
                    <td className='Width80'>{this.state.Status['calculate']}</td>
                    <td className='Width170'>{this.state.Timestamp['calculate']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Arvuta</button>
                      <select {...Options} name='CalculateYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      <input
                        {...Options}
                        className='Lower'
                        type="checkbox"
                        onChange={this.ToggleCompare}>
                      </input>
                      <div id='ReportsText'>Lisa eelmise aasta võrdlus</div>
                    </td>
                    <td className='Width100'><button {...Options} {...CalculateOptions} type='button' className='StandardButton' onClick={this.HandleGetCalculate}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartReproCalculate.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>Paljundushüvitis</td>
                    <td className='Width80'>{this.state.Status['reprocalculate']}</td>
                    <td className='Width170'>{this.state.Timestamp['reprocalculate']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Arvuta</button>
                      <select {...Options} name='ReproCalculateYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      <input
                        {...Options}
                        className='Lower'
                        type="checkbox"
                        onChange={this.ToggleReproCompare}>
                      </input>
                      <div id='ReportsText'>Lisa eelmise aasta võrdlus</div>
                    </td>
                    <td className='Width100'><button {...Options} {...ReproCalculateOptions} type='button' className='StandardButton' onClick={this.HandleGetReproCalculate}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartBankSEPA.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>Pangafail XML</td>
                    <td className='Width80'>{this.state.Status['banksepa']}</td>
                    <td className='Width170'>{this.state.Timestamp['banksepa']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='BankSEPAYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      {' '}
                      <select {...Options} name='BankSEPAChoice'>
                        <option value='NULL'>Vali hüvitis</option>
                        <option value='LH'>Laenutushüvitis</option>
                        <option value='RH'>Paljundushüvitis</option>
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...BankSEPAOptions} type='button' className='StandardButton' onClick={this.HandleGetBankSEPA}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartBankXML.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>Pangafaili eelvaade</td>
                    <td className='Width80'>{this.state.Status['bankxml']}</td>
                    <td className='Width170'>{this.state.Timestamp['bankxml']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='BankXMLYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      {' '}
                      <select {...Options} name='BankXMLChoice'>
                        <option value='NULL'>Vali hüvitis</option>
                        <option value='LH'>Laenutushüvitis</option>
                        <option value='RH'>Paljundushüvitis</option>
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...BankXMLOptions} type='button' className='StandardButton' onClick={this.HandleGetBankXML}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartEMTATSD.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>EMTA XML</td>
                    <td className='Width80'>{this.state.Status['emtatsd']}</td>
                    <td className='Width170'>{this.state.Timestamp['emtatsd']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='EMTATSDYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      {' '}
                      <select {...Options} name='EMTATSDMonth'>
                        <option value='NULL'>Vali kuu</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                        <option value='11'>11</option>
                        <option value='12'>12</option>
                      </select>
                      {' '}
                      <select {...Options} name='EMTATSDChoice'>
                        <option value='NULL'>Vali hüvitis</option>
                        <option value='LH'>Laenutushüvitis</option>
                        <option value='RH'>Paljundushüvitis</option>
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...EMTATSDOptions} type='button' className='StandardButton' onClick={this.HandleGetEMTATSD}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartEMTAXML.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>EMTA faili eelvaade</td>
                    <td className='Width80'>{this.state.Status['emtaxml']}</td>
                    <td className='Width170'>{this.state.Timestamp['emtaxml']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='EMTAXMLYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      {' '}
                      <select {...Options} name='EMTAXMLMonth'>
                        <option value='NULL'>Vali kuu</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                        <option value='11'>11</option>
                        <option value='12'>12</option>
                      </select>
                      {' '}
                      <select {...Options} name='EMTAXMLChoice'>
                        <option value='NULL'>Vali hüvitis</option>
                        <option value='LH'>Laenutushüvitis</option>
                        <option value='RH'>Paljundushüvitis</option>
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...EMTAXMLOptions} type='button' className='StandardButton' onClick={this.HandleGetEMTAXML}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartHomepage.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>Nimekiri kodulehe jaoks</td>
                    <td className='Width80'>{this.state.Status['homepage']}</td>
                    <td className='Width170'>{this.state.Timestamp['homepage']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='HomepageYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      {' '}
                      <select {...Options} name='HomepageChoice'>
                        <option value='NULL'>Vali hüvitis</option>
                        <option value='LH'>Laenutushüvitis</option>
                        <option value='RH'>Paljundushüvitis</option>
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...HomepageOptions} type='button' className='StandardButton' onClick={this.HandleGetHomepage}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartPott.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>POTT analüüs</td>
                    <td className='Width80'>{this.state.Status['pott']}</td>
                    <td className='Width170'>{this.state.Timestamp['pott']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='PottYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                      {' '}
                      <select {...Options} name='PottChoice'>
                        <option value='NULL'>Vali hüvitis</option>
                        <option value='LH'>Laenutushüvitis</option>
                        <option value='RH'>Paljundushüvitis</option>
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...PottOptions} type='button' className='StandardButton' onClick={this.HandleGetPott}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form onSubmit={this.HandleStartHeir.bind(this)}>
              <table id='ApplicationsTable'>
                <tbody>
                  <tr>
                    <td className='Width200'>Lahkunud</td>
                    <td className='Width80'>{this.state.Status['heir']}</td>
                    <td className='Width170'>{this.state.Timestamp['heir']}</td>
                    <td className='WidthRemaining'>
                      <button {...Options} type='submit' className='ReportsButton'>Koosta</button>
                      <select {...Options} name='HeirYear'>
                        <option value='NULL'>Vali aasta</option>
                        {this.yearOptions.map(function(year, index) {
                          return (
                            <option key={index} value={year}>{year}</option>
                          );
                        }, this)}
                      </select>
                    </td>
                    <td className='Width100'><button {...Options} {...HeirOptions} type='button' className='StandardButton' onClick={this.HandleGetHeir}>Lae alla</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
					</div>
				</div>
			</div>

		);

	}

	catchError(error) {

		if(window.location.pathname === '/raportid') {

			this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

			this.setState({
				isLoading: false,
				showMessage: true
			});

		}

	}

	CheckStatus() {

		if(this.KeepChecking) {

			var URL = this.APIURL + 'tasks';

			axios.get(URL, this.config).then(this.EndStatusConnection).catch(this.catchError);

		}

	}

	EndGetReportConnection(Response) {

		this.setState({ isLoading: false });

		window.location.assign(Response.data.data.reportlink);

	}

	EndStartReportConnection(Response) {

		this.message.push([{
			value: 'Aruannet koostatakse...',
			align: 'center'
		}]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	EndStatusConnection(Response) {

		if(window.location.pathname === '/raportid') {

			var Status = {
        applications: 'Puudub',
        calculate: 'Puudub',
        banksepa: 'Puudub',
        bankxml: 'Puudub',
        emtatsd: 'Puudub',
        emtaxml: 'Puudub',
        heir: 'Puudub',
        homepage: 'Puudub',
        pott: 'Puudub',
        reminders: 'Puudub',
        reprocalculate: 'Puudub'
			};

			var Timestamp = {
        applications: '',
        calculate: '',
        banksepa: '',
        bankxml: '',
        emtatsd: '',
        emtaxml: '',
        heir: '',
        homepage: '',
        pott: '',
        reminders: '',
        reprocalculate: ''
			};

			for(var i = 0; i < Response.data.data.tasklist.length; i ++) {

				if(Timestamp[Response.data.data.tasklist[i].report_type] === '' || Timestamp[Response.data.data.tasklist[i].report_type] <  Response.data.data.tasklist[i].timestamp) {

					Timestamp[Response.data.data.tasklist[i].report_type] = Response.data.data.tasklist[i].timestamp;

					if(Response.data.data.tasklist[i].task_status === 'finished') {

						Status[Response.data.data.tasklist[i].report_type] = 'Lõpetatud';

					}

					else if(Response.data.data.tasklist[i].task_status === 'started') {

						Status[Response.data.data.tasklist[i].report_type] = 'Arvutamisel';

					}

					else if(Response.data.data.tasklist[i].task_status === 'queued') {

						Status[Response.data.data.tasklist[i].report_type] = 'Järjekorras';

					}

					else if(Response.data.data.tasklist[i].task_status === 'failed') {

						Status[Response.data.data.tasklist[i].report_type] = 'Ebaõnnestus';

					}

					else if(Response.data.data.tasklist[i].task_status === 'deferred') {

						Status[Response.data.data.tasklist[i].report_type] = 'Edasilükatud';

					}

					else {

						Status[Response.data.data.tasklist[i].report_type] = 'Puudub';

					}

				}

			}

			this.setState({
				Status: Status,
				Timestamp: Timestamp
			});

			setTimeout(this.CheckStatus, 5000);

		}

	}

	GetReport(URLName) {

		this.setState({ isLoading: true });

		var URL = this.APIURL + 'reports/' + URLName;

		axios.get(URL, this.config).then(this.EndGetReportConnection).catch(this.catchError);

	}

	HandleGetApplications() {

		this.GetReport('applications');

	}

	HandleGetBankSEPA() {

		this.GetReport('banksepa');

	}

	HandleGetBankXML() {

		this.GetReport('bankxml');

	}

	HandleGetCalculate() {

		this.GetReport('calculate');

	}

	HandleGetEMTATSD() {

		this.GetReport('emtatsd');

	}

	HandleGetEMTAXML() {

		this.GetReport('emtaxml');

	}

	HandleGetHeir() {

		this.GetReport('heir');

	}

	HandleGetHomepage() {

		this.GetReport('homepage');

	}

	HandleGetPott() {

		this.GetReport('pott');

	}

	HandleGetReminders() {

		this.GetReport('reminders');

	}

	HandleGetReproCalculate() {

		this.GetReport('reprocalculate');

	}

	HandleStartApplications(event) {

    event.preventDefault();

		if(event.target.Status.value === 'NULL') {

			this.message.push([{
				value: 'Vali staatus!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.ApplicationsYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('applications', '"period": "' + event.target.ApplicationsYear.value + '","status": "' + event.target.Status.value + '"');

		}

	}

	HandleStartBankSEPA(event) {

    event.preventDefault();

		if(event.target.BankSEPAYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.BankSEPAChoice.value === 'NULL') {

			this.message.push([{
				value: 'Vali hüvitis!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('banksepa', '"period": "' + event.target.BankSEPAYear.value + '","choice": "' + event.target.BankSEPAChoice.value + '"');

		}

	}

	HandleStartBankXML(event) {

    event.preventDefault();

		if(event.target.BankXMLYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.BankXMLChoice.value === 'NULL') {

			this.message.push([{
				value: 'Vali hüvitis!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('bankxml', '"period": "' + event.target.BankXMLYear.value + '","choice": "' + event.target.BankXMLChoice.value + '"');

		}

	}

	HandleStartCalculate(event) {

    event.preventDefault();

		if(event.target.CalculateYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('calculate', '"period": "' + event.target.CalculateYear.value + '","compare": "' + this.state.Compare + '"');

		}

	}

	HandleStartEMTATSD(event) {

    event.preventDefault();

		if(event.target.EMTATSDYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.EMTATSDMonth.value === 'NULL') {

			this.message.push([{
				value: 'Vali kuu!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.EMTATSDChoice.value === 'NULL') {

			this.message.push([{
				value: 'Vali hüvitis!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('emtatsd', '"tm_aasta": "' + event.target.EMTATSDYear.value + '","tm_kuu": "' + event.target.EMTATSDMonth.value + '","choice": "' + event.target.EMTATSDChoice.value + '"');

		}

	}

	HandleStartEMTAXML(event) {

    event.preventDefault();

		if(event.target.EMTAXMLYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.EMTAXMLMonth.value === 'NULL') {

			this.message.push([{
				value: 'Vali kuu!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.EMTAXMLChoice.value === 'NULL') {

			this.message.push([{
				value: 'Vali hüvitis!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('emtaxml', '"tm_aasta": "' + event.target.EMTAXMLYear.value + '","tm_kuu": "' + event.target.EMTAXMLMonth.value + '","choice": "' + event.target.EMTAXMLChoice.value + '"');

		}

	}

	HandleStartHeir(event) {

    event.preventDefault();

		if(event.target.HeirYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('heir', '"period": "' + event.target.HeirYear.value + '","choice": "LH"');

		}

	}

	HandleStartHomepage(event) {

    event.preventDefault();

		if(event.target.HomepageYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.HomepageChoice.value === 'NULL') {

			this.message.push([{
				value: 'Vali hüvitis!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('homepage', '"period": "' + event.target.HomepageYear.value + '","choice": "' + event.target.HomepageChoice.value + '"');

		}

	}

	HandleStartPott(event) {

    event.preventDefault();

		if(event.target.PottYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else if(event.target.PottChoice.value === 'NULL') {

			this.message.push([{
				value: 'Vali hüvitis!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('pott', '"period": "' + event.target.PottYear.value + '","choice": "' + event.target.PottChoice.value + '"');

		}

	}

	HandleStartReminders(event) {

    event.preventDefault();

		if(event.target.RemindersYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('reminders', '"period": "' + event.target.RemindersYear.value + '"');

		}

	}

	HandleStartReproCalculate(event) {

    event.preventDefault();

		if(event.target.ReproCalculateYear.value === 'NULL') {

			this.message.push([{
				value: 'Vali aasta!',
				align: 'center'
			}]);

			this.setState({ showMessage: true });

		}

		else {

			this.StartReport('reprocalculate', '"compare": "' + this.state.ReproCompare + '","period": "' + event.target.ReproCalculateYear.value + '"');

		}

	}

	logOut() {

		setCookie('AHFLevel', getCookie('AHFLevel'), -1);
		setCookie('AHFToken', getCookie('AHFToken'), -1);
		setCookie('AHFUser', getCookie('AHFToken'), -1);

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

	}

	setShowMessage(showMessage) {

		this.setState({showMessage: showMessage});

	}

	StartReport(URLName, Data) {

		this.setState({ isLoading: true });

		var URL = this.APIURL + 'reports/' + URLName;

		var data = '{' + Data + '}';

		axios.post(URL, data, this.config).then(this.EndStartReportConnection).catch(this.catchError);

	}

	ToggleCompare() {

		if(this.state.Compare === 'N') {

			this.setState({Compare: 'Y'});

		}

		else {
			
			this.setState({Compare: 'N'});

		}

	}

	ToggleReproCompare() {

		if(this.state.ReproCompare === 'N') {

			this.setState({ReproCompare: 'Y'});

		}

		else {
			
			this.setState({ReproCompare: 'N'});

		}

	}

}
