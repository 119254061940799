import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	catchError,
	getCookie
} from './Utils';

export default class EditAuthor extends Component {

	constructor(props) {

		super(props);

		this.back = this.back.bind(this);
		this.catchError = catchError.bind(this);
		this.endApplicationConnection = this.endApplicationConnection.bind(this);
		this.endSubmitConnection = this.endSubmitConnection.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleIsActive = this.toggleIsActive.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);
		this.ValidateAuthorFamilyName = this.ValidateAuthorFamilyName.bind(this);
		this.ValidateAuthorFirstName = this.ValidateAuthorFirstName.bind(this);
		this.ValidatePublicAuthor = this.ValidatePublicAuthor.bind(this);

    this.AHFLevel = getCookie('AHFLevel');
		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
    this.isActive = '1';
		this.message = [];
		this.state = {
			application: [],
      checkboxIsActive: true,
			isLoading: false,
			showMessage: false
		};

	}
	
	componentDidMount() {

		this.setState({ isLoading: true });

		var URL = this.APIURL + 'applications/' + this.props.response.params.applicationID;

		axios.get(URL, this.config).then(this.endApplicationConnection).catch(this.catchError);

	}

	render() {

		if(getCookie('AHFLevel') === '2') {

			document.body.style.backgroundColor = '#BA491C';

		}

		else {

			document.body.style.backgroundColor = '#E7D074';

		}

		return(

			<div className='mediumBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div className='mediumBox OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Autori andmete muutmine</h2>
									<h2>avaldusel nr. {this.props.response.params.applicationID}</h2>
								</td>
								<td className='TopButtons'>
									<button type='button' className='StandardButton' id='ButtonWidth' onClick={this.back}>Tagasi</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
						<form onSubmit={this.handleSubmit}>
							<table className='MaxWidth'>
								<tbody>
									<tr>
										<td className='RequiredField'>
											Autori eesnimi (või -nimed):
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='AuthorFirstName'
												maxLength='50'
												defaultValue={this.state.application.author_first_name}>
											</input>
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Autori perekonnanimi:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='AuthorFamilyName'
												maxLength='50'
												defaultValue={this.state.application.author_family_name}>
											</input>
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Avalikustatav autorinimi:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='PublicAuthor'
												maxLength='50'
												defaultValue={this.state.application.public_author}>
											</input>
										</td>
									</tr>
                  {this.AHFLevel === '2' && this.state.application.applicant_role_cd === 'I' ?
                    <tr>
                      <td className='NormalField'>
                        Aktiivne:
                      </td>
                      <td>
                        <input
                          name='checkboxIsActive'
                          checked={this.state.checkboxIsActive}
                          className='Lower'
                          type="checkbox"
                          onChange={this.toggleIsActive}>
                        </input>
                      </td>
                    </tr>
                    : null}
									<tr>
										<td className='Center' colSpan='2'>
											<button className='StandardButton' type='submit'>Salvesta muudatused</button>
										</td>
									</tr>
								</tbody>
							</table>
						</form>
					</div>
				</div>
			</div>

		);

	}

	back() {

    let url = this.props.router.url({
			name: 'EditApplication',
			params: { applicationID: this.props.response.params.applicationID }
    });

    this.props.router.navigate({ url });

	}

	endApplicationConnection(response) {

    this.isActive = response.data.data.lifecycle_cd || '1';

		this.setState({
			application: response.data.data,
      checkboxIsActive: (this.isActive === '1'),
			isLoading: false
		});

	}

	endSubmitConnection(response) {

		this.message.push([{
			value: 'Muudatused salvestatud!',
			align: 'center'
		}]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	handleSubmit(event) {

		event.preventDefault();

		var errorMessage = [];

		var message = this.ValidateAuthorFirstName(event.target.AuthorFirstName.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateAuthorFamilyName(event.target.AuthorFamilyName.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidatePublicAuthor(event.target.PublicAuthor.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		if(errorMessage.length === 0) {

			this.setState({ isLoading: true });

			var data =
				'{"author_first_name": "' + event.target.AuthorFirstName.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"author_family_name": "' + event.target.AuthorFamilyName.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"public_author": "' + event.target.PublicAuthor.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
        + '"lifecycle_cd": "' + this.isActive + '"}';

			var URL = this.APIURL + 'applications/' + this.state.application.appl_id;

			axios.put(URL, data, this.config).then(this.endSubmitConnection).catch(this.catchError);

		}

		else {

			this.message.push(errorMessage);

			this.setState({ showMessage: true });

		}

	}

	setShowMessage(showMessage) {

		if(!showMessage) {

			if(this.navigateBackAfterMessage) {

        let url = this.props.router.url({ name: 'Applications' });

        this.props.router.navigate({ url });

			}

			else {

				this.setState({showMessage: false});

			}

		}

		else {

			this.setState({showMessage: true});

		}

	}

  toggleIsActive() {

    if(this.isActive === '4') {

      this.isActive = '1';

      this.setState({checkboxIsActive: true});

    }

    else {

      this.isActive = '4';

      this.setState({checkboxIsActive: false});

    }

  }

	ValidateAuthorFamilyName(Value) {

		if(Value.length === 0) {

			return('Autori perenimi peab olema täidetud!');

		}

		return('');

	}

	ValidateAuthorFirstName(Value) {

		if(Value.length === 0) {

			return('Autori eesnimi peab olema täidetud!');

		}

		return('');

	}

	ValidatePublicAuthor(Value) {

		if(Value.length === 0) {

			return('Avalikustatav autorinimi peab olema täidetud!');

		}

		return('');

	}

}