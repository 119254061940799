import { prepareRoutes } from "@curi/router";

import AdminApplications from './components/AdminApplications';
import AdminEditApplication from './components/AdminEditApplication';
import AdministrationTool from './components/AdministrationTool';
import ApplicantView from './components/ApplicantView';
import ApplicationView from './components/ApplicationView';
import Archive from './components/Archive';
import ArchiveApplicationView from './components/ArchiveApplicationView';
import Login from './components/Login';
import EditApplicant from './components/EditApplicant';
import EditAuthor from './components/EditAuthor';
import EditPayee from './components/EditPayee';
import EditWork from './components/EditWork';
import MemberApplications from './components/MemberApplications';
import MemberEditApplication from './components/MemberEditApplication';
import NewPassword from './components/NewPassword';
import NewWork from './components/NewWork';
import NotFound from './components/NotFound';
import PublisherApplication from './components/PublisherApplication';
import Recover from './components/Recover';
import Register from './components/Register';
import Reports from './components/Reports';
import UploadApplication from './components/UploadApplication';
import UploadFile from './components/UploadFile';
import {
  getCookie,
  setCookie
} from './components/Utils';

export default prepareRoutes([
  {
    name: "Applications",
    path: "avaldused",
    respond() {
      if(getCookie('AHFToken') !== '') {
        if(getCookie('AHFLevel') === '2') {
          return { body: AdminApplications };
        }
        else if(getCookie('AHFLevel') === '1' && getCookie('AHFUser') != null) {
          return { body: MemberApplications };
        }
        else {
          setCookie('AHFLevel', getCookie('AHFLevel'), -1);
          setCookie('AHFToken', getCookie('AHFToken'), -1);
          setCookie('AHFUser', getCookie('AHFUser'), -1);
        }
      }
      return {
        redirect: { name: "Login" }
      };
    }
  },
  {
    name: "AdministrationTool",
    path: "administreerimine",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '2') {
        return { body: AdministrationTool };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "ApplicantView",
    path: "esitaja/:partyID",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '2') {
        return { body: ApplicantView };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "ApplicationView",
    path: "avaldus/:applicationID/valmis",
    respond() {
      if(getCookie('AHFToken') !== '' && (getCookie('AHFLevel') === '2' || getCookie('AHFLevel') === '1')) {
        return { body: ApplicationView };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "Archive",
    path: "arhiiv",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '2') {
        return { body: Archive };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "ArchiveApplicationView",
    path: "arhiiv/:applicationID",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '2') {
        return { body: ArchiveApplicationView };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "NewPassword",
    path: "uus_parool",
    respond() {
      setCookie('AHFLevel', getCookie('AHFLevel'), -1);
      setCookie('AHFToken', getCookie('AHFToken'), -1);
      setCookie('AHFUser', getCookie('AHFUser'), -1);
      return { body: NewPassword };
    }
  },
  {
    name: "EditApplicant",
    path: "esitaja/:partyID/muutmine",
    respond() {
      if(getCookie('AHFToken') !== '' && (getCookie('AHFLevel') === '2' || getCookie('AHFLevel') === '1')) {
        return { body: EditApplicant };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "EditApplication",
    path: "avaldus/:applicationID/muutmine",
    respond() {
      if(getCookie('AHFToken') !== '') {
        if(getCookie('AHFLevel') === '2') {
          return { body: AdminEditApplication };
        }
        else if(getCookie('AHFLevel') === '1') {
          return { body: MemberEditApplication };
        }
        else {
          setCookie('AHFLevel', getCookie('AHFLevel'), -1);
          setCookie('AHFToken', getCookie('AHFToken'), -1);
          setCookie('AHFUser', getCookie('AHFUser'), -1);
        }
      }
      return {
        redirect: { name: "Login" }
      };
    }
  },
  {
    name: "EditAuthor",
    path: "avaldus/:applicationID/autor",
    respond() {
      if(getCookie('AHFToken') !== '' && (getCookie('AHFLevel') === '2' || getCookie('AHFLevel') === '1')) {
        return { body: EditAuthor };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "EditPayee",
    path: "avaldus/:applicationID/rahasaaja",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '2') {
        return { body: EditPayee };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "EditWork",
    path: "avaldus/:applicationID/teos/:workID",
    respond() {
      if(getCookie('AHFToken') !== '' && (getCookie('AHFLevel') === '2' || getCookie('AHFLevel') === '1')) {
        return { body: EditWork };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "Login",
    path: "",
    respond() {
      if(window.location.search === '?register') {
        return {
          redirect: { name: "Register" }
        };
      }
      else if(getCookie('AHFToken') !== '' && (getCookie('AHFLevel') === '2' || getCookie('AHFLevel') === '1')) {
        return {
          redirect: { name: "Applications" }
        };
      }
      else {
        setCookie('AHFLevel', getCookie('AHFLevel'), -1);
        setCookie('AHFToken', getCookie('AHFToken'), -1);
        setCookie('AHFUser', getCookie('AHFUser'), -1);
        return { body: Login };
      }
    }
  },
  {
    name: "NewWork",
    path: "avaldus/:applicationID/uus",
    respond() {
      if(getCookie('AHFToken') !== '' && (getCookie('AHFLevel') === '2' || getCookie('AHFLevel') === '1')) {
        return { body: NewWork };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "PublisherApplication",
    path: "kirjastaja_avaldus",
    respond() {
      return { body: PublisherApplication };
    }
  },
  {
    name: "Recover",
    path: "taasta_parool",
    respond() {
      return { body: Recover };
    }
  },
  {
    name: "Register",
    path: "registreerimine",
    respond() {
      return { body: Register };
    }
  },
  {
    name: "Reports",
    path: "raportid",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '2') {
        return { body: Reports };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "UploadApplication",
    path: "avaldus/:applicationID/laadimine",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '1') {
        return { body: UploadApplication };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "UploadFile",
    path: "laadimine",
    respond() {
      if(getCookie('AHFToken') !== '' && getCookie('AHFLevel') === '2') {
        return { body: UploadFile };
      }
      else {
        return {
          redirect: { name: "Login" }
        };
      }
    }
  },
  {
    name: "NotFound",
    path: "(.*)",
    respond() {
      return { body: NotFound };
    }
  }
]);