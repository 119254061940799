export function catchError(error) {

  var newMessage = [];

  if(error.response != null && error.response.status === 401) {

    if(this.props.response.name === 'Login') {

      newMessage.push({
        value: 'Vale kasutajanimi või parool!',
        align: 'center'
      });

    }

    else {

      setCookie('AHFLevel', getCookie('AHFLevel'), -1);
      setCookie('AHFToken', getCookie('AHFToken'), -1);
      setCookie('AHFUser', getCookie('AHFUser'), -1);

      this.props.router.navigate({ name: 'Login' });

    }

  }

  else if(error.response != null && (error.response.status === 403 || error.response.status === 404)) {

    newMessage.push({
      value: 'Lehekülge ei leitud!',
      align: 'center'
    });

    this.navigateBackAfterMessage = true;

  }

  else if(error.response != null
    && error.response.data != null
    && error.response.data.errcode === -1
    && this.props.response.name === 'Recover') {

      newMessage.push({
        value: error.response.data.message.substring(error.response.data.message),
        align: 'center'
      });
    
  }

  else if(error.response != null && error.response.data != null && error.response.data.errcode === 20000) {

    newMessage.push({
      value: error.response.data.message.substring(
        error.response.data.message.indexOf('ORA-20000: ') + 11,
        error.response.data.message.indexOf('\n')),
      align: 'center'});

  }

  else {

    newMessage.push({value: 'Tekkis viga!', align: 'center'});

  }

  if(require('./../config/config.js').showErrors === 'on') {

    if(error.message != null) {

      newMessage.push({
        value: error.message,
        align: 'center'
      });

    }

    if(error.response != null
      && error.response.data != null
      && error.response.data.message != null
      && typeof error.response.data.message === 'string') {

      newMessage.push({
        value: error.response.data.message,
        align: 'center'
      });

    }

  }

  this.message.push(newMessage);

  this.setState({
    isLoading: false,
    showMessage: true
  });

}

export function getApplicationFile(data) {

  var byteCharacters = atob(data.bl_file.replace(/\s/g, ""));

  var byteNumbers = new Array(byteCharacters.length);

  for (var i = 0; i < byteCharacters.length; i++) {

    byteNumbers[i] = byteCharacters.charCodeAt(i);

  }

  var byteArray = new Uint8Array(byteNumbers);

  var blob = new Blob([byteArray], {type: ''});

  if(window.navigator.msSaveOrOpenBlob) {

    window.navigator.msSaveBlob(blob, data.file_name);

  }

  else {

    var a = document.createElement('a');

    document.body.appendChild(a);

    a.style = 'display: none';

    var URL = window.URL.createObjectURL(blob);

    a.href = URL;

    a.download = data.file_name;

    a.click();

    window.URL.revokeObjectURL(URL);

  }

}

export function getApplicationHTML(party, application, works) {

  var page = ''
    + '<!DOCTYPE html><html><head><meta charset=\\"utf-8\\"><title>AHF</title><style type=\\"text/css\\">'
    + '#ApplicationRow {white-space: nowrap;}'
    + 'td, ul, td {font-family: Verdana, Helvetica, sans-serif;font-size: 16px;}'
    + 'h2 {font-family: Verdana, Helvetica, sans-serif;font-size: 20px;}'
    + '#ApplicationsBox {width: 964px;}'
    + '#LongWidth {max-width: 150px;overflow: hidden;}'
    + '#Title {text-align: center;margin: auto;font-weight: bold;padding: 10px;font-family: Verdana, Helvetica, sans-serif;font-size: 20px;}'
    + '.ApplicationTable {border-collapse: collapse;}'
    + '.ApplicationTable td {vertical-align: top;padding-top: 5px;padding-bottom: 5px;}'
    + '.Center {width: 100%;text-align: center;white-space: nowrap;}'
    + '.Column {width: 45%;display: inline-block;text-align: center;font-family: Verdana, Helvetica, sans-serif;font-size: 16px;padding-bottom: 15px;}'
    + '.Content {text-align: left;width: 50%;}'
    + '.Header {border-top: 1px solid black;border-bottom: 1px solid black;}'
    + '.InnerBox {padding: 10px;border: 1px solid black;}'
    + '.Label {text-align: right;font-weight: bold;width: 50%;}'
    + '.Logo {width: 155px;height: 80px;text-align: center;}'
    + '.Width120 {padding: 3px;min-width: 120px;word-break: break-all;word-break: break-word;}'
    + '.Width220 {padding: 3px;min-width: 220px;word-break: break-all;word-break: break-word;}'
    + '.Width60 {min-width: 60px;word-break: break-all;word-break: break-word;text-align: center;}'
    + '.WidthRemaining {padding: 3px;width: 100%;word-break: break-all;word-break: break-word;}'
    + '.MaxWidth {width: 100%;}'
    +  'tr.Space > td {padding-bottom: 1em;}'
    +  'tr {page-break-inside: avoid;}'
    + '.OuterBox {margin: auto;border-radius: 20px;padding: 20px;}'
    + '.TopButtons {min-width: 155px;max-width: 155px;height: 80px;text-align: center;}'
    + '</style></head>'
    + '<body>'
    + '<div>'
    + '<p></p>'
    + '<div id=\\"ApplicationsBox\\" class=\\"OuterBox\\">'
    + '<table class=\\"MaxWidth\\">'
    + '<tbody>'
    + '<tr>'
    + '<td class=\\"Logo\\">'
    + '<img src=\\"img/logo.gif\\"></img>'
    + '</td>'
    + '<td class=\\"Center\\" colSpan=\\"2\\">'
    + '<h2>Avaldus Autorihüvitusfondile<br></br>nr: ' + (application.appl_id == null ? '' : application.appl_id) + '</h2>'
    + '</td>'
    + '<td class=\\"TopButtons\\">'
    + '</td>'
    + '</tr>'
    + '</tbody>'
    + '</table>'
    + '<div class=\\"Header\\">'
    + '<table class=\\"MaxWidth\\">'
    + '<tbody>'
    + '<tr>'
    + '<td class=\\"Label\\">'
    + 'Avalduse esitaja:'
    + '</td>'
    + '<td class=\\"Content\\">'
    + (party.legal_cd == null || party.legal_cd.length === 0
      ? (party.applicant_first_name == null ? '' : party.applicant_first_name)
        + ' ' + (party.applicant_family_name == null ? '' : party.applicant_family_name)
      : (party.legal_name == null ? '' :  party.legal_name))
    + '</td>'
    + '</tr>'
    + (party.legal_cd == null || party.legal_cd.length === 0 ? '' :
      '<tr>'
      + '<td class=\\"Label\\">'
      + 'Esindaja:'
      + '</td>'
      + '<td class=\\"Content\\">'
      + (party.applicant_first_name == null ? '' : party.applicant_first_name)
      + ' '
      + (party.applicant_family_name == null ? '' : party.applicant_family_name)
      + '</td>'
      + '</tr>')
    + '<tr>'
    + '<td class=\\"Label\\">'
    + 'Autor:'
    + '</td>'
    + '<td class=\\"Content\\">'
    + application.author_first_name + ' ' + application.author_family_name
    + '</td>'
    + '</tr>'
    + '<tr>'
    + '<td class=\\"Label\\">'
    + 'Pseudonüüm(id):'
    + '</td>'
    + '<td class=\\"Content\\">'
    + (party.pseudonym == null ? '' : party.pseudonym)
    + '</td>'
    + '</tr>'
    + '<tr>'
    + '<td class=\\"Label\\">'
    + 'Aadress, postiindeks:'
    + '</td>'
    + '<td class=\\"Content\\">'
    + (party.address_line1 == null ? '' : party.address_line1)
    + (party.address_line1 != null && (party.address_line2 != null || party.zip_cd != null) ? ', ' : '')
    + (party.address_line2 == null ? '' : party.address_line2)
    + (party.address_line2 != null && party.zip_cd != null ? ', ' : '')
    + (party.zip_cd == null ? '' : party.zip_cd)
    + '</td>'
    + '</tr>'
    + '<tr>'
    + '<td class=\\"Label\\">'
    + 'Telefon:'
    + '</td>'
    + '<td class=\\"Content\\">'
    + (party.phone1 == null ? '' : party.phone1)
    + '</td>'
    + '</tr>'
    + '<tr>'
    + '<td class=\\"Label\\">'
    + 'E-post:'
    + '</td>'
    + '<td class=\\"Content\\">'
    + (party.email == null ? '' : party.email)
    + '</td>'
    + '</tr>'
    + '<tr>'
    + '<td class=\\"Label\\">'
    + (party.legal_cd == null || party.legal_cd.length === 0 ? 'Isikukood:' : 'Registreerimisnumber:')
    + '</td>'
    + '<td class=\\"Content\\">'
    + (party.legal_cd == null || party.legal_cd.length === 0 ? (party.personal_cd == null ? '' : party.personal_cd) : (party.legal_cd == null ? '' : party.legal_cd))
    + '</td>'
    + '</tr>'
    + (party.individual_cd == null || party.individual_cd.length === 0 ? '' :
      '<tr>'
      + '<td class=\\"Label\\">'
      + 'FIE reg. nr:'
      + '</td>'
      + '<td class=\\"Content\\">'
      + (party.individual_cd)
      + '</td>'
      + '</tr>')
    + '<tr>'
    + '<td class=\\"Label\\">'
    + 'IBAN:'
    + '</td>'
    + '<td class=\\"Content\\">'
    + (party.iban == null ? '' : party.iban)
    + '</td>'
    + '</tr>'
    + (party.bic == null || party.bic.length === 0 ? '' :
      '<tr>'
      + '<td class=\\"Label\\">'
      + 'BIC:'
      + '</td>'
      + '<td class=\\"Content\\">'
      + party.bic
      + '</td>'
      + '</tr>')
    + '<tr>'
    + '</tbody>'
    + '</table>'
    + '<ul>'
    + '<li><b>Taotlen autorihüvitust teoste laenutamise eest avalikest raamatukogudest ja reprograafilise reprodutseerimise eest vastavalt lisale.</b></li>'
    + '<br></br>'
    + '<li><b>Olen teadlik autorihüvituse taotlemise korrast ja kinnitan minu poolt esitatud andmete õigsust.</b></li>'
    + '</ul>'
    + '<div class=\\"Column\\">'
    + '<b>Kuupäev:</b> ' + (application.change_dtime == null ? '' : application.change_dtime)
    + '</div>'
    + '<div class=\\"Column\\">'
    + '<b>Allkiri:</b>'
    + '</div>'
    + '</div>'
    + (works == null ? '' : (
      '<div>'
      + '<div id=\\"Title\\">Lisa: teoste nimekiri</div>'
      + '<div class=\\"InnerBox\\">'
      + '<table class=\\"ApplicationTable\\">'
      + '<thead>'
      + '<tr>'
      + '<th class=\\"Width220\\">Pealkiri</th>'
      + '<th class=\\"WidthRemaining\\">Aasta</th>'
      + '<th class=\\"Width120\\">Autor</th>'
      + '<th class=\\"Width120\\">Autorlus</th>'
      + '<th class=\\"Width60\\">Sama tüüpi auto- reid</th>'
      + '<th class=\\"Width120\\">ISBN</th>'
      + '<th class=\\"Width120\\">Kujunduse osa</th>'
      + '<th class=\\"Width60\\">Õppe- ja tea- dusk.</th>'
      + '</tr>'
      + '</thead>'
      + '<tbody>'));

  var borders;

  for(let i = 0; i < works.length; i ++) {

    borders = 'style=\\"background-color: white; border-left: 1px solid gray; border-right: 1px solid gray;';

    if(works[i].title != null) {

      borders += 'border-top: 1px solid gray';

    }

    borders += '\\"';

    page +=
      '<tr class=\\"Space\\" key=' + i + '>'
      + '<td class=\\"Width220\\" ' + borders + '>'
      + (works[i].title == null ? '' : works[i].title.replace(/\\/g, '\\\\').replace(/\t/g, ' ').replace(/"/g, '\\"'))
      + '</td>'
      + '<td class=\\"WidthRemaining\\" ' + borders + '>'
      + (works[i].year_of_issue == null ? '' : works[i].year_of_issue)
      + '</td>'
      + '<td class=\\"Width120\\" ' + borders + '>'
      + (works[i].author_first_name == null ? '' : works[i].author_first_name.replace(/\\/g, '\\\\').replace(/\t/g, ' ').replace(/"/g, '\\"')) + ' ' + (works[i].author_family_name == null ? '' : works[i].author_family_name.replace(/\\/g, '\\\\').replace(/\t/g, ' ').replace(/"/g, '\\"'))
      + '</td>'
      + '<td class=\\"Width120\\" ' + borders + '>'
      + (works[i].relation_name == null ? '' : works[i].relation_name.replace(/\\/g, '\\\\').replace(/\t/g, ' ').replace(/"/g, '\\"'))
      + '</td>'
      + '<td class=\\"Width60\\" ' + borders + '>'
      + (works[i].allocation == null ? '' : works[i].allocation)
      + '</td>'
      + '<td class=\\"Width120\\" ' + borders + '>'
      + (works[i].isbn == null ? '' : works[i].isbn.replace(/\\/g, '\\\\').replace(/\t/g, ' ').replace(/"/g, '\\"'))
      + '</td>'
      + '<td class=\\"Width120\\" ' + borders + '>'
      + (works[i].work_id == null ? '' : (works[i].formation_part_id === 5 ? 'Illustratsioone üle poole teosest' : (works[i].formation_part_id === 4 ? 'Illustreeritud teos' : 'Illustreerimata/ üksikute illustratsiooni- dega teos')))
      + '</td>'
      + '<td class=\\"Width60\\" ' + borders + '>'
      + (works[i].work_id == null ? '' : (works[i].is_academic === 'Y' ? 'Jah' : 'Ei'))
      + '</td>'
      + '</tr>';

  }

  if(works.length > 0) {

    page += '<tr style=\\"border-top: 1px solid gray\\"><td colspan=\\"8\\"></td></tr>';

  }

  page +=
    (works[0] == null ? '' : (
      '</tbody>'
      + '</table>'
      + '</div>'
      + '</div>'))
    + '</div>'
    + '</div>'
    + '</body>'
    + '</html>';

  return page;

}

export function getCookie(name) {

  var cookieName = name + '=';

  var cookieArray = document.cookie.split(';');

  for(let i = 0; i < cookieArray.length; i++) {

    var cookie = cookieArray[i];

    while(cookie.charAt(0) === ' ') {

      cookie = cookie.substring(1);

    }

    if(cookie.indexOf(cookieName) === 0) {

      return cookie.substring(cookieName.length, cookie.length);

    }

  }

  return '';

}

export function getErrorMessage(router, pageName, error) {

  var newMessage = [];

  if(error.response != null && error.response.status === 401 && pageName === 'Login') {

    newMessage.push({value: 'Vale kasutajanimi või parool!', align: 'center'});

  }

  else if
    (error.response != null
    && error.response.data != null
    && error.response.data.message != null
    && error.response.status === 400
    && pageName === 'NewPassword') {

    newMessage.push({value: error.response.data.message, align: 'center'});

  }

  else if(pageName === 'Recover' && error.response != null && error.response.data.errcode === -1) {

      newMessage.push({
        value: error.response.data.message.substring(error.response.data.message),
        align: 'center'
      });
    
  }

  else {

    newMessage.push({value: 'Tekkis viga!', align: 'center'});

  }

  if(error.response != null && error.response.data != null && error.response.data.errcode === 20000) {

    newMessage.push({
      value: error.response.data.message.substring(
        error.response.data.message.indexOf('ORA-20000: ') + 11,
        error.response.data.message.indexOf('\n')),
      align: 'center'});

  }

  if(require('./../config/config.js').showErrors === 'on') {

    if(error.message != null) {

      newMessage.push({value: error.message, align: 'center'});

    }

    if(error.response != null && error.response.data != null && error.response.data.message != null && typeof error.response.data.message === 'string') {

      newMessage.push({value: error.response.data.message, align: 'center'});

    }

  }

  return newMessage;

}

export function setCookie(name, value, hours) {

  var date = new Date();

  date.setTime(date.getTime() + (hours * 60 * 60 * 1000));

  var expires = 'expires=' + date.toUTCString();

  document.cookie = name + '=' + value + ';' + expires + ';path=/';

}

export function validateAuthorFamilyName(value) {

  if(value.length === 0) {

    return('Autori perenimi peab olema täidetud!');

  }

  return('');

}

export function validateAuthorFirstName(value) {

  if(value.length === 0) {

    return('Autori eesnimi peab olema täidetud!');

  }

  return('');

}

export function validateBIC(displayBIC, value) {

  if(displayBIC) {

    if(value.length === 0) {

      return('BIC peab olema täidetud!');

    }

    if(value.search(/^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/) === -1) {

      return('BIC formaat on vale!');

    }

  }

  return('');

}

export function validateCountryCode(value) {

  if(value === 'NULL') {

    return('Vali resident!');

  }

  return('');

}

export function validateEMail(value) {

  if(value.length === 0) {

    return('E-posti aadress peab olema täidetud!');

  }

  if(value.search(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === -1) {

    return('E-posti aadressi formaat on vale!');

  }

  return('');

}

export function validateIBAN(value) {

  var IBAN = value.replace(/\s+/g, '');

  if(IBAN.length === 0) {

    return('IBAN peab olema täidetud!');

  }

  if(IBAN.length > 34 || IBAN.length < 10 || IBAN.search(/[^a-zA-Z0-9\s]/) !== -1) {

    return('IBAN formaat on vale!');

  }

  IBAN = IBAN.substring(4, IBAN.length).concat(IBAN.substring(0, 4)).toUpperCase();

  for(let i = 0; i < IBAN.length; i ++) {

    if(isNaN(IBAN[i])) {

      IBAN = IBAN.replace(IBAN[i], IBAN[i].charCodeAt(0) - 55);

    }

  }

  var checksum = IBAN.substring(0, 2);

  for (let i = 2; i < IBAN.length; i += 7) {

    var fragment = String(checksum) + IBAN.substring(i, i + 7);

    checksum = parseInt(fragment) % 97;

  }

  if(checksum !== 1) {

    return('IBAN formaat on vale!');

  }

  return('');

}

export function validateIndividualCode(submitterType, countryCode, value) {

  if(submitterType === 'fuusiline_isik' || submitterType === 'fie') {

    if(value.length === 0) {

      return('Isikukood peab olema täidetud!');

    }

    if(countryCode === 'EE' && (value.search(/[^0-9]/) !== -1 || value.length !== 11)) {

      return('Isikukoodi formaat on vale!');

    }

  }

  return('');

}

export function validateLegalCode(submitterType, value) {

  if(submitterType === 'jur_isik' && value.length === 0) {

    return('Jur. isiku reg. nr. peab olema täidetud!');

  }

  return('');

}

export function validateLegalName(submitterType, value) {

  if(submitterType === 'jur_isik' && value.length === 0) {

    return('Jur. isiku nimi peab olema täidetud!');

  }

  return('');

}

export function validatePersonalCode(submitterType, value) {

  if(submitterType === 'fie' && value.length === 0) {

    return('FIE reg. nr. peab olema täidetud!');

  }

  if(submitterType === 'fie' && value.length > 0 && (value.search(/[^0-9]/) !== -1 || value.length !== 8)) {

    return('FIE reg. nr. formaat on vale!');

  }

  return('');

}

export function validatePhone(value) {

  var phone = value.replace(/\s/g, '');

  if(phone.length > 0 && (phone.length < 4 || phone.search(/[^0-9+-\\(\\)]/) !== -1)) {

    return('Telefoni formaat on vale!');

  }

  return('');

}

export function validateSubmitterFamilyName(value) {

  if(value.length === 0) {

    return('Esitaja perenimi peab olema täidetud!');

  }

  return('');

}

export function validateSubmitterFirstName(value) {

  if(value.length === 0) {

    return('Esitaja eesnimi peab olema täidetud!');

  }

  return('');

}

export function validateSubmitterType(value) {

  if(value === 'NULL') {

    return('Vali esitaja tüüp!');

  }

  return('');

}

export function validateZIP(value) {

  if(value.length > 0 && value.search(/[^0-9]/) !== -1) {

    return('Postiindeksi formaat on vale!');

  }

  return('');

}