import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	catchError,
	getCookie
} from './Utils';

export default class EditApplicant extends Component {

	constructor(props) {

		super(props);

		this.back = this.back.bind(this);
		this.catchError = catchError.bind(this);
		this.changeResidentValue = this.changeResidentValue.bind(this);
		this.endApplicationsConnection = this.endApplicationsConnection.bind(this);
		this.endPartyConnection = this.endPartyConnection.bind(this);
		this.endSubmitConnection = this.endSubmitConnection.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);
		this.toggleBIC = this.toggleBIC.bind(this);
		this.toggleSubmitterType = this.toggleSubmitterType.bind(this);
		this.ValidateAutoriEesnimed = this.ValidateAutoriEesnimed.bind(this);
		this.ValidateAutoriPerenimi = this.ValidateAutoriPerenimi.bind(this);
		this.ValidateBIC = this.ValidateBIC.bind(this);
		this.ValidateEesnimed = this.ValidateEesnimed.bind(this);
		this.ValidateEPost = this.ValidateEPost.bind(this);
		this.ValidateFIERegNr = this.ValidateFIERegNr.bind(this);
		this.ValidateIBAN = this.ValidateIBAN.bind(this);
		this.ValidateNimi = this.ValidateNimi.bind(this);
		this.ValidatePerenimi = this.ValidatePerenimi.bind(this);
		this.ValidatePostiindeks = this.ValidatePostiindeks.bind(this);
		this.ValidateResident = this.ValidateResident.bind(this);
		this.ValidateTelefon = this.ValidateTelefon.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			BIC: false,
			hasMultipleAuthors: false,
			isLoading: true,
			party: {},
      residentValue: 'NULL',
			showMessage: false,
			submitterType: 'fuusiline_isik'
		};
	}
	
	componentDidMount() {

		var URL = this.APIURL + 'parties/' + this.props.response.params.partyID;

		axios.get(URL, this.config).then(this.endPartyConnection).catch(this.catchError);

	}

	render() {

		if(getCookie('AHFLevel') === '2') {

			document.body.style.backgroundColor = '#BA491C';

		}

		else {

			document.body.style.backgroundColor = '#E7D074';

		}

		var Fixed = {};

		var ConditionalFixed = {};

		var Disabled = {};

		if(this.state.party.legal_cd != null) {

			ConditionalFixed['disabled'] = 'disabled';

		}

		else {

			Disabled['disabled'] = 'disabled';

		}

		Fixed['disabled'] = 'disabled';

		return(

			<div className='mediumBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div className='mediumBox OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>Esitaja andmete muutmine</h2>
								</td>
								<td className='TopButtons'>
									<button type='button' className='StandardButton' id='ButtonWidth' onClick={this.back}>Tagasi</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
						<form onSubmit={this.submitForm.bind(this)}>
							<table className='MaxWidth'>
								<tbody>
									<tr>
										<td className='Subtitle' colSpan='2'>
											Avalduse esitaja andmed
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Avalduse esitaja eesnimi:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Eesnimed'
												defaultValue={this.state.party.applicant_first_name}
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Avalduse esitaja perekonnanimi:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Perenimi'
												defaultValue={this.state.party.applicant_family_name}
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											Esitaja tüüp:
										</td>
										<td>
											<select {...ConditionalFixed} onChange={this.toggleSubmitterType} name='submitterType' value={this.state.submitterType}>
												<option value='fuusiline_isik'>Füüsiline isik</option>
												<option value='fie'>FIE</option>
												<option {...Disabled} value='jur_isik'>Jur. isik</option>
											</select>
										</td>
									</tr>
									{this.state.submitterType === 'fie' ?
										<tr>
											<td></td>
											<td id='FieTooltip'>
												(FIE hüvitistelt ei arvestata tulumaksu)
											</td>
										</tr>
										: null}
									{this.state.submitterType !== 'jur_isik' ?
										<tr>
											<td className='RequiredField'>
												Resident:
											</td>
											<td>
												<select name='Resident' onChange={this.changeResidentValue} value={this.state.residentValue}>
													<option value='EE'>EE</option>
													{this.state.submitterType === 'fuusiline_isik' ? <option value='Mu'>Muu</option> : null}
												</select>
											</td>
										</tr>
										: null}
									{this.state.submitterType !== 'jur_isik' ?
										<tr>
											<td className='RequiredField'>
												Isikukood:
											</td>
											<td>
												<input
													{...Fixed}
													type='text'
													size='12'
													name='Isikukood'
													defaultValue={this.state.party.personal_cd}
													maxLength='20'>
												</input>
											</td>
										</tr>
										: null}
									{this.state.submitterType === 'jur_isik' ?
										<tr>
											<td className='RequiredField'>
												Jur. isiku nimi:
											</td>
											<td>
												<input
													type='text'
													size='25'
													name='Nimi'
													defaultValue={this.state.party.legal_name}
													maxLength='50'>
												</input>
											</td>
										</tr>
										: null}
									{this.state.submitterType === 'jur_isik' ?
										<tr>
											<td className='RequiredField'>
												Jur. isiku reg. nr.:
											</td>
											<td>
												<input
													{...Fixed}
													type='text'
													size='20'
													name='JurIsikuRegNr'
													defaultValue={this.state.party.legal_cd}
													maxLength='11'>
												</input>
											</td>
										</tr>
										: null}
									{this.state.submitterType === 'fie' ?
										<tr>
											<td className='RequiredField'>
												FIE reg. nr.:
											</td>
											<td>
												<input
													type='text'
													size='12'
													name='FIERegNr'
													defaultValue={this.state.party.individual_cd}
													maxLength='8'>
												</input>
											</td>
										</tr>
										: null}
									<tr>
										<td className='Subtitle' colSpan='2'>
											Aadress
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Tänav, maja, korter:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Aadress'
												defaultValue={this.state.party.address_line2}
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Linn:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Linn'
												defaultValue={this.state.party.address_line1}
												maxLength='30'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Postiindeks:
										</td>
										<td>
											<input
												type='text'
												size='12'
												name='Postiindeks'
												defaultValue={this.state.party.zip_cd}
												maxLength='5'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='Subtitle' colSpan='2'>
											E-posti aadressile saadetakse parool, millega pääsete avalduse koostamise lehtedele
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											E-posti aadress:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='EPost'
												defaultValue={this.state.party.email}
												maxLength='50'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='NormalField'>
											Telefon:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='Telefon'
												defaultValue={this.state.party.phone1}
												maxLength='20'>
											</input>
										</td>
									</tr>
									<tr>
										<td className='Subtitle' colSpan='2'>
											Panga andmed
										</td>
									</tr>
									<tr>
										<td className='RequiredField'>
											IBAN:
										</td>
										<td>
											<input
												type='text'
												size='25'
												name='IBAN'
												defaultValue={this.state.party.iban}
												maxLength='50'
												onChange={this.toggleBIC}>
											</input>
										</td>
									</tr>
									{this.state.BIC ?
										<tr>
											<td className='RequiredField'>
												BIC:
											</td>
											<td>
												<input
													type='text'
													size='12'
													name='BIC'
													defaultValue={this.state.party.bic}
													maxLength='8'>
												</input>
											</td>
										</tr>
										: null}
									{this.state.hasMultipleAuthors ? null :
										<tr>
											<td className='Subtitle' colSpan='2'>
												Autori nimi või pseudonüüm, mille all on teosed avaldatud
											</td>
										</tr>}
									{this.state.hasMultipleAuthors ? null :
										<tr>
											<td className='RequiredField'>
												Autori eesnimi (või -nimed):
											</td>
											<td>
												<input
													type='text'
													size='25'
													name='AutoriEesnimed'
													defaultValue={this.state.party.author_first_name}
													maxLength='50'>
												</input>
											</td>
										</tr>}
									{this.state.hasMultipleAuthors ? null :
										<tr>
											<td className='RequiredField'>
												Autori perekonnanimi:</td>
											<td>
												<input
													type='text'
													size='25'
													name='AutoriPerenimi'
													defaultValue={this.state.party.author_family_name}
													maxLength='50'>
												</input>
											</td>
										</tr>}
									{this.state.hasMultipleAuthors ? null :
										<tr>
											<td className='NormalField'>
												Pseudonüüm(id):
											</td>
											<td>
												<input
													type='text'
													size='25'
													name='Pseudonuumid'
													defaultValue={this.state.party.pseudonym}
													maxLength='50'>
												</input>
											</td>
										</tr>}
									<tr>
										<td className='Center' colSpan='2'>
											<button className='StandardButton' type='submit'>Salvesta muudatused</button>
										</td>
									</tr>
								</tbody>
							</table>
						</form>
					</div>
				</div>
			</div>

		);

	}

	back() {

		if(getCookie('AHFLevel') === '2'
			&& this.props.response.location.state != null
			&& this.props.response.location.state.applicationID != null) {

      let url = this.props.router.url({
        name: 'EditApplication',
				params: { applicationID: this.props.response.location.state.applicationID }
      });

      this.props.router.navigate({ url });

		}

		else {

      let url = this.props.router.url({ name: 'Applications' });

      this.props.router.navigate({ url });

		}

	}

  changeResidentValue(event) {

    this.setState({residentValue: event.target.value});

  }

	endApplicationsConnection(response) {

		var applications = response.data.data;

		var author = (this.state.party.author_first_name == null ?
				'' : this.state.party.author_first_name)
			+ (this.state.party.author_family_name == null ?
				'' : this.state.party.author_family_name);

		var hasMultipleAuthors = false;

		for(let i = 0; i < applications.length; i ++) {

			if(applications[i].author_first_name + applications[i].author_family_name !== author) {

				hasMultipleAuthors = true;

				break;

			}

		}

		this.setState({
			hasMultipleAuthors: hasMultipleAuthors,
			isLoading: false
		});

	}

	endPartyConnection(response) {

		var party = response.data.data;

		var BIC = false;

		if((party.iban.length >= 10
			&& party.iban.substring(0, 2).toLowerCase() !== 'ee')
				|| party.bic != null) {

			BIC = true;

		}

		var submitterType;

		if(party.legal_cd != null) {

			submitterType = 'jur_isik';

		}

		else if(party.individual_cd != null) {

			submitterType = 'fie';

		}

		else {

			submitterType = 'fuusiline_isik';

		}

		this.setState({
			BIC: BIC,
			party: party,
			submitterType: submitterType
		});

    if(this.state.submitterType !== 'jur_isik') {

      this.setState({residentValue: (submitterType === 'fie' || party.resident_country_cd === 'EE' ? 'EE' : 'Mu')});

    }

		var URL = this.APIURL + 'applications?filtered=false&party_id=' + party.party_id;

		axios.get(URL, this.config).then(this.endApplicationsConnection).catch(this.catchError);

	}

	endSubmitConnection(response) {

		this.message.push([{
			value: 'Muudatused salvestatud!',
			align: 'center'
		}]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	submitForm(event) {

		event.preventDefault();

		var Resident = '';
		var Nimi = '';
		var FIERegNr = '';
		var BIC = '';
		var AutoriEesnimed = '';
		var AutoriPerenimi = '';
		var Pseudonuumid = '';

		if(event.target.Resident != null) {

			Resident = event.target.Resident.value;

		}

		if(event.target.Nimi != null) {

			Nimi = event.target.Nimi.value;

		}

		if(event.target.FIERegNr != null) {

			FIERegNr = event.target.FIERegNr.value;

		}

		if(event.target.BIC != null) {

			BIC = event.target.BIC.value;

		}

		if(event.target.AutoriEesnimed != null) {

			AutoriEesnimed = event.target.AutoriEesnimed.value;

		}

		if(event.target.AutoriPerenimi != null) {

			AutoriPerenimi = event.target.AutoriPerenimi.value;

		}

		if(event.target.Pseudonuumid != null) {

			Pseudonuumid = event.target.Pseudonuumid.value;

		}

		var errorMessage = [];

		var message = this.ValidateEesnimed(event.target.Eesnimed.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidatePerenimi(event.target.Perenimi.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateNimi(Nimi);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateFIERegNr(FIERegNr);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidatePostiindeks(event.target.Postiindeks.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateEPost(event.target.EPost.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateTelefon(event.target.Telefon.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateIBAN(event.target.IBAN.value);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateBIC(BIC);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateAutoriEesnimed(AutoriEesnimed);

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		message = this.ValidateAutoriPerenimi(AutoriPerenimi);;

		if(message !== '') {

			errorMessage.push({value: message, align: 'center'});

		}

		if(errorMessage.length === 0) {

			this.setState({ isLoading: true });

			var URL = this.APIURL + 'parties/' + this.state.party.party_id;

			var data =
				'{"author_first_name": "' + AutoriEesnimed.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"author_family_name": "' + AutoriPerenimi.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"applicant_first_name": "' + event.target.Eesnimed.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"applicant_family_name": "' + event.target.Perenimi.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"pseudonym": "' + Pseudonuumid.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"legal_name": "' + Nimi.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"individual_cd": "' + FIERegNr.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"resident_country_cd": "' + Resident + '",'
				+ '"address_line2": "' + event.target.Aadress.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"address_line1": "' + event.target.Linn.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"zip_cd": "' + event.target.Postiindeks.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"email": "' + event.target.EPost.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"phone1": "' + event.target.Telefon.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"iban": "' + event.target.IBAN.value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '",'
				+ '"bic": "' + BIC.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"}';

			axios.put(URL, data, this.config).then(this.endSubmitConnection).catch(this.catchError);
		}

		else {

			this.message.push(errorMessage);

			this.setState({ showMessage: true });

		}

	}

	toggleBIC(event) {

		if(event.target.value.length >= 10 && event.target.value.substring(0, 2).toLowerCase() !== 'ee') {

			this.setState({BIC: true});

		}

		else {

			this.setState({BIC: false});

		}

	}

	setShowMessage(showMessage) {

		if(!showMessage) {

			if(this.navigateBackAfterMessage) {

				this.back();

			}

			else {

				this.setState({showMessage: false});

			}

		}

		else {

			this.setState({showMessage: true});

		}

	}

	toggleSubmitterType(event) {

		this.setState({submitterType: event.target.value});

	}

	ValidateAutoriEesnimed(Value) {

		if(!this.state.hasMultipleAuthors && Value.length === 0) {

			return('Autori eesimi peab olema täidetud!');

		}

		return('');

	}

	ValidateAutoriPerenimi(Value) {

		if(!this.state.hasMultipleAuthors && Value.length === 0) {

			return('Autori perenimi peab olema täidetud!');

		}

		return('');

	}

	ValidateBIC(Value) {

		if(this.state.BIC) {

			if(Value.length === 0) {

				return('BIC peab olema täidetud!');

			}

			if(Value.search(/^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/) === -1) {

				return('BIC formaat on vale!');

			}

		}

		return('');

	}

	ValidateEesnimed(Value) {

		if(Value.length === 0) {

			return('Esitaja eesnimi peab olema täidetud!');

		}

		return('');

	}

	ValidateEPost(Value) {

		if(Value.length === 0) {

			return('E-posti aadress peab olema täidetud!');

		}

		if(Value.search(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === -1) {

			return('E-posti aadressi formaat on vale!');

		}

		return('');

	}

	ValidateFIERegNr(Value) {

		if(this.state.submitterType === 'fie' && Value.length === 0) {

			return('FIE reg. nr. peab olema täidetud!');

		}

		if(this.state.submitterType === 'fie' && Value.length > 0 && (Value.search(/[^0-9]/) !== -1 || Value.length !== 8)) {

			return('FIE reg. nr. formaat on vale!');

		}

		return('');

	}

	ValidateIBAN(Value) {

		var IBAN = Value.replace(/\s+/g, '');

		if(IBAN.length === 0) {

			return('IBAN peab olema täidetud!');

		}

		if(IBAN.length > 34 || IBAN.length < 10 ||IBAN.search(/[^a-zA-Z0-9\s]/) !== -1) {

			return('IBAN formaat on vale!');

		}

		IBAN = IBAN.substring(4, IBAN.length).concat(IBAN.substring(0, 4)).toUpperCase();

		for(let i = 0; i < IBAN.length; i ++) {

			if(isNaN(IBAN[i])) {

				IBAN = IBAN.replace(IBAN[i], IBAN[i].charCodeAt(0) - 55);

			}

		}

		var checksum = IBAN.substring(0, 2);

		for (let i = 2; i < IBAN.length; i += 7) {

			var fragment = String(checksum) + IBAN.substring(i, i + 7);

			checksum = parseInt(fragment) % 97;

		}

		if(checksum !== 1) {

			return('IBAN formaat on vale!');

		}

		return('');

	}

	ValidateNimi(Value) {

		if(this.state.submitterType === 'jur_isik' && Value.length === 0) {

			return('Jur. isiku nimi peab olema täidetud!');

		}

		return('');

	}

	ValidatePerenimi(Value) {

		if(Value.length === 0) {

			return('Esitaja perenimi peab olema täidetud!');

		}

		return('');

	}

	ValidatePostiindeks(Value) {

		if(Value.length > 0 && Value.search(/[^0-9]/) !== -1) {

			return('Postiindeksi formaat on vale!');

		}

		return('');

	}

	ValidateResident(Value) {

		if(Value === 'NULL') {

			return('Vali resident!');

		}

		return('');

	}

	ValidateTelefon(Value) {

		var Telefon = Value.replace(/\s/g, '');

		if(Telefon.length > 0 && (Telefon.length < 4 || Telefon.search(/[^0-9+-\\(\\)]/) !== -1)) {

			return('Telefoni formaat on vale!');

		}

		return('');

	}

}