import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	getCookie,
	getErrorMessage,
	setCookie
} from './Utils';

export default class Archive extends Component {

	constructor(props) {

		super(props);

		this.catchError = this.catchError.bind(this);
		this.endApplicationsConnection = this.endApplicationsConnection.bind(this);
		this.logOut = this.logOut.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			applications: [],
			isLoading: true,
			showMessage: false
		};

	}

	componentDidMount() {

		var URL = this.APIURL + 'applications/archive';

		axios.get(URL, this.config).then(this.endApplicationsConnection).catch(this.catchError);

	}

	render() {

		document.body.style.backgroundColor = '#BA491C';

		var Options = {};

        if(this.isLoading) {

            Options['disabled'] = 'disabled';

        }

		return(

			<div className='largeBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div>
					<div id='Tabs'>
						<Link
              className='unselectedTab'
							name="Applications">
							Laekunud avaldused
						</Link>
						<Link
							className='unselectedTab'
							name="AdministrationTool">
							Administreerimine
						</Link>
						<div id='SelectedTab'>Arhiiv<p></p></div>
						<Link
							className='unselectedTab'
							name="Reports">
							Raportid
						</Link>
						<Link
							className='unselectedTab'
							name="UploadFile">
							Failide üleslaadimine
						</Link>
					</div>
					<div id='AdminApplicationsBox'>
							<table className='MaxWidth'>
								<tbody>
									<tr>
										<td className='Logo'>
											<img src='/img/logo.gif' alt=''></img>
										</td>
										<td className='Center'>
											<h2>AHF avalduste arhiiv</h2>
										</td>
										<td className='TopButtons'>
											<button {...Options} type='button' className='StandardButton' onClick={this.logOut}>Logi välja</button>
										</td>
									</tr>
								</tbody>
							</table>
						<div className='InnerBox'>
							<table id='ApplicationsTable'>
								<thead>
									<tr>
										<th className='Width100'>Isikukood/ Registri number</th>
										<th className='Width170'>Taotleja</th>
										<th className='Width250'>E-posti aadress</th>
										<th className='Width80'>Taotluse aeg</th>
										<th className='Width80'>Kinnituse aeg</th>
										<th className='WidthRemaining'>Taotlus</th>
										<th className='Width170'>Autor</th>
									</tr>
								</thead>
								<tbody>
								{this.state.applications.map(function(application, i) {

									return(

										<tr id='Applied' key={i}>
											<td className='Width100'>{application.legal_cd == null ? application.personal_cd : application.legal_cd}</td>
											<td className='Width170'>{application.applicant_first_name} {application.applicant_family_name}</td>
											<td className='Width250'>{application.party_email}</td>
											<td className='Width80'>{application.create_dtime}</td>
											<td className='Width80'>{application.create_dtime}</td>
											<td className='WidthRemaining textCenter'>
												<Link
													className='linkLikeText'
													name="ArchiveApplicationView"
													params={{ applicationID: application.appl_id }}>
													{application.appl_id}
												</Link>
											</td>
											<td className='Width170'>{application.author_first_name} {application.author_family_name}</td>
										</tr>

									);

								}, this)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

		);

	}

	catchError(error) {

		this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	endApplicationsConnection(response) {

		var applications = response.data.data;

		var partyID = -1;

		for(let i = 0; i < applications.length; i ++) {

			if(partyID !== applications[i].party_id) {

				partyID = applications[i].party_id;

				if(applications[i].legal_cd != null) {

					applications[i].personal_cd = applications[i].legal_cd;

				}

			}

			else {

				applications[i].personal_cd = '';
				applications[i].user_password = '';
				applications[i].party_create_dtime = '';
				applications[i].applicant_first_name = '';
				applications[i].applicant_family_name = '';

			}

		}

		this.setState({
			applications: applications,
			isLoading: false
		});

	}

	logOut() {

		setCookie('AHFLevel', getCookie('AHFLevel'), -1);
		setCookie('AHFToken', getCookie('AHFToken'), -1);
		setCookie('AHFUser', getCookie('AHFToken'), -1);

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

	}

	setShowMessage(showMessage) {

		this.setState({showMessage: showMessage});

	}

}